import React, { useMemo, useEffect, useCallback } from "react";
import { Trans } from "react-i18next";
import { ScrollRestoration } from "react-router-dom";
import cn from "classnames";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  useLazyPtUserListShiftsHandlerPtShiftsListGetQuery as useLazyPTShiftList,
  usePtUserListShiftsHandlerPtShiftsListGetQuery as usePTShiftList,
  useListPublicHolidaysHandlerPublicHolidaysGetQuery as useListPublicHoliday,
  PtUserListShiftsHandlerPtShiftsListGetApiArg as PTShiftListArg,
  PtUserListShiftsHandlerPtShiftsListGetApiResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { dateTimeNow } from "oneclick-component/src/utils/datetime";
import { ListEmptyImage } from "../image";
import ShiftListItem from "../components/ShiftListItem";
import useMainLayoutOutletContext from "../components/MainLayout/useMainLayoutOutletContext";
import useInfiniteQuery from "oneclick-component/src/hooks/useInfiniteQuery";
import ShiftListLoadMoreView from "../components/ShiftListLoadMoreView";
import { SHIFT_LIST_PAGE_SIZE } from "../constants/shift";
import useMeUser from "../hooks/useMeUser";
import useMeUserStation from "../hooks/useMeUserStation";

const MAX_INCIDENT_SHIFT_ON_PAGE_TOP = 50;
export const FindShiftScreen = (): React.ReactElement => {
  const me = useMeUser();
  const now = useMemo(() => {
    return dateTimeNow();
  }, []);
  const {
    filterDate,
    filterWorkingStationIds,
    setShouldShowFutureMonth,
    showBookmarkedOnly,
  } = useMainLayoutOutletContext();

  const meUserStation = useMeUserStation();

  const listShiftParams: PTShiftListArg = {
    endAfter: filterDate < now ? now.toISO() : filterDate.toISO(),
    startBefore: filterDate
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .plus({ day: 1 })
      .toISO(),
    shiftRequestStatuses: ["requested"],
    showNoneStationIds: meUserStation?.workingStations.map((ws) => ws.id) ?? [],
    shiftStatus: ["active"],
    workingStationId:
      filterWorkingStationIds.length > 0 ? filterWorkingStationIds : null,
    pageIndex: 0,
    pageSize: SHIFT_LIST_PAGE_SIZE,
    excludeNonBookmarkedShifts: showBookmarkedOnly,
    sort: "duty_start_time+",
  };

  const regularShiftParams: PTShiftListArg = {
    ...listShiftParams,
    shiftType: "REGULAR",
  };

  const incidentShiftParams: PTShiftListArg = {
    ...listShiftParams,
    endAfter: now
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISO(),
    startBefore: undefined,
    shiftType: "INCIDENT",
    pageSize: MAX_INCIDENT_SHIFT_ON_PAGE_TOP,
  };

  const {
    pages: regularShiftPages,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    nextPageIndex,
    resetAndFetchFirstPage,
  } = useInfiniteQuery<PtUserListShiftsHandlerPtShiftsListGetApiResponse>(
    useLazyPTShiftList,
    regularShiftParams
  );

  const { data: incidentShiftData, isFetching: isLoadingIncidentShift } =
    usePTShiftList(incidentShiftParams, {
      refetchOnMountOrArgChange: true,
      skip: me?.role?.name === "CCO",
    });
  const { data: holidays } = useListPublicHoliday({});

  useEffect(() => {
    resetAndFetchFirstPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, filterWorkingStationIds, showBookmarkedOnly]);

  const onClickFetchMore = useCallback(() => {
    if (nextPageIndex == null) {
      console.error("Fetch more detected without nextPageIndex");
      return;
    }
    fetchNextPage(nextPageIndex);
  }, [fetchNextPage, nextPageIndex]);

  useEffect(() => {
    setShouldShowFutureMonth(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const viewState: "loading" | "empty" | "success" = useMemo(() => {
    const isIncidentEmpty =
      incidentShiftData == null || incidentShiftData.results.length === 0;
    const isRegularEmpty =
      regularShiftPages == null || regularShiftPages.length === 0;
    if (
      isLoadingIncidentShift ||
      (regularShiftPages == null && isFetchingNextPage)
    ) {
      return "loading";
    }

    if (isIncidentEmpty && isRegularEmpty) {
      return "empty";
    }

    if (!isIncidentEmpty || !isRegularEmpty) {
      return "success";
    }
    return "loading";
  }, [
    incidentShiftData,
    isFetchingNextPage,
    isLoadingIncidentShift,
    regularShiftPages,
  ]);

  if (viewState === "loading") {
    return (
      <div
        className={cn(
          "fixed",
          "inset-0",
          "pointer-events-none",
          "flex",
          "flex-row",
          "items-center"
        )}
      >
        <LoadingSpinner size="l" className="mx-auto" />
      </div>
    );
  } else if (viewState === "empty") {
    return (
      <div className={cn("mt-11", "text-center")}>
        <ListEmptyImage className={cn("mx-auto", "mb-3")} />
        <p className={cn("text-xl", "font-medium", "mb-3")}>
          <Trans i18nKey="findShift.empty.title" />
        </p>
        <p className={cn("text-sm", "font-normal")}>
          <Trans i18nKey="findShift.empty.description" />
        </p>
      </div>
    );
  }
  return (
    <main>
      <ScrollRestoration />
      <div className="pt-2">
        {incidentShiftData != null && incidentShiftData.results.length > 0
          ? incidentShiftData.results.map((shift) => (
              <ShiftListItem
                key={`shift-${shift.id}`}
                shiftForPt={shift}
                holidays={holidays?.results ?? []}
                shouldNavigateOnClick={true}
              />
            ))
          : null}
        {regularShiftPages != null && regularShiftPages.length > 0 ? (
          <>
            {regularShiftPages.map((page) =>
              page.data.results.map((shift) => (
                <ShiftListItem
                  key={`shift-${shift.id}`}
                  shiftForPt={shift}
                  holidays={holidays?.results ?? []}
                  shouldNavigateOnClick={true}
                />
              ))
            )}
            <ShiftListLoadMoreView
              onClickFetchMore={onClickFetchMore}
              isFetching={isFetchingNextPage}
              hasNextPage={hasNextPage}
            />
          </>
        ) : null}
      </div>
    </main>
  );
};
