import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import "./index.css";
import { config } from "./config.ts";
import "./i18n";
import "swiper/css/bundle";
import authgear from "@authgear/web";
import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";

if (config.sentry?.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
  });
}

async function init() {
  try {
    // configure Authgear container instance
    await authgear.configure({
      endpoint: config.authgearEndpoint,
      clientID: config.authgearClientId,
      sessionType: "refresh_token",
    });
  } finally {
    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<GenericErrorScreen />}>
          <App />
        </Sentry.ErrorBoundary>
      </React.StrictMode>
    );
  }
}

init().catch((e) => {
  Sentry.captureException(e);
});
