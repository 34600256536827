import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import {
  ShiftForPtUserView,
  useApplyShiftHandlerPtShiftsShiftIdApplyPostMutation as useApply,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Trans, useTranslation } from "react-i18next";
import useMeUserStation from "../../hooks/useMeUserStation";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import useShiftDisplayStatus from "../../components/ShiftListItem/hooks/useShiftDisplayStatus";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import ApplyIncidentConfirmDialog from "../../components/ApplyIncidentConfirmDialog";

interface Props {
  shift: ShiftForPtUserView;
  setAppliedSuccess: Dispatch<SetStateAction<boolean>>;
}

const IncidentShiftApplicationSection = (props: Props): ReactElement => {
  const { shift, setAppliedSuccess } = props;
  const { t } = useTranslation();
  const meStation = useMeUserStation();
  const showMessage = useShowMessage();
  const [applyShift, { isLoading: isApplying }] = useApply();
  const [
    isApplyIncidentConfirmationDialogOpen,
    setIsApplyIncidentConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const handleCloseDialog = useCallback(
    () => setIsApplyIncidentConfirmationDialogOpen(false),
    []
  );
  const onApplyShift = useCallback(() => {
    if (isApplying) {
      return;
    }
    applyShift({
      shiftId: shift.id,
    })
      .unwrap()
      .then(() => {
        setAppliedSuccess(true);
      })
      .catch((e) => {
        console.error(e);
        showMessage({
          title: t("shift.application.apply.toast.fail.title"),
          message: t("shift.application.apply.toast.fail.message"),
          type: "fail",
          showDismiss: true,
        });
      });
  }, [applyShift, showMessage, t, isApplying, shift, setAppliedSuccess]);

  const onClickApplyShift = useCallback(() => {
    setIsApplyIncidentConfirmationDialogOpen(true);
  }, []);

  const { canApply } = useShiftDisplayStatus(shift, meStation!);

  return (
    <>
      <div
        className={cn(
          "px-5",
          "pt-2",
          "fixed",
          "pb-5",
          "bottom-0",
          "left-0",
          "right-0",
          "border-t",
          "border-t-black/12",
          "bg-white"
        )}
      >
        <Button
          theme="red"
          className={cn("w-full", "mb-2")}
          onClick={onClickApplyShift}
          isLoading={isApplying}
          disabled={!canApply}
        >
          <Trans i18nKey="shift.incidentApplication.button.apply" />
        </Button>
      </div>
      <ApplyIncidentConfirmDialog
        isOpen={isApplyIncidentConfirmationDialogOpen}
        onClose={handleCloseDialog}
        onConfirmApply={onApplyShift}
        isConfirmApplyLoading={isApplying}
      />
    </>
  );
};

export default IncidentShiftApplicationSection;
