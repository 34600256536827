import { ReactElement } from "react";
import cn from "classnames";

interface StatusMessageProps {
  icon?: ReactElement;
  message?: string;
  isEmergency?: boolean;
}

export const StatusTextDisplay = (props: StatusMessageProps): ReactElement => {
  const { icon, message, isEmergency } = props;
  return (
    <div className={cn("flex", "flex-row", "mb-1", "items-start")}>
      <div
        className={cn(
          "w-5",
          "h-5",
          "mr-2",
          "flex",
          "justify-center",
          "items-center"
        )}
      >
        {icon}
      </div>
      <p
        className={cn("font-normal", "text-sm", {
          "text-red-600": isEmergency,
          "text-black/60": !isEmergency,
        })}
      >
        {message}
      </p>
    </div>
  );
};
