import cn from "classnames";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { ErrorScreenLogo } from "oneclick-component/src/image";
import { useConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostMutation } from "oneclick-component/src/store/apis/enhancedApi";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import useMeUser from "../hooks/useMeUser";
import { CheckCircleIcon } from "../icon";
import { HomeImage } from "../image";

export default function WelcomeScreen(): React.ReactElement {
  const user = useMeUser();
  const [
    consent,
    {
      isLoading: isConsentLoading,
      isError: isConsentError,
      isSuccess: isConsentSuccess,
    },
  ] = useConsentPtUserHandlerPtPartTimeUsersPtUserIdConsentPostMutation();

  useEffect(() => {
    if (user?.pendingConsent) {
      consent({
        ptUserId: user.id,
      }).catch((e) => {
        throw e;
      });
    }
  }, [consent, user]);

  return (
    <main
      className={cn(
        "fixed",
        "top-1/2",
        "left-1/2",
        "-translate-x-1/2",
        "-translate-y-1/2",
        "pt-16",
        "pb-35",
        "text-center",
        "w-full"
      )}
    >
      {isConsentLoading ? (
        <div className={cn("pt-5", "text-center")}>
          <LoadingSpinner size="l" />
        </div>
      ) : null}
      {isConsentError ? (
        <div className={cn("px-4", "text-center")}>
          <ErrorScreenLogo
            className={cn("w-36", "h-36", "mx-auto", "mb-9.5")}
          />
          <span className={cn("block", "mb-3", "text-lg", "text-black")}>
            <Trans i18nKey="welcome.error.title" />
          </span>
          <span
            className={cn(
              "block",
              "mb-3",
              "text-sm",
              "text-black/60",
              "font-medium"
            )}
          >
            <Trans i18nKey="welcome.error.desc" />
          </span>
        </div>
      ) : null}
      {isConsentSuccess || !user?.pendingConsent ? (
        <div
          className={cn(
            "text-center",
            "flex",
            "flex-col",
            "items-center",
            "justify-center"
          )}
        >
          <CheckCircleIcon className={cn("w-14.5", "h-14.5")} />
          <h1 className={cn("font-medium", "text-lg", "text-black", "mt-3")}>
            <Trans i18nKey="welcome.title" />
          </h1>
          <p
            className={cn(
              "font-medium",
              "text-sm",
              "text-black/60",
              "whitespace-pre-line",
              "mt-5"
            )}
          >
            <Trans i18nKey="welcome.description" />
          </p>
          <HomeImage className={cn("mx-auto", "mt-6")} />
        </div>
      ) : null}
    </main>
  );
}
