import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { NoSymbolIcon } from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import {
  ShiftForPtUserView,
  useGetMasterShiftDetailHandlerPtMasterShiftsJobIdGetQuery as useGetMasterShiftDetail,
  useApplyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPostMutation as useApplyMasterShift,
  useListPublicHolidaysHandlerPublicHolidaysGetQuery as useListPublicHoliday,
  useLazyPtUserListShiftsHandlerPtShiftsListGetQuery as useLazyPTShiftList,
  PtUserListShiftsHandlerPtShiftsListGetApiArg as PTShiftListArg,
  PtUserListShiftsHandlerPtShiftsListGetApiResponse,
  MasterShift,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  isApiErrorCode,
  isFetchQueryBaseError,
} from "oneclick-component/src/models/error";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { Button } from "oneclick-component/src/components/Button";

import { MasterShiftInfo } from "./MasterShiftInfo";
import { MasterShiftShiftList } from "./ShiftList";
import { CheckCircleIcon } from "../../icon";
import useMainLayoutOutletContext from "../../components/MainLayout/useMainLayoutOutletContext";
import ShiftListLoadMoreView from "../../components/ShiftListLoadMoreView";
import useInfiniteQuery, {
  InfiniteQueryPage,
} from "oneclick-component/src/hooks/useInfiniteQuery";
import useMeUser from "../../hooks/useMeUser";
import { SHIFT_LIST_PAGE_SIZE } from "../../constants/shift";
import CcoOverWorkHourDialog from "../../components/CcoOverWorkHourDialog.tsx/CcoOverWorkHourDialog";
import ga from "../../utils/ga";

interface UseMasterShiftApplicationScreenQueryOptions {
  masterShiftId?: string;
}

interface UseMasterShiftApplicationScreenQueryReturnValue {
  masterShift: MasterShift | null;
  shiftListPages:
    | InfiniteQueryPage<PtUserListShiftsHandlerPtShiftsListGetApiResponse>[]
    | null;
  error: unknown;
  isGetMasterShiftLoading: boolean;
  isFetchingShiftListNextPage: boolean;
  fetchShiftListNextPage: () => void;
  hasShiftListNextPage: boolean;
  resetShiftListAndFetchFirstPage: () => void;
}

const useMasterShiftApplicationScreenQuery = (
  options: UseMasterShiftApplicationScreenQueryOptions
): UseMasterShiftApplicationScreenQueryReturnValue => {
  const { masterShiftId } = options;

  const {
    data: getMasterShiftData,
    error: getMasterShiftError,
    isLoading: isGetMasterShiftLoading,
  } = useGetMasterShiftDetail(
    { jobId: parseInt(masterShiftId!, 10) },
    {
      skip: masterShiftId == null,
    }
  );

  const listShiftParams: PTShiftListArg = useMemo(() => {
    let shiftStationIds = null;
    if (getMasterShiftData != null) {
      shiftStationIds = [getMasterShiftData.masterShift.workingStation.id];
    }
    return {
      masterShiftId:
        masterShiftId != null ? [parseInt(masterShiftId, 10)] : null,
      showNoneStationIds: shiftStationIds,
      shiftRequestStatuses: ["applied", "hired", "requested", "contacted"],
      pageIndex: 0,
      pageSize: SHIFT_LIST_PAGE_SIZE,
      sort: "duty_start_time+",
    };
  }, [getMasterShiftData, masterShiftId]);

  const {
    pages: shiftListPages,
    fetchNextPage: _fetchShiftListNextPage,
    hasNextPage: hasShiftListNextPage,
    nextPageIndex: shiftListNextPageIndex,
    isFetchingNextPage: isFetchingShiftListNextPage,
    resetAndFetchFirstPage: resetShiftListAndFetchFirstPage,
  } = useInfiniteQuery<PtUserListShiftsHandlerPtShiftsListGetApiResponse>(
    useLazyPTShiftList,
    listShiftParams,
    getMasterShiftData == null
  );

  const fetchShiftListNextPage = useCallback(() => {
    if (shiftListNextPageIndex == null) {
      resetShiftListAndFetchFirstPage();
      return;
    }
    _fetchShiftListNextPage(shiftListNextPageIndex);
  }, [
    _fetchShiftListNextPage,
    resetShiftListAndFetchFirstPage,
    shiftListNextPageIndex,
  ]);

  return {
    masterShift: getMasterShiftData?.masterShift ?? null,
    shiftListPages: shiftListPages,
    error: getMasterShiftError,
    isGetMasterShiftLoading,
    isFetchingShiftListNextPage,
    fetchShiftListNextPage,
    hasShiftListNextPage,
    resetShiftListAndFetchFirstPage,
  };
};

const MasterShiftApplicationScreen = (): React.ReactElement => {
  const { id } = useParams();
  const me = useMeUser();
  const { setNavTitle } = useMainLayoutOutletContext();
  const { t } = useTranslation();
  const [appliedSuccess, setAppliedSuccess] = useState(false);
  const showMessage = useShowMessage();
  const [appliedShiftIds, setAppliedShiftIds] = useState<number[]>([]);
  const [isOverWorkDialogOpen, setIsOverWorkDialogOpen] = useState(false);
  const [apply, { isLoading: isApplying }] = useApplyMasterShift();

  const [selectedShiftIds, setNewSelectedShiftIds] = useState<number[]>([]);

  const {
    masterShift,
    shiftListPages,
    error,
    isGetMasterShiftLoading,
    isFetchingShiftListNextPage,
    fetchShiftListNextPage,
    hasShiftListNextPage,
    resetShiftListAndFetchFirstPage,
  } = useMasterShiftApplicationScreenQuery({ masterShiftId: id });

  const shiftList: ShiftForPtUserView[] = useMemo(() => {
    if (shiftListPages == null) {
      return [];
    }
    const shiftList: ShiftForPtUserView[] = shiftListPages.reduce(
      (
        accShiftList: ShiftForPtUserView[],
        currPage: InfiniteQueryPage<PtUserListShiftsHandlerPtShiftsListGetApiResponse>
      ) => [...accShiftList, ...currPage.data.results],
      []
    );
    return shiftList;
  }, [shiftListPages]);

  const onClickFetchMoreShift = useCallback(() => {
    fetchShiftListNextPage();
  }, [fetchShiftListNextPage]);

  const shiftDateRange = useMemo(() => {
    if (shiftList.length === 0) {
      return null;
    }
    return {
      startDate: shiftList[0].dutyStartTime,
      endDate: shiftList[shiftList.length - 1].dutyStartTime,
    };
  }, [shiftList]);

  const { data: holidays, isLoading: isListHolidayLoading } =
    useListPublicHoliday(
      {
        startDate: shiftDateRange?.startDate,
        endDate: shiftDateRange?.endDate,
      },
      {
        skip: shiftDateRange == null,
      }
    );

  const onDialogClose = useCallback(() => {
    setIsOverWorkDialogOpen(false);
  }, []);

  useEffect(() => {
    if (error != null) {
      console.error(error);
      showMessage({
        title: t("masterShift.application.detail.toast.fail.title"),
        type: "fail",
        showDismiss: true,
      });
    } else {
      setNavTitle(masterShift?.masterShiftId ?? "");
    }
  }, [masterShift, error, showMessage, t, setNavTitle]);

  const onSelectShift = useCallback((shift: ShiftForPtUserView) => {
    if (
      shift.shiftRequest != null &&
      shift.shiftRequest.status !== "requested"
    ) {
      return;
    }
    setNewSelectedShiftIds((prev) => {
      if (prev.includes(shift.id)) {
        return prev.filter((id) => id !== shift.id);
      }
      return [...prev, shift.id];
    });
  }, []);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onApplyClick = useCallback(() => {
    if (isApplying) {
      return;
    }
    if (id == null) {
      console.warn("Cannot apply shift, token param is null");
      return;
    }
    apply({
      jobId: parseInt(id, 10),
      applyMasterShiftRequest: {
        shiftIds: selectedShiftIds,
      },
    })
      .unwrap()
      .then(() => {
        setAppliedShiftIds((prev) => [...prev, ...selectedShiftIds]);
        setAppliedSuccess(true);
        setNewSelectedShiftIds([]);
        window.scrollTo(0, 0);
        ga("event", "shift_apply", {
          job_id: id,
          shift_ids: selectedShiftIds,
          shift_type: "multiple",
        });
      })
      .catch((err) => {
        console.error(err);

        // TODO: change to check error id
        if (
          err.data.detail.description.startsWith(
            "CCO staff cannot work longer than"
          )
        ) {
          setIsOverWorkDialogOpen(true);
        } else {
          showMessage({
            title: t("masterShift.application.apply.toast.fail.title"),
            type: "fail",
            showDismiss: true,
          });
        }

        if (isFetchQueryBaseError(err)) {
          const rawCode = (err.data as any)?.detail?.code;
          if (
            typeof rawCode === "string" &&
            isApiErrorCode(rawCode) &&
            rawCode === "INVALID_REQUEST"
          ) {
            // Attempt to refetch latest data
            resetShiftListAndFetchFirstPage();
          }
        }
      });
  }, [
    isApplying,
    id,
    apply,
    selectedShiftIds,
    showMessage,
    t,
    resetShiftListAndFetchFirstPage,
  ]);

  const isInitialLoading =
    isGetMasterShiftLoading || isListHolidayLoading || holidays == null;
  if (isInitialLoading) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  if (masterShift == null) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2",
          "flex",
          "flex-col",
          "items-center",
          "justify-center",
          "pt-16"
        )}
      >
        <NoSymbolIcon className={cn("w-18", "h-18", "fill-gray-300")} />
        <p className={cn("font-medium", "text-sm", "text-black/90", "mt-3")}>
          <Trans i18nKey="masterShift.application.notFound" />
        </p>
      </div>
    );
  }

  return (
    <main className={cn("bg-gray-50", "pt-2")}>
      {appliedSuccess ? (
        <>
          <div className={cn("px-5", "py-7", "bg-white", "w-full")}>
            <div className={cn("text-center", "px-15")}>
              <CheckCircleIcon className={cn("w-14.5", "h-14.5", "mx-auto")} />
              <h1
                className={cn("font-medium", "text-xl", "text-black", "mt-3")}
              >
                <Trans i18nKey="shift.application.success.title" />
              </h1>
              <p
                className={cn(
                  "font-medium",
                  "text-sm",
                  "text-black/60",
                  "whitespace-pre-line",
                  "mt-5"
                )}
              >
                <Trans i18nKey="masterShift.application.success.description" />
              </p>
            </div>
          </div>
          <hr className={cn("border-black/12", "mb-2")} />
        </>
      ) : null}
      <section
        className={cn(
          "px-5",
          "pt-4",
          "w-full",
          "flex",
          "justify-center",
          "border-b",
          "border-black/12",
          "bg-white"
        )}
      >
        <MasterShiftInfo masterShift={masterShift} />
      </section>
      <section className="pb-40">
        <MasterShiftShiftList
          shiftList={shiftList}
          onSelectShift={onSelectShift}
          selectedShiftIds={selectedShiftIds}
          holidays={holidays.results}
          appliedShiftIds={appliedShiftIds}
        />
        <ShiftListLoadMoreView
          isFetching={isFetchingShiftListNextPage}
          onClickFetchMore={onClickFetchMoreShift}
          hasNextPage={hasShiftListNextPage}
        />
        <footer
          className={cn(
            "fixed",
            "z-20",
            "bottom-0",
            "left-0",
            "bg-white",
            "w-full",
            "px-[0.875rem]",
            "py-4",
            "border-t",
            "border-black/12"
          )}
        >
          <CcoOverWorkHourDialog
            isOpen={isOverWorkDialogOpen}
            onClose={onDialogClose}
          />
          {me?.role?.name === "CCO" ? null : (
            <p
              className={cn(
                "font-medium",
                "text-sm",
                "leading-5",
                "text-red-500",
                "mb-2.5"
              )}
            >
              <Trans i18nKey="masterShift.application.418Note" />
            </p>
          )}
          <Button
            type="button"
            className={cn("w-full", "h-12")}
            disabled={selectedShiftIds.length === 0}
            onClick={onApplyClick}
            isLoading={isApplying}
          >
            <Trans
              i18nKey="masterShift.application.apply"
              values={{
                count: selectedShiftIds.length,
              }}
            />
          </Button>
        </footer>
      </section>
    </main>
  );
};

export default MasterShiftApplicationScreen;
