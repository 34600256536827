import React from "react";
import { Trans } from "react-i18next";
import cn from "classnames";

import { CheckCircleIcon } from "../icon";

const MasterShiftApplicationSuccessScreen = (): React.ReactElement => {
  return (
    <div
      className={cn(
        "fixed",
        "top-0",
        "bottom-0",
        "left-0",
        "right-0",
        "px-3.5",
        "pt-19.5",
        "pb-4"
      )}
    >
      <div
        className={cn(
          "h-full",
          "max-w-227",
          "mx-auto",
          "p-4",
          "bg-white",
          "rounded-lg",
          "shadow",
          "flex",
          "flex-col",
          "items-center",
          "justify-center",
          "text-center"
        )}
      >
        <CheckCircleIcon className={cn("w-14.5", "h-14.5")} />
        <h1
          className={cn(
            "font-medium",
            "text-lg",
            "leading-7",
            "text-black",
            "mt-3"
          )}
        >
          <Trans i18nKey="masterShift.application.apply.success.title" />
        </h1>
        <p
          className={cn(
            "font-medium",
            "text-sm",
            "leading-5",
            "text-black/60",
            "whitespace-pre-line",
            "mt-5"
          )}
        >
          <Trans i18nKey="masterShift.application.apply.success.message" />
        </p>
      </div>
    </div>
  );
};

export default MasterShiftApplicationSuccessScreen;
