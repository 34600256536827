import React, {
  useCallback,
  useMemo,
  MouseEvent,
  useState,
  ReactElement,
} from "react";
import cn from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
  ShiftForPtUserView,
  PublicHoliday,
  ShiftRate,
  useLikeShiftHandlerPtShiftsShiftIdLikePostMutation as useLikeShift,
  useUnlikeShiftHandlerPtShiftsShiftIdUnlikePostMutation as useUnlikeShift,
  useBookmarkShiftHandlerPtShiftsShiftIdBookmarkPostMutation as useBookmarkShift,
  useUnbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPostMutation as useUnbookmarkShift,
  ShiftType,
  WorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  makeShiftTimeIntervalDisplayText,
  makeIncidentShiftEndTimeDisplayText,
} from "oneclick-component/src/utils/shift";
import {
  isoToDateTime,
  isDateInHoliday,
  dateTimeNow,
} from "oneclick-component/src/utils/datetime";
import { Button } from "oneclick-component/src/components/Button";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import { CheckIcon } from "oneclick-component/src/icon";
import {
  DayIcon,
  NightIcon,
  ConfirmIcon,
  StartTimeIcon,
  EndTimeIcon,
  HiredPeopleIcon,
} from "../../icon";
import { StatusTextDisplay } from "./ShiftStatusDisplay";
import LikeShiftButton from "../LikeShiftButton/LikeShiftButton";
import useMeUserStation from "../../hooks/useMeUserStation";
import BookmarkShiftButton from "../BookmarkShiftButton";
import CalendarStationView from "./CalendarStationView";
import AppRoutes from "../../routes/AppRoutes";
import useShiftDisplayStatus from "./hooks/useShiftDisplayStatus";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";

interface SelectShiftButtonProps {
  isApplied: boolean;
  isSelected: boolean;
  disabled: boolean;
  shiftForPt: ShiftForPtUserView;
  onClick: (shift: ShiftForPtUserView) => void;
  className?: string;
}

const SelectShiftButton = React.memo(
  (props: SelectShiftButtonProps): React.ReactElement => {
    const { isApplied, isSelected, onClick, disabled, shiftForPt, className } =
      props;

    const onShiftSelect = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onClick(shiftForPt);
      },
      [onClick, shiftForPt]
    );

    return (
      <Button
        type="button"
        className={cn(
          "w-10",
          "h-19",
          "!px-2",
          {
            "!bg-primary-500 border-primary-500": isSelected,
            "border-gray-500": !isSelected,
          },
          className
        )}
        theme="whiteNoFillBlackTextThick"
        onClick={onShiftSelect}
        disabled={isApplied || disabled}
      >
        {isApplied || disabled ? (
          <XMarkIcon className={cn("w-5", "h-5")} />
        ) : (
          <CheckIcon
            className={cn("w-5", "h-5", {
              "fill-black/60": !isSelected,
              "fill-white": isSelected,
            })}
          />
        )}
      </Button>
    );
  }
);

interface ShiftTitleDisplayProps {
  title: string;
  rate: ShiftRate | null;
  shiftType: ShiftType;
  isIncident?: boolean;
  isEmergency?: boolean;
}
const ShiftTitleDisplay = (props: ShiftTitleDisplayProps): ReactElement => {
  const { rate, title, isIncident, isEmergency } = props;
  return (
    <div className={cn("flex", "flex-row", "mb-1", "items-center", "gap-x-2")}>
      <ShiftBadge
        rate={rate}
        isIncident={isIncident ?? false}
        isHideCco={true}
      />
      <p
        className={cn("font-medium", "text-base", "line-clamp-3", {
          "text-black/86": !isIncident,

          "text-red-600": isEmergency,
        })}
      >
        {title}
      </p>
    </div>
  );
};

interface ShiftTimeDisplayProps {
  isDayShift: boolean;
  text: string;
  isIncident?: boolean;
  isEmergency?: boolean;
}

export const ShiftTimeDisplay = (
  props: ShiftTimeDisplayProps
): ReactElement => {
  const { isDayShift, text, isIncident, isEmergency } = props;

  if (isIncident) {
    return (
      <>
        <div className={cn("flex", "flex-row", "mb-1", "items-center")}>
          <div
            className={cn(
              "w-5",
              "mr-2",
              "flex",
              "justify-center",
              "items-center"
            )}
          >
            <StartTimeIcon
              className={isEmergency ? "fill-red-600" : "fill-black/60"}
            />
          </div>
          <p
            className={cn("font-normal", "text-sm", {
              "text-red-600": isEmergency,
              "text-black/60": !isEmergency,
            })}
          >
            <Trans i18nKey="shiftList.time.startNow" />
          </p>
        </div>
        <div className={cn("flex", "flex-row", "mb-1", "items-center")}>
          <div
            className={cn(
              "w-5",
              "mr-2",
              "flex",
              "justify-center",
              "items-center"
            )}
          >
            <EndTimeIcon
              className={isEmergency ? "fill-red-600" : "fill-black/60"}
            />
          </div>
          <p
            className={cn("font-normal", "text-sm", {
              "text-red-600": isEmergency,
              "text-black/60": !isEmergency,
            })}
          >
            <Trans
              i18nKey="shiftList.time.endAt"
              values={{
                time: text,
              }}
            />
          </p>
        </div>
      </>
    );
  }
  return (
    <div className={cn("flex", "flex-row", "mb-1", "items-center")}>
      <div
        className={cn("w-5", "mr-2", "flex", "justify-center", "items-center")}
      >
        {isDayShift ? (
          <DayIcon className="fill-black/60" />
        ) : (
          <NightIcon className="fill-black/60" />
        )}
      </div>
      <p className={cn("font-medium", "text-base", "text-black/86")}>{text}</p>
    </div>
  );
};

interface ShiftListItemProps {
  shiftForPt: ShiftForPtUserView;
  holidays: PublicHoliday[];
  shouldNavigateOnClick?: boolean;
  selectState?: {
    isApplied: boolean;
    isSelected: boolean;
    onSelect: (shift: ShiftForPtUserView) => void;
  };
  className?: string;
}

// eslint-disable-next-line complexity
const ShiftListItem = (
  props: ShiftListItemProps
  // eslint-disable-next-line sonarjs/cognitive-complexity
): React.ReactElement | null => {
  const {
    shiftForPt,
    holidays,
    selectState,
    shouldNavigateOnClick = false,
    className,
  } = props;
  const { t } = useTranslation();
  const meUserStation = useMeUserStation();
  const [likeShift] = useLikeShift();
  const [unlikeShift] = useUnlikeShift();
  const [bookmarkShift] = useBookmarkShift();
  const [unbookmarkShift] = useUnbookmarkShift();

  const isIncident = shiftForPt.isIncident;
  const isEmergency = isIncident && shiftForPt.status === "active";

  const shiftTimeIntervalDisplayText = useMemo(() => {
    return makeShiftTimeIntervalDisplayText(
      shiftForPt.dutyStartTime,
      shiftForPt.dutyEndTime
    );
  }, [shiftForPt]);

  const incidentShiftTimeDisplayText = useMemo(() => {
    return makeIncidentShiftEndTimeDisplayText(
      shiftForPt.dutyStartTime,
      shiftForPt.dutyEndTime
    );
  }, [shiftForPt]);

  const expectedEndTime = useMemo(() => {
    if (shiftForPt.dutyEndTime == null) {
      if (shiftForPt.isIncident) {
        return isoToDateTime(shiftForPt.dutyStartTime).plus({ days: 1 });
      }
      return isoToDateTime(shiftForPt.dutyStartTime)
        .plus({ days: 1 })
        .set({ hour: 3 });
    }
    return isoToDateTime(shiftForPt.dutyEndTime);
  }, [shiftForPt]);

  const isDayShift = useMemo(() => {
    const startDate = isoToDateTime(shiftForPt.dutyStartTime);
    return startDate.hour >= 5 && startDate.hour < 18;
  }, [shiftForPt]);

  const isSingleShift = useMemo(() => {
    const shiftIdParts = shiftForPt.displayId.split("-");
    if (shiftIdParts.length !== 4) {
      return true;
    }
    return shiftIdParts[2].charAt(0) === "S" || isIncident;
  }, [isIncident, shiftForPt.displayId]);

  const isHoliday = useMemo(() => {
    return isDateInHoliday(shiftForPt.dutyStartTime, holidays);
  }, [holidays, shiftForPt.dutyStartTime]);

  const [isLikedDisplay, setIsLikedDisplay] = useState<boolean>(
    shiftForPt.isLiked
  );

  const {
    calendar: calendarDisplayStatus,
    statusIcon,
    statusText,
    canApply,
    action,
  } = useShiftDisplayStatus(shiftForPt, meUserStation!, isLikedDisplay);

  const isNewApply = useMemo(() => {
    if (selectState?.isApplied && canApply) {
      return true;
    }
    return false;
  }, [selectState, canApply]);

  const displayStation = useMemo<WorkingStation>(() => {
    return isIncident
      ? shiftForPt.supportStation ?? shiftForPt.workingStation
      : shiftForPt.workingStation;
  }, [isIncident, shiftForPt.supportStation, shiftForPt.workingStation]);

  const StatusIcon = statusIcon;

  const onToggleShiftLike = useCallback(() => {
    setIsLikedDisplay((prev) => !prev); // optimistic update
    if (!isLikedDisplay) {
      likeShift({ shiftId: shiftForPt.id }).catch((_) =>
        setIsLikedDisplay((prev) => !prev)
      ); // undo optimistic update
      return;
    }
    unlikeShift({ shiftId: shiftForPt.id }).catch((_) =>
      setIsLikedDisplay((prev) => !prev)
    ); // undo optimistic update
  }, [isLikedDisplay, likeShift, shiftForPt.id, unlikeShift]);

  const [isBookmarkedDisplay, setIsBookmarkedDisplay] = useState<boolean>(
    shiftForPt.isBookmarked
  );

  const onToggleShiftBookmark = useCallback(() => {
    setIsBookmarkedDisplay((prev) => !prev); // optimistic update
    if (!isBookmarkedDisplay) {
      bookmarkShift({ shiftId: shiftForPt.id }).catch((_) =>
        setIsBookmarkedDisplay((prev) => !prev)
      ); // undo optimistic update
      return;
    }
    unbookmarkShift({ shiftId: shiftForPt.id }).catch((_) =>
      setIsBookmarkedDisplay((prev) => !prev)
    ); // undo optimistic update
  }, [isBookmarkedDisplay, bookmarkShift, shiftForPt.id, unbookmarkShift]);

  const now = useMemo(() => {
    return dateTimeNow();
  }, []);

  return (
    <div
      className={cn(
        "w-full",
        "relative",
        "mb-2",
        "shadow-sm",
        "flex",
        "flex-row",
        "py-4",
        "px-5",
        "items-start",
        { "bg-red-50": isEmergency, "bg-white": !isEmergency },
        className
      )}
    >
      {shouldNavigateOnClick ? (
        <Link
          to={AppRoutes.ShiftDetailScreen.render(shiftForPt.id)}
          className={cn("absolute", "inset-0")}
        />
      ) : null}
      <CalendarStationView
        isIncident={isIncident}
        isHoliday={isHoliday}
        isExpired={shiftForPt.status === "expired" || expectedEndTime < now}
        station={displayStation}
        isLayeredCalendar={!isSingleShift}
        datetime={isoToDateTime(shiftForPt.dutyStartTime)}
        className="mr-4"
        calendarDisplayStatus={calendarDisplayStatus}
      />
      <div className="flex-1">
        {isIncident ? null : (
          <p
            className={cn("font-normal", "text-xs", "mb-0.5", "text-black/60")}
          >
            {shiftForPt.displayId}
          </p>
        )}
        <ShiftTitleDisplay
          isIncident={isIncident}
          isEmergency={isEmergency}
          title={shiftForPt.shiftTitle}
          rate={shiftForPt.rate}
          shiftType={shiftForPt.shiftType}
        />
        <ShiftTimeDisplay
          isIncident={isIncident}
          isEmergency={isEmergency}
          isDayShift={isDayShift}
          text={
            isIncident
              ? incidentShiftTimeDisplayText
              : shiftTimeIntervalDisplayText
          }
        />
        {canApply && !selectState?.isApplied ? (
          <div className={cn("flex", "flex-row", "mb-1", "items-center")}>
            <div
              className={cn(
                "w-5",
                "mr-2",
                "flex",
                "justify-center",
                "items-center"
              )}
            >
              <HiredPeopleIcon
                className={isEmergency ? "fill-red-600" : "fill-black/60"}
              />
            </div>
            <ProgressBar
              labelClassName={isEmergency ? "text-red-600" : "text-black/60"}
              labelStyle="left"
              count={shiftForPt.hiredCount}
              max={shiftForPt.fulfillmentCount}
            />
          </div>
        ) : null}
        {/**
         * FIXME: workaround for rtk query lazy query always return cached value bug
         * ref:
         * https://github.com/reduxjs/redux-toolkit/issues/2802
         * https://github.com/reduxjs/redux-toolkit/issues/3711
         */}
        <StatusTextDisplay
          isEmergency={isEmergency}
          icon={
            isNewApply ? (
              <ConfirmIcon
                className={isEmergency ? "fill-red-600" : "fill-black/60"}
              />
            ) : (
              <StatusIcon
                className={isEmergency ? "fill-red-600" : "fill-black/60"}
              />
            )
          }
          message={isNewApply ? t("shiftList.status.applied") : statusText}
        />
      </div>
      {action === "like" ? (
        <LikeShiftButton
          onToggleLike={onToggleShiftLike}
          isLiked={isLikedDisplay}
          className={cn(
            "p-3",
            "-m-3", // -ve margin + +ve padding = larger clickable area w/o changing parent
            "z-10"
          )}
          iconClassName={cn({
            "!w-5 !h-5": selectState != null,
          })}
        />
      ) : null}
      {action === "bookmark" ? (
        <BookmarkShiftButton
          onToggleBookmark={onToggleShiftBookmark}
          isBookmarked={isBookmarkedDisplay}
          className={cn(
            "p-3",
            "-m-3", // -ve margin + +ve padding = larger clickable area w/o changing parent
            "z-10"
          )}
          iconClassName={cn({
            "!w-5 !h-5": selectState != null,
          })}
        />
      ) : null}
      {selectState != null ? (
        <SelectShiftButton
          isApplied={selectState.isApplied}
          isSelected={selectState.isSelected}
          disabled={!canApply}
          shiftForPt={shiftForPt}
          onClick={selectState.onSelect}
          className={cn("ml-4", "z-10")}
        />
      ) : null}
    </div>
  );
};

export default ShiftListItem;
