import React from "react";
import cn from "classnames";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { Button } from "oneclick-component/src/components/Button";
import { Trans } from "react-i18next";

interface Props {
  onClickFetchMore: () => void;
  isFetching: boolean;
  hasNextPage: boolean;
}

const ShiftListLoadMoreView = (props: Props): React.ReactElement => {
  const { onClickFetchMore, isFetching, hasNextPage } = props;
  return (
    <div
      className={cn(
        "w-full",
        "py-4",
        "px-5",
        "flex",
        "flex-row",
        "items-center",
        "justify-center"
      )}
    >
      {!hasNextPage ? (
        <span className={cn("text-black/60", "text-sm", "font-normal")}>
          <Trans i18nKey="common.endOfList" />
        </span>
      ) : isFetching ? (
        <LoadingSpinner size="l" className="mx-auto" />
      ) : (
        <Button
          theme="whitePrimary"
          className={cn(
            "!border-primary-500",
            "text-primary-500",
            "font-normal"
          )}
          onClick={onClickFetchMore}
          isLoading={isFetching}
        >
          <span>
            <Trans i18nKey="common.loadMore" />
          </span>
        </Button>
      )}
    </div>
  );
};
export default ShiftListLoadMoreView;
