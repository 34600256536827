import { DateTime, Duration } from "luxon";
import { ShiftRequestBaseWithNumbering } from "oneclick-component/src/store/apis/enhancedApi";
import {
  dateTimeNow,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { useEffect, useMemo, useState } from "react";

interface UseCancelApplyExpiryCountdownReturnValue {
  canCancelApply: boolean;
  durationUntilExpiry: Duration | null;
}
const useCancelApplyExpiryCountdown = (
  shiftRequest: ShiftRequestBaseWithNumbering | null
): UseCancelApplyExpiryCountdownReturnValue => {
  const [timeNow, setTimeNow] = useState<DateTime>(dateTimeNow());
  const cancelApplyExpiryTime = useMemo<DateTime | undefined>(() => {
    if (shiftRequest?.appliedAt == null) {
      return undefined;
    }
    const shiftAppliedAt = isoToDateTime(shiftRequest.appliedAt);
    const expiryTime = shiftAppliedAt.plus({ hours: 1 });
    return expiryTime;
  }, [shiftRequest?.appliedAt]);

  const isRejected = shiftRequest?.borrowingRequestStatus === "rejected";
  const canCancelApply =
    !isRejected &&
    cancelApplyExpiryTime != null &&
    cancelApplyExpiryTime > timeNow;

  const durationUntilExpiry = canCancelApply
    ? cancelApplyExpiryTime.diff(timeNow)
    : null;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeNow(dateTimeNow());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return {
    canCancelApply,
    durationUntilExpiry: durationUntilExpiry,
  };
};

export default useCancelApplyExpiryCountdown;
