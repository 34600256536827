import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import authgear, { PromptOption, SessionState } from "@authgear/web";
import cn from "classnames";

import {
  PtUser,
  useLazyPtUserAuthenticateHandlerPtPtUserAuthenticateGetQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { RootState } from "../store/store";
import AppRoutes from "../routes/AppRoutes";
import {
  initialized,
  loginSucceed,
  logoutSucceed,
  unauthenticated,
} from "../store/auth";

interface AuthContextValue {
  login: () => void;
  logout: () => void;
  fetchUser: () => Promise<PtUser | undefined>;
}

export const AuthContext = React.createContext<AuthContextValue>(null as any);

export default function AuthProvider(
  props: PropsWithChildren
): React.ReactElement {
  const dispatch = useDispatch();
  const [authenticate] =
    useLazyPtUserAuthenticateHandlerPtPtUserAuthenticateGetQuery();
  const isInitialized = useSelector((state: RootState) => {
    return state.auth.isInitialized;
  });

  const verifyUser = useCallback(
    async (token: string) => {
      const { data: user } = await authenticate();
      if (user) {
        dispatch(
          loginSucceed({
            meUser: user,
            token,
          })
        );
      } else {
        dispatch(unauthenticated());
      }
      return user;
    },
    [authenticate, dispatch]
  );

  const fetchUser = useCallback(async (): Promise<PtUser | undefined> => {
    try {
      await authgear.fetchUserInfo();
      if (authgear.sessionState === SessionState.Authenticated) {
        await authgear.refreshAccessTokenIfNeeded();
        if (authgear.accessToken != null) {
          return await verifyUser(authgear.accessToken);
        }
      }
    } catch (err: unknown) {
      throw err;
    }
  }, [verifyUser]);

  const login = useCallback(() => {
    authgear
      .startAuthentication({
        redirectURI: `${
          window.location.origin
        }${AppRoutes.AuthRedirect.render()}`,
        prompt: PromptOption.Login,
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  const logout = useCallback(() => {
    authgear
      .logout({
        redirectURI: `${
          window.location.origin
        }${AppRoutes.LogInScreen.render()}`,
      })
      .then(() => {
        dispatch(logoutSucceed());
      })
      .catch((err) => {
        throw err;
      });
  }, [dispatch]);

  useEffect(() => {
    if (isInitialized) {
      return;
    }
    fetchUser()
      .catch((err: unknown) => {
        throw err;
      })
      .finally(() => {
        dispatch(initialized());
      });
  }, [dispatch, fetchUser, isInitialized, verifyUser]);

  const contextValue = useMemo(
    () => ({
      login,
      logout,
      fetchUser,
    }),
    [fetchUser, login, logout]
  );

  if (!isInitialized) {
    return (
      <main
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </main>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}
