import { DateTime } from "luxon";
import { dateTimeNow } from "./datetime";

const HOUR_TO_OPEN_ALL_T3_SHIFTS = 11; // 11:00 am

const getLatestShiftStartTimeToOpenT3 = (): DateTime => {
  const now = dateTimeNow();
  const startOfToday = now.startOf("day");
  const threeDaysFromStartOfToday = startOfToday.plus({ days: 3 });
  const fourDaysFromStartOfToday = startOfToday.plus({ days: 4 });
  if (now.hour < HOUR_TO_OPEN_ALL_T3_SHIFTS) {
    return threeDaysFromStartOfToday;
  }
  return fourDaysFromStartOfToday;
};

export const isShiftStartTimeT3 = (startTime: DateTime): boolean => {
  const latestShiftStartTimeToOpenT3 = getLatestShiftStartTimeToOpenT3();
  return startTime < latestShiftStartTimeToOpenT3;
};
