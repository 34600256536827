import { AuthService } from "oneclick-component/src/services/auth";
import React, { PropsWithChildren, useRef } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { createStore } from "../store/store";
interface Props extends PropsWithChildren {
  authService?: AuthService;
}
export const StoreProvider = (props: Props): React.ReactElement => {
  const { authService, children } = props;
  const store = useRef(createStore({ authService }));
  const persistor = useRef(persistStore(store.current));
  return (
    <Provider store={store.current}>
      <PersistGate persistor={persistor.current}>{children}</PersistGate>
    </Provider>
  );
};
