import cn from "classnames";
import { DateTime } from "luxon";
import { SwiperClass } from "swiper/react";
import {
  EnvironmentBadge,
  StationBadge,
} from "oneclick-component/src/components/Badge";
import PopMenu from "oneclick-component/src/components/PopMenu";
import { Environment } from "oneclick-component/src/constants/environment";
import { LogoIcon } from "oneclick-component/src/icon";
import {
  dateTimeNow,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import React, {
  MouseEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { config } from "../../config";
import { KeyboardArrowLeftIcon } from "../../icon";
import AppRoutes from "../../routes/AppRoutes";
import MeAvatarImage from "../MeAvatarImage/MeAvatarImage";
import { LayoutSettingProps } from "../ProtectedRoute/ProtectedRoute";
import TabMenu from "../TabMenu";
import UserMenu from "./UserMenu";
import { MainLayoutOutletContext } from "./model";
import ShiftStationFilterView from "../ShiftStationFilterView";
import { replaceExistingURLSearchParams } from "../../utils/url";
import useMeUserStation from "../../hooks/useMeUserStation";
import MainLayoutHeaderFilter from "./HeaderFilter";

interface NavBarProps {
  shouldShowProfile: boolean;
  shouldShowBackNavigate: boolean;
  onBackClick: (e: MouseEvent<HTMLAnchorElement>) => void;
  navTitle: string;
}

const NavBar = (props: NavBarProps) => {
  const { shouldShowBackNavigate, shouldShowProfile, onBackClick, navTitle } =
    props;
  const meUserStation = useMeUserStation();

  const getControlElementClasses = useCallback((option: { open: boolean }) => {
    return cn(
      "flex",
      "max-w-xs",
      "items-center",
      "rounded-full",
      "bg-white",
      "text-sm",
      option.open && [
        "outline-none",
        "ring-2",
        "ring-primary-500",
        "ring-offset-2",
      ]
    );
  }, []);

  return (
    <nav
      className={cn(
        "mx-auto",
        "flex",
        "items-center",
        "justify-between",
        "h-16",
        "py-3",
        "px-5"
      )}
      aria-label="Global"
    >
      {shouldShowBackNavigate ? (
        <Link to="#" onClick={onBackClick}>
          <KeyboardArrowLeftIcon className="fill-black" />
        </Link>
      ) : (
        <div className={cn("flex", "relative", "items-center")}>
          <div className="relative">
            <Link to={AppRoutes.LogInScreen.render()}>
              <LogoIcon />
              <EnvironmentBadge
                environment={
                  Environment[config.environment as keyof typeof Environment]
                }
                className={cn(
                  "absolute",
                  "bottom-1/2",
                  "right-1/2",
                  "translate-x-1/2",
                  "translate-y-1/2"
                )}
              />
            </Link>
          </div>
          <div
            className={cn("block", "h-8", "w-px", "bg-gray-900/10", "mx-4")}
            aria-hidden="true"
          />
          {meUserStation != null ? (
            <StationBadge
              className="self-center"
              station={meUserStation}
              stationTeam={null}
            />
          ) : null}
        </div>
      )}
      <div className={cn("flex-1", "text-center", "text-black/60")}>
        {navTitle}
      </div>
      {shouldShowProfile ? (
        <PopMenu
          menuClass="relative ml-3"
          controlElementClass={getControlElementClasses}
          controlElement={<MeAvatarImage size="40" />}
          menuBodyClass="right-0 !z-30"
        >
          <UserMenu />
        </PopMenu>
      ) : null}
    </nav>
  );
};

export default function MainLayout(
  props: LayoutSettingProps
): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    shouldShowTabMenu = false,
    shouldShowHeaderFilter = false,
    shouldShowBackNavigate = false,
    shouldShowProfile = false,
    shouldShowBookmarkFilter = false,
  } = props;
  const [showStationFilterView, setShowStationFilterView] =
    useState<boolean>(false);
  const [shouldShowFutureMonth, setShouldShowFutureMonth] =
    useState<boolean>(true);
  const [shouldShowMonthPicker, setShouldShowMonthPicker] =
    useState<boolean>(false);
  const [navTitle, setNavTitle] = useState("");
  const [filterMonth, setFilterMonth] = useState<DateTime>(
    searchParams.get("year") == null && searchParams.get("month") == null
      ? dateTimeNow().set({ day: 1, hour: 0, minute: 0, second: 0 })
      : DateTime.local(
          parseInt(searchParams.get("year")!, 10),
          parseInt(searchParams.get("month")!, 10),
          1,
          0,
          0,
          0
        )
  );
  const [filterDate, setFilterDate] = useState<DateTime>(
    searchParams.get("date") == null ||
      isoToDateTime(searchParams.get("date")!) < dateTimeNow()
      ? dateTimeNow()
      : isoToDateTime(searchParams.get("date")!)
  );
  const [weekDatePickerControl, setWeekDatePickerControl] =
    useState<SwiperClass | null>(null);

  const [showBookmarkedOnly, setShowBookmarkedOnly] = useState<boolean>(false);

  const isFromApplication = useMemo(() => {
    if (location.state) {
      return location.state.key === "from-application";
    }
    return false;
  }, [location]);
  const [filterWorkingStationIds, setFilterWorkingStationIds] = useState<
    number[]
  >(
    searchParams
      .getAll("stationId")
      .map((i) => parseInt(i, 10))
      .filter((id) => !isNaN(id))
  );

  const onChangeStationFilter = useCallback(
    (newStationIds: number[]) => {
      const newStationIdParamPairs: string[][] =
        newStationIds.length === 0
          ? [["stationId", ""]]
          : newStationIds.map((id) => ["stationId", id.toString()]);
      setSearchParams((prev) =>
        replaceExistingURLSearchParams(prev, newStationIdParamPairs)
      );
      setFilterWorkingStationIds(newStationIds);
      setShowStationFilterView(false);
    },
    [setSearchParams]
  );

  const onUpdateFilterMonth = useCallback(
    (d: DateTime) => {
      setSearchParams((prev) =>
        replaceExistingURLSearchParams(prev, [
          ["year", `${d.year}`],
          ["month", `${d.month}`],
        ])
      );
      setFilterMonth(d);
    },
    [setSearchParams]
  );
  const onUpdateFilterDate = useCallback(
    (d: DateTime) => {
      setSearchParams((prev) =>
        replaceExistingURLSearchParams(prev, [["date", d.toISODate()!]])
      );
      setFilterDate(d);
    },
    [setSearchParams]
  );
  const toggleShouldShowMonthPicker = useCallback(() => {
    setShouldShowMonthPicker((prev) => !prev);
  }, []);
  const outletContext: MainLayoutOutletContext = useMemo(
    () => ({
      filterMonth,
      filterWorkingStationIds,
      filterDate,
      navTitle,
      setNavTitle,
      showBookmarkedOnly,
      setShouldShowFutureMonth,
      shouldShowFutureMonth,
    }),
    [
      setShouldShowFutureMonth,
      shouldShowFutureMonth,
      filterDate,
      filterMonth,
      filterWorkingStationIds,
      navTitle,
      setNavTitle,
      showBookmarkedOnly,
    ]
  );

  const onBackClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (isFromApplication) {
        navigate("/");
      } else {
        navigate(-1);
      }
    },
    [navigate, isFromApplication]
  );

  const resetFilterMonth = useCallback(() => {
    setFilterMonth(
      dateTimeNow().set({ day: 1, hour: 0, minute: 0, second: 0 })
    );
  }, []);

  const onClickBookmark = useCallback(() => {
    setShowBookmarkedOnly((prev) => !prev);
  }, []);

  const onClickFilter = useCallback(() => {
    setShowStationFilterView(true);
  }, []);

  const onHideFilter = useCallback(() => {
    setShowStationFilterView(false);
  }, []);

  useEffect(() => {
    /** reset title on page change */
    setNavTitle("");
  }, [location]);

  if (showStationFilterView) {
    return (
      <ShiftStationFilterView
        filterStationIds={filterWorkingStationIds}
        onSubmit={onChangeStationFilter}
        onDismiss={onHideFilter}
        className={cn("w-screen", "bg-white", "z-30", "h-screen")}
      />
    );
  }

  return (
    <main className={cn({ "pb-15": shouldShowTabMenu })}>
      <header className={cn("bg-white", "sticky", "top-0", "z-30")}>
        <NavBar
          onBackClick={onBackClick}
          shouldShowBackNavigate={shouldShowBackNavigate}
          shouldShowProfile={shouldShowProfile}
          navTitle={navTitle}
        />
        {shouldShowHeaderFilter ? (
          <MainLayoutHeaderFilter
            filterMonth={filterMonth}
            shouldShowFutureMonth={shouldShowFutureMonth}
            onChangeFilterMonth={onUpdateFilterMonth}
            filterDate={filterDate}
            onChangeFilterDate={onUpdateFilterDate}
            weekDatePickerControl={weekDatePickerControl}
            setWeekDatePickerControl={setWeekDatePickerControl}
            isBookmarkFilterActive={showBookmarkedOnly}
            onClickBookmark={onClickBookmark}
            onClickFilter={onClickFilter}
            shouldShowBookmarkFilter={shouldShowBookmarkFilter}
            filterStationCount={filterWorkingStationIds.length}
            shouldShowMonthPicker={shouldShowMonthPicker}
            toggleShouldShowMonthPicker={toggleShouldShowMonthPicker}
          />
        ) : null}
      </header>
      <Outlet context={outletContext} />
      <TabMenu
        resetFilterMonth={resetFilterMonth}
        shouldShow={shouldShowTabMenu}
      />
    </main>
  );
}
