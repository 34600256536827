import React, { MouseEvent } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";
import { Link, useMatch, useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import {
  FindShiftTabIcon,
  ConfirmedShiftTabIcon,
  PendingShiftTabIcon,
  HistoryShiftTabIcon,
} from "../../icon";

interface TabMenuProps {
  resetFilterMonth?: () => void;
  shouldShow?: boolean;
}

const TabMenu = (props: TabMenuProps): React.ReactElement => {
  const { resetFilterMonth, shouldShow } = props;
  const navigate = useNavigate();
  const homePathMatch = useMatch(AppRoutes.HomeScreen.path);
  const findShiftPathMatch = useMatch(AppRoutes.FindShiftScreen.path);
  const pendingShiftPathMatch = useMatch(AppRoutes.PendingShiftScreen.path);
  const shiftHistoryPathMatch = useMatch(AppRoutes.ShiftHistoryScreen.path);

  const makeNavigateAction = (path: string) => {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (resetFilterMonth) {
        resetFilterMonth();
      }

      navigate(path);
    };
  };

  if (!shouldShow) {
    return <></>;
  }

  return (
    <div
      className={cn(
        "fixed",
        "bottom-0",
        "w-full",
        "h-15",
        "border-t",
        "border-t-black/12",
        "bg-white",
        "grid",
        "grid-cols-4",
        "text-center",
        "pt-2",
        "text-xs",
        "font-normal",
        "z-20"
      )}
    >
      <Link to="/" onClick={makeNavigateAction("/")} className="relative">
        <div className={cn("mx-auto", "inline-block", "relative")}>
          <ConfirmedShiftTabIcon
            className={cn("w-6", "h-6", {
              "fill-primary-500": homePathMatch != null,
              "fill-black/24": homePathMatch == null,
            })}
          />
        </div>
        <p
          className={cn({
            "text-primary-500": homePathMatch != null,
            "text-black/24": homePathMatch == null,
          })}
        >
          <Trans i18nKey="tabMenu.confirmedShiftTab.title" />
        </p>
      </Link>
      <Link
        to={AppRoutes.FindShiftScreen.path}
        onClick={makeNavigateAction(AppRoutes.FindShiftScreen.path)}
        className="relative"
      >
        <div className={cn("mx-auto", "inline-block", "relative")}>
          <FindShiftTabIcon
            className={cn("w-6", "h-6", {
              "fill-primary-500": findShiftPathMatch != null,
              "fill-black/24": findShiftPathMatch == null,
            })}
          />
        </div>
        <p
          className={cn({
            "text-primary-500": findShiftPathMatch != null,
            "text-black/24": findShiftPathMatch == null,
          })}
        >
          <Trans i18nKey="tabMenu.findShiftTab.title" />
        </p>
      </Link>
      <Link
        to={AppRoutes.PendingShiftScreen.path}
        onClick={makeNavigateAction(AppRoutes.PendingShiftScreen.path)}
        className="relative"
      >
        <div className={cn("mx-auto", "inline-block", "relative")}>
          <PendingShiftTabIcon
            className={cn("w-6", "h-6", {
              "fill-primary-500": pendingShiftPathMatch != null,
              "fill-black/24": pendingShiftPathMatch == null,
            })}
          />
        </div>
        <p
          className={cn({
            "text-primary-500": pendingShiftPathMatch != null,
            "text-black/24": pendingShiftPathMatch == null,
          })}
        >
          <Trans i18nKey="tabMenu.pendingShiftTab.title" />
        </p>
      </Link>
      <Link to={AppRoutes.ShiftHistoryScreen.path} className="relative">
        <div className={cn("mx-auto", "inline-block", "relative")}>
          <HistoryShiftTabIcon
            className={cn("w-6", "h-6", {
              "fill-primary-500": shiftHistoryPathMatch != null,
              "fill-black/24": shiftHistoryPathMatch == null,
            })}
          />
        </div>
        <p
          className={cn({
            "text-primary-500": shiftHistoryPathMatch != null,
            "text-black/24": shiftHistoryPathMatch == null,
          })}
        >
          <Trans i18nKey="tabMenu.historyShiftTab.title" />
        </p>
      </Link>
    </div>
  );
};

export default TabMenu;
