import i18next, { Resource } from "i18next";
import ICU from "i18next-icu";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Locale } from "oneclick-component/src/i18n/Locale";
import zhHantHkTranslation from "./zh-Hant-HK/translation.json";

const resources: Resource = {
  "zh-Hant-HK": {
    translation: zhHantHkTranslation,
  },
};

i18next
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: [Locale.zhHantHK],
    supportedLngs: [Locale.zhHantHK],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator"],
    },
  })
  .catch(console.error);

export default i18next;
