import React, { useCallback, useEffect } from "react";
import { Trans } from "react-i18next";
import { ScrollRestoration } from "react-router-dom";
import cn from "classnames";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  useListPublicHolidaysHandlerPublicHolidaysGetQuery as useListPublicHoliday,
  useLazyPtUserListShiftsHandlerPtShiftsListGetQuery as useLazyPTShiftList,
  PtUserListShiftsHandlerPtShiftsListGetApiArg as PTShiftListArg,
  PtUserListShiftsHandlerPtShiftsListGetApiResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { ListEmptyImage } from "../image";
import ShiftListItem from "../components/ShiftListItem";
import useMainLayoutOutletContext from "../components/MainLayout/useMainLayoutOutletContext";
import useInfiniteQuery from "oneclick-component/src/hooks/useInfiniteQuery";
import ShiftListLoadMoreView from "../components/ShiftListLoadMoreView";
import { SHIFT_LIST_PAGE_SIZE } from "../constants/shift";

export const ShiftHistoryScreen = (): React.ReactElement => {
  const {
    filterMonth,
    filterWorkingStationIds,
    setShouldShowFutureMonth,
    showBookmarkedOnly,
  } = useMainLayoutOutletContext();

  const listShiftParams: PTShiftListArg = {
    endAfter: filterMonth.toISO(),
    startBefore: filterMonth.plus({ months: 1 }).toISO(),
    shiftRequestStatuses: ["hired", "applied"],
    shiftStatus: ["expired", "cancelled"],

    workingStationId: filterWorkingStationIds,
    pageIndex: 0,
    pageSize: SHIFT_LIST_PAGE_SIZE,
    excludeNonBookmarkedShifts: showBookmarkedOnly,
    sort: "duty_start_time+",
  };
  const {
    pages: shiftListPages,
    isFetchingNextPage,
    fetchNextPage,
    resetAndFetchFirstPage,
    hasNextPage,
    nextPageIndex,
  } = useInfiniteQuery<PtUserListShiftsHandlerPtShiftsListGetApiResponse>(
    useLazyPTShiftList,
    listShiftParams
  );
  const { data: holidays } = useListPublicHoliday({});

  const onClickFetchMore = useCallback(() => {
    if (nextPageIndex == null) {
      console.error("Fetch more detected without nextPageIndex");
      return;
    }
    fetchNextPage(nextPageIndex);
  }, [fetchNextPage, nextPageIndex]);

  useEffect(() => {
    setShouldShowFutureMonth(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetAndFetchFirstPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMonth, filterWorkingStationIds, showBookmarkedOnly]);

  return (
    <main>
      <ScrollRestoration />
      <div className="pt-2">
        {shiftListPages != null && shiftListPages.length > 0 ? (
          <>
            {shiftListPages.map((page) =>
              page.data.results.map((shift) => (
                <ShiftListItem
                  key={`shiftRequest-${shift.id}`}
                  shiftForPt={shift}
                  holidays={holidays?.results ?? []}
                  shouldNavigateOnClick={true}
                />
              ))
            )}
            <ShiftListLoadMoreView
              isFetching={isFetchingNextPage}
              onClickFetchMore={onClickFetchMore}
              hasNextPage={hasNextPage}
            />
          </>
        ) : isFetchingNextPage ? (
          <div
            className={cn(
              "fixed",
              "inset-0",
              "pointer-events-none",
              "flex",
              "flex-row",
              "items-center"
            )}
          >
            <LoadingSpinner size="l" className="mx-auto" />
          </div>
        ) : (
          <>
            <div className={cn("mt-33", "text-center")}>
              <ListEmptyImage className={cn("mx-auto", "mb-3")} />
              <p className={cn("text-xl", "font-medium", "mb-3")}>
                <Trans i18nKey="confirmedShift.empty.title" />
              </p>
              <p className={cn("text-sm", "font-normal")}>
                <Trans i18nKey="confirmedShift.empty.description" />
              </p>
            </div>
          </>
        )}
      </div>
    </main>
  );
};
