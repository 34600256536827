import {
  configureStore,
  combineReducers,
  EnhancedStore,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import { AuthService } from "oneclick-component/src/services/auth";
import { enhancedApi } from "oneclick-component/src/store/apis/enhancedApi";

const reducerMap = {
  [enhancedApi.reducerPath]: enhancedApi.reducer,
  auth: persistReducer(
    {
      key: "auth",
      storage,
      whitelist: ["token", "redirectTo"],
    },
    auth
  ),
};

export const reducer = combineReducers(reducerMap);

export type RootState = ReturnType<typeof reducer>;

export interface ThunkExtraArgument {
  authService?: AuthService;
}

export const createStore: (tea: ThunkExtraArgument) => EnhancedStore = (
  thunkExtraArgument: ThunkExtraArgument
) =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk: {
          extraArgument: thunkExtraArgument,
        },
      }).concat(enhancedApi.middleware),
  });
