import { useMemo } from "react";
import {
  PublicHoliday,
  useListPublicHolidaysHandlerPublicHolidaysGetQuery as useListPublicHoliday,
} from "oneclick-component/src/store/apis/enhancedApi";

const useHolidays = (startDate?: string, endDate?: string): PublicHoliday[] => {
  const { data } = useListPublicHoliday({
    startDate,
    endDate,
  });

  const holidays = useMemo(() => {
    if (data == null) {
      return [];
    }
    return data.results;
  }, [data]);

  return holidays;
};

export default useHolidays;
