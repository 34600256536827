import React, { useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { useGetMasterShiftIdFromApplicationHandlerPtMasterShiftsIdFromApplicationGetQuery as useGetMasterShiftApplication } from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";

import useTrackGTags from "../hooks/useTrackGTags";
import AppRoutes from "../routes/AppRoutes";

const MasterShiftApplicationScreen = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { t } = useTranslation();
  const showMessage = useShowMessage();

  const {
    data,
    error: getMasterShiftError,
    isLoading,
  } = useGetMasterShiftApplication(
    { token: token! },
    {
      skip: token == null,
    }
  );

  useEffect(() => {
    if (getMasterShiftError != null) {
      console.error(getMasterShiftError);
      showMessage({
        title: t("masterShift.application.detail.toast.fail.title"),
        type: "fail",
        showDismiss: true,
      });
    }
  }, [getMasterShiftError, showMessage, t]);

  useTrackGTags([
    {
      type: "event",
      name: "whatsapp_open_app",
      params: {
        shift_type: "multiple",
      },
    },
  ]);

  if (isLoading) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  if (data != null) {
    return (
      <Navigate
        to={AppRoutes.JobDetailScreen.render(data.masterShiftId)}
        replace={true}
        state={{ key: "from-application" }}
      />
    );
  }
  return <></>;
};

export default MasterShiftApplicationScreen;
