import { ReactElement } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  RouteObject,
  Outlet,
} from "react-router-dom";

import AppRoutes from "./AppRoutes";
import ShiftApplicationScreen from "../screen/ShiftApplicationScreen";
import ShiftDetailScreen from "../screen/ShiftDetailScreen/ShiftDetailScreen";
import AuthRedirect from "../screen/AuthRedirect";
import { LogInScreen } from "../screen/LogInScreen";
import { HomeScreen } from "../screen/HomeScreen";
import { ShiftHistoryScreen } from "../screen/ShiftHistoryScreen";
import { FindShiftScreen } from "../screen/FindShiftScreen";
import { PendingShiftScreen } from "../screen/PendingShiftScreen";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { PrivacyScreen } from "../screen/PrivacyScreen";
import WelcomeScreen from "../screen/WelcomeScreen";
import MasterShiftApplicationScreen from "../screen/MasterShiftApplicationScreen";
import MasterShiftApplicationSuccessScreen from "../screen/MasterShiftApplicationSuccessScreen";
import JobDetailScreen from "../screen/JobDetailScreen";

const decoratedRoutesConfig: RouteObject[] = [
  {
    path: AppRoutes.LogInScreen.path,
    element: <LogInScreen />,
  },
  {
    path: AppRoutes.PrivacyScreen.path,
    element: <PrivacyScreen />,
  },
  /** Detail screens */
  {
    element: (
      <ProtectedRoute shouldShowProfile={true}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: AppRoutes.WelcomeScreen.path,
        element: <WelcomeScreen />,
      },
      {
        path: AppRoutes.ShiftApplicationScreen.path,
        element: <ShiftApplicationScreen />,
      },
    ],
  },
  /** Screen with back and no profile */
  {
    element: (
      <ProtectedRoute shouldShowBackNavigate={true}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: AppRoutes.MasterShiftApplicationSuccessScreen.path,
        element: <MasterShiftApplicationSuccessScreen />,
      },
      {
        path: AppRoutes.MasterShiftApplicationScreen.path,
        element: <MasterShiftApplicationScreen />,
      },
      {
        path: AppRoutes.JobDetailScreen.path,
        element: <JobDetailScreen />,
      },
    ],
  },
  /** Detail screen with back */
  {
    element: (
      <ProtectedRoute shouldShowBackNavigate={true} shouldShowProfile={true}>
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: AppRoutes.ShiftDetailScreen.path,
        element: <ShiftDetailScreen />,
      },
    ],
  },
  /** Tab Screens WITHOUT Bookmark filter */
  {
    element: (
      <ProtectedRoute
        shouldShowTabMenu={true}
        shouldShowHeaderFilter={true}
        shouldShowProfile={true}
      >
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: AppRoutes.HomeScreen.path,
        element: <HomeScreen />,
      },
    ],
  },
  /** Tab Screens WITH Bookmark filter */
  {
    element: (
      <ProtectedRoute
        shouldShowTabMenu={true}
        shouldShowHeaderFilter={true}
        shouldShowProfile={true}
        shouldShowBookmarkFilter={true}
      >
        <Outlet />
      </ProtectedRoute>
    ),
    children: [
      {
        path: AppRoutes.FindShiftScreen.path,
        element: <FindShiftScreen />,
      },
      {
        path: AppRoutes.PendingShiftScreen.path,
        element: <PendingShiftScreen />,
      },
      {
        path: AppRoutes.ShiftHistoryScreen.path,
        element: <ShiftHistoryScreen />,
      },
    ],
  },
  {
    path: AppRoutes.AuthRedirect.path,
    element: <AuthRedirect />,
  },
];

export const RoutesConfig = Object.freeze(decoratedRoutesConfig);

const router = createBrowserRouter(decoratedRoutesConfig, {
  basename: "/",
});

const AppRouter = (): ReactElement => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
