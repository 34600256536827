export function replaceExistingURLSearchParams(
  prev: URLSearchParams,
  newParams: string[][]
): URLSearchParams {
  const result: string[][] = [];
  const newParamKeys = newParams.map(([key, _value]) => key);
  for (const [key, value] of prev.entries()) {
    if (!newParamKeys.includes(key)) {
      result.push([key, value]);
    }
  }
  newParams.forEach(([key, value]) => result.push([key, value]));

  return new URLSearchParams(result);
}
