import { Button, IconButton } from "oneclick-component/src/components/Button";
import React from "react";
import { Trans } from "react-i18next";
import { KeyboardArrowLeftIcon, SortIcon } from "../../icon";
import cn from "classnames";

interface Props {
  onDismiss: () => void;
  onReset: () => void;
  className?: string;
}

const ShiftStationFilterViewHeader = (props: Props): React.ReactElement => {
  const { onDismiss, onReset, className } = props;

  return (
    <header
      className={cn(
        "flex",
        "py-5",
        "px-2.5",
        "items-center",
        "justify-between",
        "border",
        "bg-white",
        "border-black/12",
        className
      )}
    >
      <IconButton
        onClick={onDismiss}
        icon={KeyboardArrowLeftIcon}
        theme="white"
        className={cn(
          "border-0",
          "shadow-none",
          "!p-2.5",
          "-my-2.5",
          "focus:!ring-0"
        )}
        iconClassName={cn("fill-black", "w-6", "h-6")}
      />
      <div className={cn("flex", "items-center", "gap-x-2.5")}>
        <SortIcon className={cn("w-4.5", "h-5", "fill-black/86")} />
        <span className={cn("text-base", "font-medium")}>
          <Trans i18nKey="shiftList.filter.header" />
        </span>
      </div>

      <Button
        onClick={onReset}
        theme="whitePrimary"
        className={cn(
          "!border-0",
          "!shadow-none",
          "!px-2.5",
          "!py-1.5",
          "!-my-1.5",
          "!outline-0",
          "focus:!ring-0"
        )}
      >
        <Trans i18nKey="shiftList.filter.reset" />
      </Button>
    </header>
  );
};

export default ShiftStationFilterViewHeader;
