import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import {
  ShiftForPtUserView,
  useApplyShiftHandlerPtShiftsShiftIdApplyPostMutation as useApply,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Trans, useTranslation } from "react-i18next";
import useMeUser from "../../hooks/useMeUser";
import useMeUserStation from "../../hooks/useMeUserStation";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import useShiftDisplayStatus from "../../components/ShiftListItem/hooks/useShiftDisplayStatus";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import CcoOverWorkHourDialog from "../../components/CcoOverWorkHourDialog.tsx/CcoOverWorkHourDialog";
import ga from "../../utils/ga";

interface Props {
  shift: ShiftForPtUserView;
  setAppliedSuccess: Dispatch<SetStateAction<boolean>>;
}

const ShiftApplicationSection = (props: Props): ReactElement => {
  const { shift, setAppliedSuccess } = props;
  const { t } = useTranslation();
  const me = useMeUser();
  const meStation = useMeUserStation();
  const showMessage = useShowMessage();
  const [isOverWorkDialogOpen, setIsOverWorkDialogOpen] = useState(false);
  const [applyShift, { isLoading: isApplying }] = useApply();

  const onClickApplyShift = useCallback(() => {
    if (isApplying) {
      return;
    }
    applyShift({
      shiftId: shift.id,
    })
      .unwrap()
      .then(() => {
        setAppliedSuccess(true);
        ga("event", "shift_apply", {
          shift_ids: shift.id,
          shift_type: "single",
        });
      })
      .catch((e) => {
        console.error(e);
        // TODO: change to check error id
        if (
          e.data.detail.description.startsWith(
            "CCO staff cannot work longer than"
          )
        ) {
          setIsOverWorkDialogOpen(true);
        } else {
          showMessage({
            title: t("shift.application.apply.toast.fail.title"),
            message: t("shift.application.apply.toast.fail.message"),
            type: "fail",
            showDismiss: true,
          });
        }
      });
  }, [applyShift, showMessage, t, isApplying, shift, setAppliedSuccess]);

  const onDialogClose = useCallback(() => {
    setIsOverWorkDialogOpen(false);
  }, []);

  const { canApply } = useShiftDisplayStatus(shift, meStation!);

  return (
    <div
      className={cn(
        "px-5",
        "pt-2",
        "fixed",
        "pb-5",
        "bottom-0",
        "left-0",
        "right-0",
        "border-t",
        "border-t-black/12",
        "bg-white"
      )}
    >
      <CcoOverWorkHourDialog
        isOpen={isOverWorkDialogOpen}
        onClose={onDialogClose}
      />
      {me?.role?.name === "CCO" ? null : (
        <p
          className={cn("text-sm", "font-medium", "mb-2", {
            "text-black/24": !canApply,
            "text-red-500": canApply,
          })}
        >
          <Trans i18nKey="masterShift.application.418Note" />
        </p>
      )}
      <Button
        className={cn("w-full", "mb-2")}
        onClick={onClickApplyShift}
        isLoading={isApplying}
        disabled={!canApply}
      >
        <Trans i18nKey="shift.application.button.apply" />
      </Button>
    </div>
  );
};

export default ShiftApplicationSection;
