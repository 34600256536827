import React from "react";
import cn from "classnames";
import { useSearchParams, Navigate } from "react-router-dom";
import { useGetShiftApplicationHandlerPtShiftsApplicationGetQuery } from "oneclick-component/src/store/apis/enhancedApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import useTrackGTags from "../hooks/useTrackGTags";
import AppRoutes from "../routes/AppRoutes";

const ShiftApplicationScreen = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const arg = token != null ? { token } : skipToken;

  const { data, isLoading } =
    useGetShiftApplicationHandlerPtShiftsApplicationGetQuery(arg);

  useTrackGTags([
    {
      type: "event",
      name: "whatsapp_open_app",
      params: {
        shift_type: "single",
      },
    },
  ]);

  if (isLoading) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  if (data != null) {
    return (
      <Navigate
        to={AppRoutes.ShiftDetailScreen.render(data.shiftId)}
        replace={true}
        state={{ key: "from-application" }}
      />
    );
  }
  return <></>;
};

export default ShiftApplicationScreen;
