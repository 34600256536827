import React, { useContext, useEffect, useRef } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import authgear from "@authgear/web";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { AuthContext } from "../providers/AuthProvider";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import AppRoutes from "../routes/AppRoutes";

const AuthRedirect = (): React.ReactElement => {
  const usedToken = useRef(false);
  const { fetchUser } = useContext(AuthContext);
  const redirectTo = useSelector((state: RootState) => state.auth.redirectTo);

  const navigate = useNavigate();

  useEffect(() => {
    async function updateToken() {
      try {
        await authgear.finishAuthentication();
        const user = await fetchUser();
        if (user?.pendingConsent) {
          navigate(AppRoutes.WelcomeScreen.render());
        } else {
          navigate(redirectTo);
        }
      } finally {
      }
    }

    if (!usedToken.current) {
      usedToken.current = true;
      updateToken().catch((e) => {
        throw e;
      });
    }
  }, [fetchUser, navigate, redirectTo]);

  return (
    <div
      className={cn(
        "fixed",
        "top-1/2",
        "left-1/2",
        "-translate-x-1/2",
        "-translate-y-1/2"
      )}
    >
      <LoadingSpinner size="l" />
    </div>
  );
};

export default AuthRedirect;
