import React, { useMemo } from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import {
  MasterShift,
  WorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { masterShiftTimePatternSchema } from "oneclick-component/src/models/shiftTimePattern";
import { makeShiftTimeIntervalDisplayText } from "oneclick-component/src/utils/shift";
import { StationBadge } from "oneclick-component/src/components/Badge";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { ShiftTimeDisplay } from "../../components/ShiftListItem/ShiftListItem";
import { DateIcon } from "../../icon";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";

interface MasterShiftDetailProps {
  masterShift: MasterShift;
}

export const MasterShiftInfo = (
  props: MasterShiftDetailProps
): React.ReactElement => {
  const { masterShift } = props;
  const { t } = useTranslation();
  const displayStation = useMemo<WorkingStation>(() => {
    return masterShift.isIncident
      ? masterShift.supportStation ?? masterShift.workingStation
      : masterShift.workingStation;
  }, [
    masterShift.isIncident,
    masterShift.supportStation,
    masterShift.workingStation,
  ]);
  const shiftDateSection = useMemo(() => {
    const shiftTimePatternResult = masterShiftTimePatternSchema.safeParse(
      masterShift.shiftDatetimeSlots
    );
    if (!shiftTimePatternResult.success) {
      console.warn(
        "Failed to parse master shift time pattern JSON",
        shiftTimePatternResult.error
      );
      return <></>;
    }

    let startDateString = "";
    let endDateString = "";

    const timeRanges = shiftTimePatternResult.data.shiftTimeRanges.map(
      (range) => {
        const startDate = isoToDateTime(range.dutyStartTime);
        const isDayShift = startDate.hour >= 5 && startDate.hour < 18;
        return (
          <ShiftTimeDisplay
            key={`shiftTime-${range.dutyStartTime}`}
            isDayShift={isDayShift}
            text={makeShiftTimeIntervalDisplayText(
              range.dutyStartTime,
              range.dutyEndTime ?? null
            )}
          />
        );
      }
    );

    if (shiftTimePatternResult.data.recurring) {
      const startDate = isoToDateTime(
        shiftTimePatternResult.data.recurrenceStartDate
      );
      const endDate = isoToDateTime(
        shiftTimePatternResult.data.recurrenceEndDate
      );
      startDateString = formatDateTime(
        startDate,
        t("common.dateWithWeekday.displayFormat")
      );
      endDateString = formatDateTime(
        endDate,
        t("common.dateWithWeekday.displayFormat")
      );
      return (
        <>
          <div className={cn("flex", "flex-row")}>
            <div className={cn("w-5", "h-5", "mr-2", "mt-0.5")}>
              <DateIcon className={cn("fill-black/60", "mx-auto")} />
            </div>
            <div className="mb-3">
              <div className={cn("flex", "flex-row", "items-center", "mb-0.5")}>
                <p className={cn("text-black/60", "mr-2")}>
                  <Trans i18nKey="masterShift.shift.dateRange.fromText" />
                </p>
                <p className={cn("font-medium", "text-black/86")}>
                  {startDateString}
                </p>
              </div>
              <div className={cn("flex", "flex-row", "items-center")}>
                <p className={cn("text-black/60", "mr-2")}>
                  <Trans i18nKey="masterShift.shift.dateRange.toText" />
                </p>
                <p className={cn("font-medium", "text-black/86")}>
                  {endDateString}
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4">{timeRanges}</div>
        </>
      );
    }

    const sortedDates = shiftTimePatternResult.data.shiftStartDates.sort(
      (a, b) => isoToDateTime(a).toMillis() - isoToDateTime(b).toMillis()
    );

    return (
      <>
        <div className={cn("flex", "flex-row")}>
          <div className={cn("w-5", "h-5", "mr-2", "mt-0.5")}>
            <DateIcon className={cn("fill-black/60", "mx-auto")} />
          </div>
          <div className="mb-3">
            {sortedDates.map((date) => (
              <p
                key={`shiftDate-${date}`}
                className={cn("font-medium", "text-black/86", "mb-0.5")}
              >
                {formatDateTime(
                  isoToDateTime(date),
                  t("common.dateWithWeekday.displayFormat")
                )}
              </p>
            ))}
          </div>
        </div>
        <div className="mb-4">{timeRanges}</div>
      </>
    );
  }, [masterShift.shiftDatetimeSlots, t]);

  return (
    <div className={cn("bg-white", "mb-4", "w-full")}>
      <h1
        className={cn(
          "text-lg",
          "leading-7",
          "font-medium",
          "text-black/86",
          "flex",
          "items-center",
          "gap-x-3"
        )}
      >
        <ShiftBadge
          rate={masterShift.rate}
          isIncident={masterShift.isIncident}
          isHideCco={true}
        />
        <span>{masterShift.shiftTitle}</span>
      </h1>
      <div className="mt-3">
        {shiftDateSection}
        <StationBadge
          showStationName={true}
          station={displayStation}
          stationTeam={null}
        />
      </div>
      <hr className="my-4" />
      <h6 className={cn("text-xs", "leading-5", "text-black/60", "mt-5")}>
        <Trans i18nKey="masterShift.application.detail.description" />
      </h6>
      <p
        className={cn(
          "text-sm",
          "font-medium",
          "text-black/86",
          "mt-3",
          "whitespace-pre-wrap",
          "break-words"
        )}
      >
        {masterShift.shiftDescription}
      </p>
    </div>
  );
};
