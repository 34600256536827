import { TFunction } from "i18next";
import { ShiftDisplayStatus } from "./useShiftDisplayStatus";
import {
  ConfirmIcon,
  StatusCancelIcon,
  StatusPendingIcon,
  StatusUnavailableIcon,
  WelcomeApplyIcon,
} from "../../../../icon";

export const makeWelcomeApplyShiftDisplayStatus: (
  t: TFunction,
  isIncident: boolean
) => ShiftDisplayStatus = (t, isIncident) => ({
  calendar: "normal",
  statusIcon: WelcomeApplyIcon,
  statusText: isIncident
    ? t("shiftList.status.welcomeIncident")
    : t("shiftList.status.welcome"),
  canApply: true,
  action: isIncident ? null : "bookmark",
});

export const makeConflictedShiftDisplayStatus: (
  t: TFunction,
  conflict: "hiredShift" | "regularSchedule"
) => ShiftDisplayStatus = (t, conflict) => ({
  calendar: "conflicted",
  statusIcon: StatusUnavailableIcon,
  statusText:
    conflict === "hiredShift"
      ? t("shiftList.status.conflict")
      : t("shiftList.status.conflictWithRegularSchedule"),
  canApply: false,
  action: null, // TODO: TBC by client #2205
});

// no need conflicted incident shift display status, incident will never render as conflicted

export const makeUnavailableShiftDisplayStatus: (
  t: TFunction,
  isLiked: boolean,
  isIncident: boolean
) => ShiftDisplayStatus = (t, isLiked, isIncident) => {
  const statusText = (() => {
    if (isIncident) {
      return t("shiftList.status.notAvailable");
    }
    return isLiked
      ? t("shiftList.status.notAvailableLiked")
      : t("shiftList.status.notAvailableNotLiked");
  })();
  return {
    calendar: "unavailable",
    statusIcon: StatusUnavailableIcon,
    statusText: statusText,
    canApply: false,
    action: isIncident ? null : "like",
  };
};

export const makeWaitingListShiftDisplayStatus: (
  t: TFunction,
  isBorrowing: boolean,
  index: number,
  isIncident: boolean
) => ShiftDisplayStatus = (t, isBorrowing, index, isIncident) => {
  let statusText = "";

  if (isIncident) {
    statusText = t("shiftList.status.incidentWaitingList");
  } else if (isBorrowing) {
    statusText = t("shiftList.status.borrowingWaitingList", { n: index });
  } else {
    statusText = t("shiftList.status.waitingList", { n: index });
  }

  return {
    calendar: "normal",
    statusIcon: isIncident ? ConfirmIcon : StatusPendingIcon,
    statusText: statusText,
    canApply: false,
    action: isIncident ? null : "bookmark",
  };
};

export const makeApplySuccessFutureShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "normal",
  statusIcon: ConfirmIcon,
  statusText: t("shiftList.status.successFuture"),
  canApply: false,
  action: null,
});

export const makeApplyIncidentSuccessShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "normal",
  statusIcon: ConfirmIcon,
  statusText: t("shiftList.status.successIncident"),
  canApply: false,
  action: null,
});

export const makeApplySuccessPastShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "normal",
  statusIcon: ConfirmIcon,
  statusText: t("shiftList.status.successPast"),
  canApply: false,
  action: null,
});

export const makeRejectedShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "unavailable",
  statusIcon: StatusUnavailableIcon,
  statusText: t("shiftList.status.rejected"),
  canApply: false,
  action: null,
});

export const makeCancelledShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "unavailable",
  statusIcon: StatusCancelIcon,
  statusText: t("shiftList.status.cancelled"),
  canApply: false,
  action: null,
});

// from #2237, past shifts should display its corresponding status instead of showing this expired
// this status should have no use case
export const makeExpiredShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "unavailable",
  statusIcon: StatusCancelIcon,
  statusText: t("shiftList.status.expired"),
  canApply: false,
  action: null,
});

// this should not happen, but put it here just in case
export const makePtSuspendedShiftDisplayStatus: (
  t: TFunction
) => ShiftDisplayStatus = (t) => ({
  calendar: "unavailable",
  statusIcon: StatusUnavailableIcon,
  statusText: t("shiftList.status.ptUserSuspended"),
  canApply: false,
  action: null,
});
