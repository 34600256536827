export const privacy = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
      p, ul, li, ol {
        margin: 10px
      }
      .lst-kix_list_14-1 > li:before {
        content: "" counter(lst-ctn-kix_list_14-1, decimal) ". ";
      }
      .lst-kix_list_14-3 > li:before {
        content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
      }
      .lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1;
      }
      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0;
      }
      .lst-kix_list_14-0 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) ". ";
      }
      .lst-kix_list_14-4 > li:before {
        content: "" counter(lst-ctn-kix_list_14-4, decimal) ". ";
      }
      ol.lst-kix_list_18-5.start {
        counter-reset: lst-ctn-kix_list_18-5 0;
      }
      .lst-kix_list_14-5 > li:before {
        content: "" counter(lst-ctn-kix_list_14-5, decimal) ". ";
      }
      .lst-kix_list_14-7 > li:before {
        content: "" counter(lst-ctn-kix_list_14-7, decimal) ". ";
      }
      .lst-kix_list_14-6 > li:before {
        content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
      }
      ol.lst-kix_list_20-2.start {
        counter-reset: lst-ctn-kix_list_20-2 0;
      }
      .lst-kix_list_9-0 > li {
        counter-increment: lst-ctn-kix_list_9-0;
      }
      ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0;
      }
      ol.lst-kix_list_11-8.start {
        counter-reset: lst-ctn-kix_list_11-8 0;
      }
      .lst-kix_list_14-2 > li:before {
        content: "" counter(lst-ctn-kix_list_14-2, decimal) ". ";
      }
      .lst-kix_list_20-7 > li {
        counter-increment: lst-ctn-kix_list_20-7;
      }
      ol.lst-kix_list_26-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-0 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-5 {
        list-style-type: none;
      }
      .lst-kix_list_24-7 > li {
        counter-increment: lst-ctn-kix_list_24-7;
      }
      ul.lst-kix_list_17-4 {
        list-style-type: none;
      }
      ul.lst-kix_list_17-3 {
        list-style-type: none;
      }
      .lst-kix_list_14-8 > li:before {
        content: "" counter(lst-ctn-kix_list_14-8, decimal) ". ";
      }
      ul.lst-kix_list_17-2 {
        list-style-type: none;
      }
      .lst-kix_list_18-8 > li {
        counter-increment: lst-ctn-kix_list_18-8;
      }
      ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0;
      }
      ol.lst-kix_list_26-7.start {
        counter-reset: lst-ctn-kix_list_26-7 0;
      }
      ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0;
      }
      .lst-kix_list_5-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_14-8 > li {
        counter-increment: lst-ctn-kix_list_14-8;
      }
      ol.lst-kix_list_22-3.start {
        counter-reset: lst-ctn-kix_list_22-3 0;
      }
      .lst-kix_list_24-7 > li:before {
        content: "" counter(lst-ctn-kix_list_24-7, decimal) ". ";
      }
      ol.lst-kix_list_16-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_16-6 {
        list-style-type: none;
      }
      .lst-kix_list_24-8 > li:before {
        content: "" counter(lst-ctn-kix_list_24-8, decimal) ". ";
      }
      ol.lst-kix_list_16-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_16-8 {
        list-style-type: none;
      }
      .lst-kix_list_5-3 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0;
      }
      ol.lst-kix_list_24-6.start {
        counter-reset: lst-ctn-kix_list_24-6 0;
      }
      ol.lst-kix_list_16-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_16-2 {
        list-style-type: none;
      }
      .lst-kix_list_5-2 > li:before {
        content: "\\0025c6   ";
      }
      ol.lst-kix_list_16-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-3 > li {
        counter-increment: lst-ctn-kix_list_8-3;
      }
      ol.lst-kix_list_16-4 {
        list-style-type: none;
      }
      .lst-kix_list_5-1 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_18-0.start {
        counter-reset: lst-ctn-kix_list_18-0 2;
      }
      .lst-kix_list_24-2 > li:before {
        content: "" counter(lst-ctn-kix_list_24-2, decimal) ". ";
      }
      ol.lst-kix_list_16-0 {
        list-style-type: none;
      }
      .lst-kix_list_5-7 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_20-7.start {
        counter-reset: lst-ctn-kix_list_20-7 0;
      }
      .lst-kix_list_5-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_5-8 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_24-3 > li:before {
        content: "" counter(lst-ctn-kix_list_24-3, decimal) ". ";
      }
      .lst-kix_list_24-4 > li:before {
        content: "" counter(lst-ctn-kix_list_24-4, decimal) ". ";
      }
      .lst-kix_list_9-4 > li {
        counter-increment: lst-ctn-kix_list_9-4;
      }
      .lst-kix_list_24-5 > li:before {
        content: "" counter(lst-ctn-kix_list_24-5, decimal) ". ";
      }
      .lst-kix_list_5-4 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_5-5 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_24-6 > li:before {
        content: "" counter(lst-ctn-kix_list_24-6, decimal) ". ";
      }
      ol.lst-kix_list_16-4.start {
        counter-reset: lst-ctn-kix_list_16-4 0;
      }
      ol.lst-kix_list_14-1.start {
        counter-reset: lst-ctn-kix_list_14-1 0;
      }
      .lst-kix_list_23-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_6-1 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_6-3 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_27-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_27-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_27-8 {
        list-style-type: none;
      }
      .lst-kix_list_23-3 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_23-7 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_6-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_6-4 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_27-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-8.start {
        counter-reset: lst-ctn-kix_list_14-8 0;
      }
      ol.lst-kix_list_27-2 {
        list-style-type: none;
      }
      .lst-kix_list_23-2 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_27-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_27-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_27-3 {
        list-style-type: none;
      }
      .lst-kix_list_23-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_23-8 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_27-0 {
        list-style-type: none;
      }
      .lst-kix_list_23-1 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_6-2 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_24-1 > li:before {
        content: "" counter(lst-ctn-kix_list_24-1, decimal) ". ";
      }
      .lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5;
      }
      .lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8;
      }
      .lst-kix_list_24-0 > li:before {
        content: "" counter(lst-ctn-kix_list_24-0, decimal) ". ";
      }
      .lst-kix_list_6-8 > li:before {
        content: "\\0025c6   ";
      }
      ol.lst-kix_list_26-2.start {
        counter-reset: lst-ctn-kix_list_26-2 0;
      }
      .lst-kix_list_6-5 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_6-7 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_23-4 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_23-5 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_6-6 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0;
      }
      ol.lst-kix_list_27-6.start {
        counter-reset: lst-ctn-kix_list_27-6 0;
      }
      .lst-kix_list_7-4 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_7-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_18-5 > li {
        counter-increment: lst-ctn-kix_list_18-5;
      }
      .lst-kix_list_22-2 > li:before {
        content: "" counter(lst-ctn-kix_list_22-2, decimal) ". ";
      }
      .lst-kix_list_22-6 > li:before {
        content: "" counter(lst-ctn-kix_list_22-6, decimal) ". ";
      }
      ol.lst-kix_list_24-1.start {
        counter-reset: lst-ctn-kix_list_24-1 0;
      }
      .lst-kix_list_22-2 > li {
        counter-increment: lst-ctn-kix_list_22-2;
      }
      .lst-kix_list_7-2 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_27-2 > li {
        counter-increment: lst-ctn-kix_list_27-2;
      }
      .lst-kix_list_8-6 > li {
        counter-increment: lst-ctn-kix_list_8-6;
      }
      .lst-kix_list_22-0 > li:before {
        content: "" counter(lst-ctn-kix_list_22-0, decimal) ". ";
      }
      .lst-kix_list_22-8 > li:before {
        content: "" counter(lst-ctn-kix_list_22-8, decimal) ". ";
      }
      ol.lst-kix_list_22-8.start {
        counter-reset: lst-ctn-kix_list_22-8 0;
      }
      ol.lst-kix_list_9-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_22-5.start {
        counter-reset: lst-ctn-kix_list_22-5 0;
      }
      .lst-kix_list_24-3 > li {
        counter-increment: lst-ctn-kix_list_24-3;
      }
      .lst-kix_list_13-7 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_9-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-5 {
        list-style-type: none;
      }
      .lst-kix_list_7-8 > li:before {
        content: "\\0025c6   ";
      }
      ol.lst-kix_list_9-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-2 {
        list-style-type: none;
      }
      .lst-kix_list_22-4 > li:before {
        content: "" counter(lst-ctn-kix_list_22-4, decimal) ". ";
      }
      ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0;
      }
      .lst-kix_list_15-5 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_26-0.start {
        counter-reset: lst-ctn-kix_list_26-0 0;
      }
      .lst-kix_list_9-8 > li {
        counter-increment: lst-ctn-kix_list_9-8;
      }
      .lst-kix_list_27-3 > li {
        counter-increment: lst-ctn-kix_list_27-3;
      }
      .lst-kix_list_4-1 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_15-7 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_19-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_19-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-3 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_4-5 > li:before {
        content: "\\0025c6   ";
      }
      ul.lst-kix_list_19-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_19-4 {
        list-style-type: none;
      }
      ul.lst-kix_list_19-3 {
        list-style-type: none;
      }
      .lst-kix_list_25-5 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_19-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_19-1 {
        list-style-type: none;
      }
      .lst-kix_list_10-5 > li {
        counter-increment: lst-ctn-kix_list_10-5;
      }
      ul.lst-kix_list_19-0 {
        list-style-type: none;
      }
      .lst-kix_list_15-1 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_24-4 > li {
        counter-increment: lst-ctn-kix_list_24-4;
      }
      .lst-kix_list_25-7 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_18-3.start {
        counter-reset: lst-ctn-kix_list_18-3 0;
      }
      .lst-kix_list_15-3 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_22-1 > li {
        counter-increment: lst-ctn-kix_list_22-1;
      }
      ol.lst-kix_list_24-4.start {
        counter-reset: lst-ctn-kix_list_24-4 0;
      }
      ul.lst-kix_list_19-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_18-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_18-8 {
        list-style-type: none;
      }
      .lst-kix_list_16-2 > li {
        counter-increment: lst-ctn-kix_list_16-2;
      }
      ol.lst-kix_list_18-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_18-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_18-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_18-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0;
      }
      ol.lst-kix_list_27-4.start {
        counter-reset: lst-ctn-kix_list_27-4 0;
      }
      .lst-kix_list_20-0 > li {
        counter-increment: lst-ctn-kix_list_20-0;
      }
      ol.lst-kix_list_16-7.start {
        counter-reset: lst-ctn-kix_list_16-7 0;
      }
      ol.lst-kix_list_18-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_18-1 {
        list-style-type: none;
      }
      .lst-kix_list_9-3 > li {
        counter-increment: lst-ctn-kix_list_9-3;
      }
      ol.lst-kix_list_18-2 {
        list-style-type: none;
      }
      .lst-kix_list_11-2 > li {
        counter-increment: lst-ctn-kix_list_11-2;
      }
      .lst-kix_list_26-5 > li {
        counter-increment: lst-ctn-kix_list_26-5;
      }
      ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0;
      }
      ol.lst-kix_list_8-8 {
        list-style-type: none;
      }
      .lst-kix_list_12-3 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_8-4 {
        list-style-type: none;
      }
      .lst-kix_list_12-1 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_8-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-0 {
        list-style-type: none;
      }
      .lst-kix_list_16-3 > li {
        counter-increment: lst-ctn-kix_list_16-3;
      }
      ol.lst-kix_list_8-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-4 > li {
        counter-increment: lst-ctn-kix_list_10-4;
      }
      .lst-kix_list_14-1 > li {
        counter-increment: lst-ctn-kix_list_14-1;
      }
      .lst-kix_list_13-3 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_13-5 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_12-5 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_18-4 > li {
        counter-increment: lst-ctn-kix_list_18-4;
      }
      .lst-kix_list_12-7 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_13-1 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_24-8 > li {
        counter-increment: lst-ctn-kix_list_24-8;
      }
      .lst-kix_list_22-8 > li {
        counter-increment: lst-ctn-kix_list_22-8;
      }
      ol.lst-kix_list_11-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0;
      }
      .lst-kix_list_3-0 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_11-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-5.start {
        counter-reset: lst-ctn-kix_list_20-5 0;
      }
      ul.lst-kix_list_5-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_5-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_5-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-0 {
        list-style-type: none;
      }
      ul.lst-kix_list_5-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-1 {
        list-style-type: none;
      }
      .lst-kix_list_21-8 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_26-5 > li:before {
        content: "" counter(lst-ctn-kix_list_26-5, lower-roman) ". ";
      }
      .lst-kix_list_16-0 > li {
        counter-increment: lst-ctn-kix_list_16-0;
      }
      .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0;
      }
      ul.lst-kix_list_5-0 {
        list-style-type: none;
      }
      .lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0;
      }
      .lst-kix_list_3-4 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_18-2.start {
        counter-reset: lst-ctn-kix_list_18-2 0;
      }
      ul.lst-kix_list_5-3 {
        list-style-type: none;
      }
      .lst-kix_list_3-3 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_5-4 {
        list-style-type: none;
      }
      .lst-kix_list_26-8 > li:before {
        content: "" counter(lst-ctn-kix_list_26-8, lower-roman) ". ";
      }
      ul.lst-kix_list_5-1 {
        list-style-type: none;
      }
      .lst-kix_list_8-0 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, upper-roman) ". ";
      }
      ul.lst-kix_list_5-2 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li:before {
        content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
      }
      .lst-kix_list_3-8 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_21-0 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0;
      }
      .lst-kix_list_26-1 > li:before {
        content: "" counter(lst-ctn-kix_list_26-1, decimal) "\\003001  ";
      }
      .lst-kix_list_21-1 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_8-3 > li:before {
        content: "" counter(lst-ctn-kix_list_8-3, lower-latin) ". ";
      }
      ul.lst-kix_list_13-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_22-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_13-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_22-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_13-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_13-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_22-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_13-1 {
        list-style-type: none;
      }
      .lst-kix_list_3-7 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_22-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_13-0 {
        list-style-type: none;
      }
      .lst-kix_list_26-4 > li:before {
        content: "" counter(lst-ctn-kix_list_26-4, decimal) "\\003001  ";
      }
      ol.lst-kix_list_22-2 {
        list-style-type: none;
      }
      .lst-kix_list_8-4 > li:before {
        content: "" counter(lst-ctn-kix_list_8-4, decimal) ". ";
      }
      ol.lst-kix_list_22-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_22-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_22-1 {
        list-style-type: none;
      }
      .lst-kix_list_10-2 > li {
        counter-increment: lst-ctn-kix_list_10-2;
      }
      ol.lst-kix_list_22-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0;
      }
      ol.lst-kix_list_26-4.start {
        counter-reset: lst-ctn-kix_list_26-4 0;
      }
      ul.lst-kix_list_13-8 {
        list-style-type: none;
      }
      .lst-kix_list_11-1 > li:before {
        content: "" counter(lst-ctn-kix_list_11-1, decimal) "\\003001  ";
      }
      ul.lst-kix_list_13-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_13-6 {
        list-style-type: none;
      }
      .lst-kix_list_21-5 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_21-4 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_11-0 > li:before {
        content: "" counter(lst-ctn-kix_list_11-0, upper-latin) ". ";
      }
      ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0;
      }
      .lst-kix_list_26-0 > li:before {
        content: "" counter(lst-ctn-kix_list_26-0, decimal) ". ";
      }
      .lst-kix_list_8-8 > li:before {
        content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
      }
      ol.lst-kix_list_2-2 {
        list-style-type: none;
      }
      .lst-kix_list_16-8 > li:before {
        content: "" counter(lst-ctn-kix_list_16-8, decimal) ". ";
      }
      ol.lst-kix_list_2-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-4 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-1 {
        list-style-type: none;
      }
      .lst-kix_list_16-7 > li:before {
        content: "" counter(lst-ctn-kix_list_16-7, decimal) ". ";
      }
      ol.lst-kix_list_2-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-1 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-8 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_4-7 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_14-2 > li {
        counter-increment: lst-ctn-kix_list_14-2;
      }
      ol.lst-kix_list_20-0.start {
        counter-reset: lst-ctn-kix_list_20-0 3;
      }
      .lst-kix_list_17-0 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_4-8 {
        list-style-type: none;
      }
      .lst-kix_list_16-0 > li:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) ". ";
      }
      ol.lst-kix_list_26-3.start {
        counter-reset: lst-ctn-kix_list_26-3 0;
      }
      ul.lst-kix_list_4-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_4-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_27-8.start {
        counter-reset: lst-ctn-kix_list_27-8 0;
      }
      .lst-kix_list_25-1 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_8-4 > li {
        counter-increment: lst-ctn-kix_list_8-4;
      }
      ul.lst-kix_list_4-0 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-7 {
        list-style-type: none;
      }
      .lst-kix_list_25-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_16-4 > li:before {
        content: "" counter(lst-ctn-kix_list_16-4, decimal) ". ";
      }
      ul.lst-kix_list_4-1 {
        list-style-type: none;
      }
      ul.lst-kix_list_23-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0;
      }
      .lst-kix_list_16-3 > li:before {
        content: "" counter(lst-ctn-kix_list_16-3, decimal) ". ";
      }
      ul.lst-kix_list_4-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_4-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_4-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_4-3 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li {
        counter-increment: lst-ctn-kix_list_11-3;
      }
      ol.lst-kix_list_18-1.start {
        counter-reset: lst-ctn-kix_list_18-1 0;
      }
      ul.lst-kix_list_12-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0;
      }
      ul.lst-kix_list_12-5 {
        list-style-type: none;
      }
      .lst-kix_list_17-7 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_12-4 {
        list-style-type: none;
      }
      ul.lst-kix_list_12-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_12-2 {
        list-style-type: none;
      }
      .lst-kix_list_16-7 > li {
        counter-increment: lst-ctn-kix_list_16-7;
      }
      ul.lst-kix_list_12-1 {
        list-style-type: none;
      }
      .lst-kix_list_17-8 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_12-0 {
        list-style-type: none;
      }
      .lst-kix_list_17-3 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_27-7 > li {
        counter-increment: lst-ctn-kix_list_27-7;
      }
      .lst-kix_list_17-4 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_12-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-6.start {
        counter-reset: lst-ctn-kix_list_20-6 0;
      }
      ul.lst-kix_list_12-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0;
      }
      .lst-kix_list_7-0 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_27-7.start {
        counter-reset: lst-ctn-kix_list_27-7 0;
      }
      .lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2;
      }
      .lst-kix_list_16-5 > li {
        counter-increment: lst-ctn-kix_list_16-5;
      }
      ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0;
      }
      .lst-kix_list_26-2 > li {
        counter-increment: lst-ctn-kix_list_26-2;
      }
      .lst-kix_list_22-5 > li:before {
        content: "" counter(lst-ctn-kix_list_22-5, decimal) ". ";
      }
      .lst-kix_list_2-4 > li:before {
        content: "" counter(lst-ctn-kix_list_2-4, decimal) "\\003001  ";
      }
      .lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
      }
      .lst-kix_list_22-1 > li:before {
        content: "" counter(lst-ctn-kix_list_22-1, decimal) ". ";
      }
      .lst-kix_list_27-4 > li:before {
        content: "" counter(lst-ctn-kix_list_27-4, decimal) "\\003001  ";
      }
      .lst-kix_list_20-2 > li {
        counter-increment: lst-ctn-kix_list_20-2;
      }
      .lst-kix_list_7-3 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_7-5 {
        list-style-type: none;
      }
      .lst-kix_list_10-0 > li:before {
        content: "" counter(lst-ctn-kix_list_10-0, upper-latin) ". ";
      }
      ul.lst-kix_list_7-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_7-3 {
        list-style-type: none;
      }
      .lst-kix_list_9-7 > li {
        counter-increment: lst-ctn-kix_list_9-7;
      }
      ul.lst-kix_list_7-4 {
        list-style-type: none;
      }
      .lst-kix_list_13-8 > li:before {
        content: "\\0025c6   ";
      }
      ol.lst-kix_list_14-6.start {
        counter-reset: lst-ctn-kix_list_14-6 0;
      }
      .lst-kix_list_18-3 > li:before {
        content: "" counter(lst-ctn-kix_list_18-3, decimal) ". ";
      }
      .lst-kix_list_18-7 > li:before {
        content: "" counter(lst-ctn-kix_list_18-7, decimal) ". ";
      }
      ul.lst-kix_list_7-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_7-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_26-8.start {
        counter-reset: lst-ctn-kix_list_26-8 0;
      }
      .lst-kix_list_18-6 > li {
        counter-increment: lst-ctn-kix_list_18-6;
      }
      ul.lst-kix_list_7-1 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li {
        counter-increment: lst-ctn-kix_list_8-7;
      }
      ul.lst-kix_list_7-2 {
        list-style-type: none;
      }
      .lst-kix_list_27-0 > li:before {
        content: "" counter(lst-ctn-kix_list_27-0, upper-latin) ". ";
      }
      ul.lst-kix_list_7-0 {
        list-style-type: none;
      }
      .lst-kix_list_7-7 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0;
      }
      .lst-kix_list_20-4 > li {
        counter-increment: lst-ctn-kix_list_20-4;
      }
      .lst-kix_list_15-4 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_9-5 > li {
        counter-increment: lst-ctn-kix_list_9-5;
      }
      ol.lst-kix_list_24-8 {
        list-style-type: none;
      }
      .lst-kix_list_10-4 > li:before {
        content: "" counter(lst-ctn-kix_list_10-4, decimal) "\\003001  ";
      }
      .lst-kix_list_10-8 > li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
      }
      ol.lst-kix_list_20-4.start {
        counter-reset: lst-ctn-kix_list_20-4 0;
      }
      .lst-kix_list_4-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_27-0 > li {
        counter-increment: lst-ctn-kix_list_27-0;
      }
      ol.lst-kix_list_24-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_15-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_24-4 {
        list-style-type: none;
      }
      ul.lst-kix_list_15-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_24-7 {
        list-style-type: none;
      }
      .lst-kix_list_15-0 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_15-1 {
        list-style-type: none;
      }
      .lst-kix_list_15-8 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_24-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_15-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_24-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-3.start {
        counter-reset: lst-ctn-kix_list_14-3 0;
      }
      ol.lst-kix_list_24-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_24-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_24-2 {
        list-style-type: none;
      }
      .lst-kix_list_4-4 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0;
      }
      ol.lst-kix_list_20-1.start {
        counter-reset: lst-ctn-kix_list_20-1 0;
      }
      .lst-kix_list_25-4 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_15-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_15-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_15-6 {
        list-style-type: none;
      }
      .lst-kix_list_9-3 > li:before {
        content: "" counter(lst-ctn-kix_list_9-3, lower-latin) ". ";
      }
      ul.lst-kix_list_15-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_15-4 {
        list-style-type: none;
      }
      .lst-kix_list_24-1 > li {
        counter-increment: lst-ctn-kix_list_24-1;
      }
      .lst-kix_list_25-8 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_25-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_25-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-4.start {
        counter-reset: lst-ctn-kix_list_14-4 0;
      }
      ol.lst-kix_list_14-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_25-2 {
        list-style-type: none;
      }
      .lst-kix_list_9-7 > li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
      }
      .lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4;
      }
      ol.lst-kix_list_26-6.start {
        counter-reset: lst-ctn-kix_list_26-6 0;
      }
      ul.lst-kix_list_25-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_25-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-6 {
        list-style-type: none;
      }
      .lst-kix_list_11-4 > li:before {
        content: "" counter(lst-ctn-kix_list_11-4, decimal) "\\003001  ";
      }
      ol.lst-kix_list_14-0 {
        list-style-type: none;
      }
      .lst-kix_list_22-5 > li {
        counter-increment: lst-ctn-kix_list_22-5;
      }
      .lst-kix_list_12-4 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_14-1 {
        list-style-type: none;
      }
      .lst-kix_list_27-5 > li {
        counter-increment: lst-ctn-kix_list_27-5;
      }
      ol.lst-kix_list_14-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_6-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_6-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_6-4 {
        list-style-type: none;
      }
      .lst-kix_list_20-5 > li:before {
        content: "" counter(lst-ctn-kix_list_20-5, decimal) ". ";
      }
      ul.lst-kix_list_6-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_6-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_25-5 {
        list-style-type: none;
      }
      .lst-kix_list_1-0 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_25-6 {
        list-style-type: none;
      }
      .lst-kix_list_20-1 > li:before {
        content: "" counter(lst-ctn-kix_list_20-1, decimal) ". ";
      }
      ul.lst-kix_list_25-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_25-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_6-2 {
        list-style-type: none;
      }
      .lst-kix_list_11-8 > li:before {
        content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
      }
      ul.lst-kix_list_6-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0;
      }
      .lst-kix_list_24-6 > li {
        counter-increment: lst-ctn-kix_list_24-6;
      }
      ul.lst-kix_list_6-0 {
        list-style-type: none;
      }
      .lst-kix_list_12-0 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_6-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0;
      }
      .lst-kix_list_1-4 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_13-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_14-4 > li {
        counter-increment: lst-ctn-kix_list_14-4;
      }
      .lst-kix_list_13-4 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_10-7 > li {
        counter-increment: lst-ctn-kix_list_10-7;
      }
      ol.lst-kix_list_26-5.start {
        counter-reset: lst-ctn-kix_list_26-5 0;
      }
      .lst-kix_list_2-0 > li:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
      }
      ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0;
      }
      .lst-kix_list_18-1 > li {
        counter-increment: lst-ctn-kix_list_18-1;
      }
      ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0;
      }
      .lst-kix_list_11-5 > li {
        counter-increment: lst-ctn-kix_list_11-5;
      }
      ol.lst-kix_list_14-5.start {
        counter-reset: lst-ctn-kix_list_14-5 0;
      }
      ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0;
      }
      .lst-kix_list_1-8 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_27-8 > li:before {
        content: "" counter(lst-ctn-kix_list_27-8, lower-roman) ". ";
      }
      .lst-kix_list_12-8 > li:before {
        content: "\\0025c6   ";
      }
      ol.lst-kix_list_20-3.start {
        counter-reset: lst-ctn-kix_list_20-3 0;
      }
      .lst-kix_list_8-2 > li {
        counter-increment: lst-ctn-kix_list_8-2;
      }
      .lst-kix_list_19-0 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_19-1 > li:before {
        content: "\\0025cf   ";
      }
      ul.lst-kix_list_1-0 {
        list-style-type: none;
      }
      .lst-kix_list_27-8 > li {
        counter-increment: lst-ctn-kix_list_27-8;
      }
      .lst-kix_list_19-4 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_8-1 > li {
        counter-increment: lst-ctn-kix_list_8-1;
      }
      ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0;
      }
      ol.lst-kix_list_26-1.start {
        counter-reset: lst-ctn-kix_list_26-1 0;
      }
      .lst-kix_list_19-2 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_19-3 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_24-5.start {
        counter-reset: lst-ctn-kix_list_24-5 0;
      }
      .lst-kix_list_11-0 > li {
        counter-increment: lst-ctn-kix_list_11-0;
      }
      ul.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-4 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-1 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-8 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_1-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0;
      }
      ol.lst-kix_list_16-3.start {
        counter-reset: lst-ctn-kix_list_16-3 0;
      }
      ol.lst-kix_list_27-5.start {
        counter-reset: lst-ctn-kix_list_27-5 0;
      }
      .lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3;
      }
      ol.lst-kix_list_22-4.start {
        counter-reset: lst-ctn-kix_list_22-4 0;
      }
      .lst-kix_list_22-7 > li {
        counter-increment: lst-ctn-kix_list_22-7;
      }
      .lst-kix_list_26-7 > li {
        counter-increment: lst-ctn-kix_list_26-7;
      }
      .lst-kix_list_19-8 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_14-7.start {
        counter-reset: lst-ctn-kix_list_14-7 0;
      }
      ol.lst-kix_list_20-8.start {
        counter-reset: lst-ctn-kix_list_20-8 0;
      }
      ol.lst-kix_list_11-2.start {
        counter-reset: lst-ctn-kix_list_11-2 0;
      }
      .lst-kix_list_19-5 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_19-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_19-7 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_9-2 > li {
        counter-increment: lst-ctn-kix_list_9-2;
      }
      ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0;
      }
      .lst-kix_list_24-5 > li {
        counter-increment: lst-ctn-kix_list_24-5;
      }
      .lst-kix_list_20-5 > li {
        counter-increment: lst-ctn-kix_list_20-5;
      }
      .lst-kix_list_14-3 > li {
        counter-increment: lst-ctn-kix_list_14-3;
      }
      .lst-kix_list_22-0 > li {
        counter-increment: lst-ctn-kix_list_22-0;
      }
      ol.lst-kix_list_24-0.start {
        counter-reset: lst-ctn-kix_list_24-0 5;
      }
      .lst-kix_list_18-3 > li {
        counter-increment: lst-ctn-kix_list_18-3;
      }
      .lst-kix_list_10-3 > li {
        counter-increment: lst-ctn-kix_list_10-3;
      }
      .lst-kix_list_18-0 > li:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) ". ";
      }
      .lst-kix_list_18-1 > li:before {
        content: "" counter(lst-ctn-kix_list_18-1, decimal) ". ";
      }
      .lst-kix_list_18-2 > li:before {
        content: "" counter(lst-ctn-kix_list_18-2, decimal) ". ";
      }
      .lst-kix_list_26-0 > li {
        counter-increment: lst-ctn-kix_list_26-0;
      }
      li.li-bullet-2:before {
        margin-left: -24pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 24pt;
      }
      ol.lst-kix_list_27-0.start {
        counter-reset: lst-ctn-kix_list_27-0 0;
      }
      .lst-kix_list_27-4 > li {
        counter-increment: lst-ctn-kix_list_27-4;
      }
      ol.lst-kix_list_11-7.start {
        counter-reset: lst-ctn-kix_list_11-7 0;
      }
      ol.lst-kix_list_14-2.start {
        counter-reset: lst-ctn-kix_list_14-2 0;
      }
      .lst-kix_list_16-1 > li {
        counter-increment: lst-ctn-kix_list_16-1;
      }
      ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0;
      }
      .lst-kix_list_27-1 > li {
        counter-increment: lst-ctn-kix_list_27-1;
      }
      .lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, decimal) "\\003001  ";
      }
      .lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7;
      }
      .lst-kix_list_27-1 > li:before {
        content: "" counter(lst-ctn-kix_list_27-1, decimal) "\\003001  ";
      }
      .lst-kix_list_24-2 > li {
        counter-increment: lst-ctn-kix_list_24-2;
      }
      .lst-kix_list_2-5 > li:before {
        content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
      }
      .lst-kix_list_27-3 > li:before {
        content: "" counter(lst-ctn-kix_list_27-3, decimal) ". ";
      }
      .lst-kix_list_27-5 > li:before {
        content: "" counter(lst-ctn-kix_list_27-5, lower-roman) ". ";
      }
      ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0;
      }
      .lst-kix_list_22-3 > li {
        counter-increment: lst-ctn-kix_list_22-3;
      }
      .lst-kix_list_18-6 > li:before {
        content: "" counter(lst-ctn-kix_list_18-6, decimal) ". ";
      }
      ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0;
      }
      ul.lst-kix_list_3-7 {
        list-style-type: none;
      }
      .lst-kix_list_14-6 > li {
        counter-increment: lst-ctn-kix_list_14-6;
      }
      ul.lst-kix_list_3-8 {
        list-style-type: none;
      }
      .lst-kix_list_10-1 > li:before {
        content: "" counter(lst-ctn-kix_list_10-1, decimal) "\\003001  ";
      }
      .lst-kix_list_18-4 > li:before {
        content: "" counter(lst-ctn-kix_list_18-4, decimal) ". ";
      }
      .lst-kix_list_18-8 > li:before {
        content: "" counter(lst-ctn-kix_list_18-8, decimal) ". ";
      }
      ol.lst-kix_list_27-3.start {
        counter-reset: lst-ctn-kix_list_27-3 0;
      }
      ul.lst-kix_list_3-1 {
        list-style-type: none;
      }
      ul.lst-kix_list_3-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_3-0 {
        list-style-type: none;
      }
      ul.lst-kix_list_3-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_3-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_3-3 {
        list-style-type: none;
      }
      ul.lst-kix_list_3-4 {
        list-style-type: none;
      }
      .lst-kix_list_10-7 > li:before {
        content: "" counter(lst-ctn-kix_list_10-7, decimal) "\\003001  ";
      }
      .lst-kix_list_20-1 > li {
        counter-increment: lst-ctn-kix_list_20-1;
      }
      .lst-kix_list_10-5 > li:before {
        content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
      }
      ol.lst-kix_list_18-6.start {
        counter-reset: lst-ctn-kix_list_18-6 0;
      }
      li.li-bullet-1:before {
        margin-left: -24pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 24pt;
      }
      .lst-kix_list_10-3 > li:before {
        content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
      }
      .lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6;
      }
      ol.lst-kix_list_20-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-7 {
        list-style-type: none;
      }
      .lst-kix_list_11-7 > li {
        counter-increment: lst-ctn-kix_list_11-7;
      }
      ol.lst-kix_list_20-6 {
        list-style-type: none;
      }
      .lst-kix_list_9-2 > li:before {
        content: "" counter(lst-ctn-kix_list_9-2, upper-latin) ". ";
      }
      ol.lst-kix_list_20-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-0 {
        list-style-type: none;
      }
      .lst-kix_list_26-4 > li {
        counter-increment: lst-ctn-kix_list_26-4;
      }
      ol.lst-kix_list_20-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_20-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-0.start {
        counter-reset: lst-ctn-kix_list_14-0 0;
      }
      .lst-kix_list_20-8 > li:before {
        content: "" counter(lst-ctn-kix_list_20-8, decimal) ". ";
      }
      .lst-kix_list_9-0 > li:before {
        content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
      }
      ul.lst-kix_list_21-1 {
        list-style-type: none;
      }
      ul.lst-kix_list_21-2 {
        list-style-type: none;
      }
      ul.lst-kix_list_21-3 {
        list-style-type: none;
      }
      .lst-kix_list_16-8 > li {
        counter-increment: lst-ctn-kix_list_16-8;
      }
      ul.lst-kix_list_21-4 {
        list-style-type: none;
      }
      .lst-kix_list_20-0 > li:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) ". ";
      }
      ul.lst-kix_list_21-5 {
        list-style-type: none;
      }
      ul.lst-kix_list_21-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_21-7 {
        list-style-type: none;
      }
      ul.lst-kix_list_21-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-7 {
        list-style-type: none;
      }
      .lst-kix_list_9-6 > li:before {
        content: "" counter(lst-ctn-kix_list_9-6, lower-roman) ". ";
      }
      ol.lst-kix_list_24-3.start {
        counter-reset: lst-ctn-kix_list_24-3 0;
      }
      ol.lst-kix_list_10-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-3 {
        list-style-type: none;
      }
      .lst-kix_list_9-4 > li:before {
        content: "" counter(lst-ctn-kix_list_9-4, decimal) ". ";
      }
      ol.lst-kix_list_10-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-5 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li:before {
        content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
      }
      ol.lst-kix_list_10-6 {
        list-style-type: none;
      }
      ul.lst-kix_list_21-0 {
        list-style-type: none;
      }
      .lst-kix_list_20-6 > li:before {
        content: "" counter(lst-ctn-kix_list_20-6, decimal) ". ";
      }
      ol.lst-kix_list_18-4.start {
        counter-reset: lst-ctn-kix_list_18-4 0;
      }
      ol.lst-kix_list_10-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-2 {
        list-style-type: none;
      }
      .lst-kix_list_20-4 > li:before {
        content: "" counter(lst-ctn-kix_list_20-4, decimal) ". ";
      }
      .lst-kix_list_11-5 > li:before {
        content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
      }
      ol.lst-kix_list_22-7.start {
        counter-reset: lst-ctn-kix_list_22-7 0;
      }
      .lst-kix_list_20-2 > li:before {
        content: "" counter(lst-ctn-kix_list_20-2, decimal) ". ";
      }
      .lst-kix_list_9-8 > li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
      }
      .lst-kix_list_20-6 > li {
        counter-increment: lst-ctn-kix_list_20-6;
      }
      .lst-kix_list_1-1 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_11-7 > li:before {
        content: "" counter(lst-ctn-kix_list_11-7, decimal) "\\003001  ";
      }
      .lst-kix_list_8-5 > li {
        counter-increment: lst-ctn-kix_list_8-5;
      }
      .lst-kix_list_1-3 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_16-8.start {
        counter-reset: lst-ctn-kix_list_16-8 0;
      }
      ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0;
      }
      .lst-kix_list_1-7 > li:before {
        content: "\\0025a0   ";
      }
      ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0;
      }
      .lst-kix_list_1-5 > li:before {
        content: "\\0025c6   ";
      }
      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0;
      }
      .lst-kix_list_14-7 > li {
        counter-increment: lst-ctn-kix_list_14-7;
      }
      ol.lst-kix_list_24-2.start {
        counter-reset: lst-ctn-kix_list_24-2 0;
      }
      ol.lst-kix_list_22-6.start {
        counter-reset: lst-ctn-kix_list_22-6 0;
      }
      .lst-kix_list_2-1 > li:before {
        content: "" counter(lst-ctn-kix_list_2-1, decimal) "\\003001  ";
      }
      .lst-kix_list_27-7 > li:before {
        content: "" counter(lst-ctn-kix_list_27-7, decimal) "\\003001  ";
      }
      .lst-kix_list_2-3 > li:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
      }
      .lst-kix_list_11-8 > li {
        counter-increment: lst-ctn-kix_list_11-8;
      }
      ol.lst-kix_list_24-8.start {
        counter-reset: lst-ctn-kix_list_24-8 0;
      }
      .lst-kix_list_26-8 > li {
        counter-increment: lst-ctn-kix_list_26-8;
      }
      .lst-kix_list_20-8 > li {
        counter-increment: lst-ctn-kix_list_20-8;
      }
      .lst-kix_list_9-1 > li {
        counter-increment: lst-ctn-kix_list_9-1;
      }
      .lst-kix_list_3-1 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_3-2 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_26-6 > li:before {
        content: "" counter(lst-ctn-kix_list_26-6, decimal) ". ";
      }
      .lst-kix_list_26-7 > li:before {
        content: "" counter(lst-ctn-kix_list_26-7, decimal) "\\003001  ";
      }
      .lst-kix_list_14-0 > li {
        counter-increment: lst-ctn-kix_list_14-0;
      }
      .lst-kix_list_8-1 > li:before {
        content: "" counter(lst-ctn-kix_list_8-1, upper-latin) ". ";
      }
      .lst-kix_list_8-2 > li:before {
        content: "" counter(lst-ctn-kix_list_8-2, decimal) ". ";
      }
      .lst-kix_list_3-5 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_18-0 > li {
        counter-increment: lst-ctn-kix_list_18-0;
      }
      ol.lst-kix_list_11-5.start {
        counter-reset: lst-ctn-kix_list_11-5 0;
      }
      .lst-kix_list_21-2 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_8-5 > li:before {
        content: "" counter(lst-ctn-kix_list_8-5, lower-latin) ". ";
      }
      .lst-kix_list_11-1 > li {
        counter-increment: lst-ctn-kix_list_11-1;
      }
      .lst-kix_list_8-6 > li:before {
        content: "" counter(lst-ctn-kix_list_8-6, lower-roman) ". ";
      }
      .lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0;
      }
      .lst-kix_list_26-2 > li:before {
        content: "" counter(lst-ctn-kix_list_26-2, lower-roman) ". ";
      }
      .lst-kix_list_26-3 > li:before {
        content: "" counter(lst-ctn-kix_list_26-3, decimal) ". ";
      }
      .lst-kix_list_3-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_21-6 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_16-6.start {
        counter-reset: lst-ctn-kix_list_16-6 0;
      }
      .lst-kix_list_21-7 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_11-2 > li:before {
        content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
      }
      ol.lst-kix_list_22-1.start {
        counter-reset: lst-ctn-kix_list_22-1 0;
      }
      ol.lst-kix_list_16-0.start {
        counter-reset: lst-ctn-kix_list_16-0 1;
      }
      ol.lst-kix_list_27-2.start {
        counter-reset: lst-ctn-kix_list_27-2 0;
      }
      .lst-kix_list_21-3 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_18-7.start {
        counter-reset: lst-ctn-kix_list_18-7 0;
      }
      ol.lst-kix_list_11-6.start {
        counter-reset: lst-ctn-kix_list_11-6 0;
      }
      .lst-kix_list_16-6 > li:before {
        content: "" counter(lst-ctn-kix_list_16-6, decimal) ". ";
      }
      ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0;
      }
      .lst-kix_list_25-2 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_17-1 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_18-2 > li {
        counter-increment: lst-ctn-kix_list_18-2;
      }
      .lst-kix_list_25-3 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_22-6 > li {
        counter-increment: lst-ctn-kix_list_22-6;
      }
      .lst-kix_list_16-1 > li:before {
        content: "" counter(lst-ctn-kix_list_16-1, decimal) ". ";
      }
      .lst-kix_list_16-2 > li:before {
        content: "" counter(lst-ctn-kix_list_16-2, decimal) ". ";
      }
      ol.lst-kix_list_27-1.start {
        counter-reset: lst-ctn-kix_list_27-1 0;
      }
      .lst-kix_list_26-6 > li {
        counter-increment: lst-ctn-kix_list_26-6;
      }
      .lst-kix_list_16-5 > li:before {
        content: "" counter(lst-ctn-kix_list_16-5, decimal) ". ";
      }
      ol.lst-kix_list_22-2.start {
        counter-reset: lst-ctn-kix_list_22-2 0;
      }
      ol.lst-kix_list_11-0.start {
        counter-reset: lst-ctn-kix_list_11-0 0;
      }
      ol.lst-kix_list_18-8.start {
        counter-reset: lst-ctn-kix_list_18-8 0;
      }
      .lst-kix_list_16-4 > li {
        counter-increment: lst-ctn-kix_list_16-4;
      }
      ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0;
      }
      .lst-kix_list_17-2 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_16-5.start {
        counter-reset: lst-ctn-kix_list_16-5 0;
      }
      .lst-kix_list_17-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_17-5 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
      }
      .lst-kix_list_27-2 > li:before {
        content: "" counter(lst-ctn-kix_list_27-2, lower-roman) ". ";
      }
      ol.lst-kix_list_16-2.start {
        counter-reset: lst-ctn-kix_list_16-2 0;
      }
      .lst-kix_list_22-3 > li:before {
        content: "" counter(lst-ctn-kix_list_22-3, decimal) ". ";
      }
      .lst-kix_list_14-5 > li {
        counter-increment: lst-ctn-kix_list_14-5;
      }
      .lst-kix_list_7-1 > li:before {
        content: "\\0025a0   ";
      }
      .lst-kix_list_7-5 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_9-6 > li {
        counter-increment: lst-ctn-kix_list_9-6;
      }
      .lst-kix_list_27-6 > li:before {
        content: "" counter(lst-ctn-kix_list_27-6, decimal) ". ";
      }
      .lst-kix_list_22-7 > li:before {
        content: "" counter(lst-ctn-kix_list_22-7, decimal) ". ";
      }
      ol.lst-kix_list_11-1.start {
        counter-reset: lst-ctn-kix_list_11-1 0;
      }
      .lst-kix_list_18-5 > li:before {
        content: "" counter(lst-ctn-kix_list_18-5, decimal) ". ";
      }
      .lst-kix_list_13-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_20-3 > li {
        counter-increment: lst-ctn-kix_list_20-3;
      }
      .lst-kix_list_26-3 > li {
        counter-increment: lst-ctn-kix_list_26-3;
      }
      .lst-kix_list_16-6 > li {
        counter-increment: lst-ctn-kix_list_16-6;
      }
      .lst-kix_list_10-6 > li {
        counter-increment: lst-ctn-kix_list_10-6;
      }
      .lst-kix_list_11-6 > li {
        counter-increment: lst-ctn-kix_list_11-6;
      }
      ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0;
      }
      .lst-kix_list_15-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_11-4 > li {
        counter-increment: lst-ctn-kix_list_11-4;
      }
      ol.lst-kix_list_24-7.start {
        counter-reset: lst-ctn-kix_list_24-7 0;
      }
      .lst-kix_list_22-4 > li {
        counter-increment: lst-ctn-kix_list_22-4;
      }
      .lst-kix_list_10-2 > li:before {
        content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
      }
      .lst-kix_list_20-7 > li:before {
        content: "" counter(lst-ctn-kix_list_20-7, decimal) ". ";
      }
      .lst-kix_list_4-2 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_4-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_15-2 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_10-8 > li {
        counter-increment: lst-ctn-kix_list_10-8;
      }
      .lst-kix_list_26-1 > li {
        counter-increment: lst-ctn-kix_list_26-1;
      }
      .lst-kix_list_25-6 > li:before {
        content: "\\0025cf   ";
      }
      .lst-kix_list_10-6 > li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
      }
      .lst-kix_list_9-1 > li:before {
        content: "" counter(lst-ctn-kix_list_9-1, upper-latin) ". ";
      }
      .lst-kix_list_9-5 > li:before {
        content: "" counter(lst-ctn-kix_list_9-5, lower-latin) ". ";
      }
      ol.lst-kix_list_22-0.start {
        counter-reset: lst-ctn-kix_list_22-0 4;
      }
      .lst-kix_list_24-0 > li {
        counter-increment: lst-ctn-kix_list_24-0;
      }
      .lst-kix_list_12-2 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_11-6 > li:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
      }
      .lst-kix_list_20-3 > li:before {
        content: "" counter(lst-ctn-kix_list_20-3, decimal) ". ";
      }
      ol.lst-kix_list_11-3.start {
        counter-reset: lst-ctn-kix_list_11-3 0;
      }
      .lst-kix_list_1-2 > li:before {
        content: "\\0025c6   ";
      }
      .lst-kix_list_27-6 > li {
        counter-increment: lst-ctn-kix_list_27-6;
      }
      .lst-kix_list_10-1 > li {
        counter-increment: lst-ctn-kix_list_10-1;
      }
      .lst-kix_list_8-8 > li {
        counter-increment: lst-ctn-kix_list_8-8;
      }
      ol.lst-kix_list_16-1.start {
        counter-reset: lst-ctn-kix_list_16-1 0;
      }
      .lst-kix_list_1-6 > li:before {
        content: "\\0025cf   ";
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      .lst-kix_list_18-7 > li {
        counter-increment: lst-ctn-kix_list_18-7;
      }
      .lst-kix_list_12-6 > li:before {
        content: "\\0025cf   ";
      }
      ol.lst-kix_list_11-4.start {
        counter-reset: lst-ctn-kix_list_11-4 0;
      }
      .lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
      }
      .lst-kix_list_13-2 > li:before {
        content: "\\0025c6   ";
      }
      ol {
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c23 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 20pt;
        font-family: "DFKai-SB";
        font-style: normal;
      }
      .c4 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 700;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 16pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c20 {
        color: #262626;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-family: "DFKai-SB";
        font-style: normal;
      }
      .c3 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c28 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-family: "DFKai-SB";
        font-style: normal;
      }
      .c16 {
        padding-top: 0pt;
        padding-bottom: 15pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c11 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 16pt;
        font-family: "DFKai-SB";
        font-style: normal;
      }
      .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-family: "DFKai-SB";
        font-style: normal;
      }
      .c5 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 14pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c0 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c13 {
        margin-left: 66pt;
        padding-top: 0pt;
        padding-left: 6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c14 {
        margin-left: 48pt;
        padding-top: 0pt;
        padding-left: 6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c26 {
        -webkit-text-decoration-skip: none;
        font-weight: 400;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 14pt;
        font-family: "DFKai-SB";
      }
      .c1 {
        margin-left: 24pt;
        padding-top: 0pt;
        text-indent: -24pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c12 {
        margin-left: 90pt;
        padding-top: 0pt;
        padding-left: 6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c8 {
        margin-left: 24pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c18 {
        margin-left: 60pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c10 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c29 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        color: #0563c1;
        text-decoration: underline;
      }
      .c24 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: justify;
      }
      .c15 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: center;
      }
      .c27 {
        max-width: 538.6pt;
        padding: 28.4pt 28.4pt 28.4pt 28.4pt;
      }
      .c9 {
        margin-left: 18pt;
        padding-left: 0pt;
      }
      .c19 {
        color: inherit;
        text-decoration: inherit;
      }
      .c7 {
        padding: 0;
      }
      .c22 {
        margin-left: 66pt;
      }
      .c6 {
        height: 12pt;
      }
      .c25 {
        margin-left: 96pt;
      }
      .c17 {
        padding-left: 6pt;
      }
      .c21 {
        padding-left: 0pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 12pt;
        font-family: "Calibri";
      }
      p {
        color: #000000;
        font-size: 12pt;
        font-family: "Calibri";
      }
      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
      }
      h4 {
        padding-top: 0pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 0pt;
        font-family: "PMingLiu";
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
      }
    </style>
  </head>
  <body class="c27 doc-content">
    <p class="c15">
      <span class="c23"
        >收集個人資料聲明</span
      >
    </p>
    <ol class="c7 lst-kix_list_2-0 start" start="1">
      <li class="c10 c21 li-bullet-0">
        <span class="c11"
          >你的私隱</span
        >
      </li>
    </ol>
    <ul class="c7 lst-kix_list_12-0 start">
      <li class="c18 c17 li-bullet-1">
        <span class="c2"
          >香港鐵路有限公司(並下稱「港鐵」、「我們」或「我們的」) 以及我們的子公司和附屬公司在
          收集、保存、使用及傳送個人資料時,尊重法例賦予你的私隱權利;而本「收集個人資料聲
          明」旨在說明我們處理個人私隱的方法。我們的政策是要遵守香港特別行政區法例第 486
          章《個人資料(私隱)條例》以及由私隱專員發出的實務守則以及指引資料,以及任何其他
          適用的私隱法,及相關的法規、實務守則以及指引。</span
        >
      </li>
      <li class="c17 c18 li-bullet-1">
        <span class="c2"
          >請仔細閲讀下方資訊,以了解我們有關如何處理你個人資料的政策和做法。本收集個人資料
          聲明可能會不時進行修訂,或在我們認為必要的情況下進行其他更改,但我們會在任何此類
          修訂或更改的情況下提前通知你。</span
        >
      </li>
      <li class="c18 c17 li-bullet-2">
        <span class="c2"
          >倘本收集個人資料聲明的英文本與中文本有任何差異的地方,則以英文本為準。</span
        >
      </li>
      <li class="c18 c17 li-bullet-2">
        <span class="c2"
          >「個人資料」是指可以從中直接或間接地確定個人身份的 任何個人身份信息或數據(例如
            姓名、職銜、電郵地址、部門、辦公地點、照片、影片及 裝置名稱等)。</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_2-0" start="3">
      <li class="c10 c21 li-bullet-0">
        <span class="c11"
          >使用個人資料的用途</span
        >
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c13 li-bullet-2">
        <span class="c2"
          >如閣下同意透過你的手機接收額外與工作相關的訊息或資料,並同意公司可以要求閣下在
          原車站或原車綫的其他車站以外的車站或工作地點工作,我們將收集以及你必須提供閣下
          的個人資料。我們可能就下列任何或所有用途使用個人資料:</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_11-0 start" start="1">
      <li class="c12 li-bullet-1">
        <span class="c2"
          >提供、操作、管理及優化本招聘及人手調配的活動(包括但不限於透過客戶研究、分
            析、數據分析或其他統計方式);</span
        >
      </li>
      <li class="c12 li-bullet-1">
        <span class="c2"
          >其他與上述用途有直接聯繫或附帶用途的目的。</span
        >
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c13 li-bullet-2">
        <span class="c20"
          >我們僅會在收集個人資料的目的範圍內使用閣下的個人資料,除非我們合理認為需要出於
          其他原因使用該資料,且該原因合符最初的目的。</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_2-0" start="4">
      <li class="c10 c21 li-bullet-0">
        <span class="c11"
          >個人資料的轉移及披露</span
        >
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c13 li-bullet-2">
        <span class="c2"
          >我們將採取一切合理步驟以確保閣下的個人資料保密。在下列的情況下,我們可能需要向
          以下的任何一方披露、轉移或轉讓閣下的個人資料:</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_10-0 start" start="1">
      <li class="c12 li-bullet-1">
        <span class="c2"
          >按任何適用的法律及法規對港鐵公司作出披露要求的任何人士(包括但不限於政府機
            關、監管或行政機構、或執法機關);</span
        >
      </li>
      <li class="c12 li-bullet-1">
        <span class="c2"
          >我們的附屬公司及/或合作伙伴就任何管理、營運及/或一切與試用版有關的目的;</span
        >
      </li>
      <li class="c12 li-bullet-2">
        <span class="c2"
          >任何就我們的相關商業運作,向港鐵公司提供諮詢、顧問、行政、電訊、客戶服務支
          援、科技、付款、預防欺詐、保險、數據資料處理及/或其他服務的代理人、參事、核
          數師、顧問、承包商及/或第三方的服務供應商,及/或以其他方式為或代替我們處理
          個人資料。為避免疑義,我們不會就市場調查或市場分析之用途而披露或轉讓閣下的
          個人資料予任何數據資料仲介;</span
        >
      </li>
      <li class="c12 li-bullet-2">
        <span class="c2"
          >凡涉及港鐵公司業務的合併、買賣或重組,或其他公司控制權的更替時,任何屬港鐵
          公司就閣下的個人資料的權利的任何實際或擬定的承讓人及繼承人;</span
        >
      </li>
    </ol>
    <p class="c24 c6 c25"><span class="c2"></span></p>
    <ol class="c7 lst-kix_list_2-0" start="5">
      <li class="c9 c16 li-bullet-0">
        <span class="c11">保安</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c16 c22 c17 li-bullet-2">
        <span class="c2"
          >除上文第 3 節所提及有關資料披露及轉移的情況外,只有我們已獲授權的人員或承辦商方
          可查閱閣下的個人資料。凡以電子方式儲存的個人資料,將以被保護的伺服器保存,並將
          加有密碼保障(或以同等形式的保障措施),及僅限上述授權人員查閱。在網絡傳輸個人資
          料將受相關安全設定保護。被指示處理個人資料的上述授權人員需遵守本聲明的規定。</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_2-0" start="6">
      <li class="c0 c9 li-bullet-0">
        <span class="c11"
          >閣下查閱及改正資料的權利</span
        >
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c0 c22 c17 li-bullet-1">
        <span class="c2"
          >閣下可隨時要求查閱及改正在我們的任何紀錄中與閣下有關的個人資料。閣下亦可要求我
          們從任何運作中的郵遞或分發名單中刪除閣下或閣下的個人資料。若閣下需行使任何上述
          權利,可按以下地址、傳真號碼或電郵與我們聯絡,並在閣下的通訊上註明「保密」字樣。
          在回應閣下的要求時,我們可能要求閣下提供某些關於閣下的資料,以確定閣下乃該等資
          料所指的人士。</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_2-0" start="7">
      <li class="c0 c9 li-bullet-0">
        <span class="c28">資料的保留</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c0 c17 c22 li-bullet-2">
        <span class="c2"
          >我們將按收集資料目的或其直接有關目的所需的時間保留你的個人資料。除外為了遵從任
          何法律、監管或會計要求,或為了保障港鐵的權益而保留相關資料,否則無需保留的個人
          資料將會在合理的時間內銷毀或匿名化。</span
        >
      </li>
    </ul>
    <ol class="c7 lst-kix_list_2-0" start="8">
      <li class="c0 c9 li-bullet-0">
        <span class="c28">查詢及投訴</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_13-0">
      <li class="c0 c22 c17 li-bullet-1">
        <span class="c2"
          >閣下有權隨時要求查閱及/或更正我們就閣下所持有的個人資料。閣下亦可要求我們將閣
          下從我們任何現行的郵寄或分發名單中取消訂閱。如果閣下需提出該等要求,或就我們的
          私隱政策有任何疑問或投訴,請透過以下方式聯絡我們:</span
        >
      </li>
    </ul>
    <p class="c0">
      <span class="c26"
        >香港鐵路有限公司</span
      >
    </p>
    <p class="c0">
      <span class="c2"
        >保障個人資料(私隱)主任</span
      >
    </p>
    <p class="c0">
      <span class="c2">法律常務部</span>
    </p>
    <p class="c0"><span class="c2">(標上保密)</span></p>
    <p class="c0"><span class="c2">&nbsp;</span></p>
    <p class="c0">
      <span class="c2"
        >地址：香港九龍九龍灣德福廣場港鐵總部大樓</span
      >
    </p>
    <p class="c0">
      <span class="c2">電郵：<span class="c29"
        ><a class="c19" href="mailto:PDPO@mtr.com.hk">PDPO@mtr.com.hk</a></span></span>
    </p>
    <p class="c0"><span class="c2">&nbsp;</span></p>
    <p class="c0">
      <span class="c2"
        >版本：二零二三年九月廿一日</span
      >
    </p>
    <p class="c6 c10"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c15">
      <span class="c4">Personal Data Collection Statement</span>
    </p>
    <ol class="c7 lst-kix_list_26-0 start" start="1">
      <li class="c10 c17 li-bullet-2">
        <span class="c5">Your Privacy</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_12-0 start">
      <li class="c18 c17 li-bullet-1">
        <span class="c3">The MTR Corporation Limited (referred to as "MTR," "we," or "our") and our subsidiaries and affiliates  respect your privacy rights when collecting, storing, using, and transmitting personal data. This "Personal  Data Collection Statement" aims to explain how we handle personal privacy. Our policy is to comply with  the Personal Data (Privacy) Ordinance (Chapter 486 of the Laws of Hong Kong Special Administrative  Region), as well as the practical guidance and guidelines issued by the Privacy Commissioner, any other  applicable privacy laws, regulations, practical guidance, and guidelines.
        </span>
      </li>
      <li class="c17 c18 li-bullet-1">
        <span class="c3"
          >Please read the following information carefully to understand our policies and practices regarding the  handling of your personal data. This Personal Data Collection Statement may be revised from time to time  or subject to other changes deemed necessary by us, but we will provide advance notice of any such  revisions or changes.</span
        >
      </li>
      <li class="c18 c17 li-bullet-2">
        <span class="c3"
          >In case of any discrepancies between the English and Chinese versions of this Personal Data Collection  Statement, the English version shall prevail.</span
        >
      </li>
      <li class="c18 c17 li-bullet-2">
        <span class="c3"
          >"Personal data" refers to any personally identifiable information or data (e.g., name, job title, email address,  department, office location, photos, videos, device names) that can directly or indirectly identify an  individual.</span
        >
      </li>
    </ul>
    <p class="c1 c6"><span class="c3"></span></p>
    <ol class="c7 lst-kix_list_26-0" start="2">
      <li class="c10 c17 li-bullet-1">
        <span class="c5">Purposes for Using Personal Data</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_12-0 start">
      <li class="c18 c17 li-bullet-1">
        <span class="c3">We collect your personal data and you must provide your personal data if you agree to receive additional  work related messages or information to your mobile phone and allow us to request you to work at stations or workplaces other than your original station or other stations along the original Railway Line. We may  use your personal data for any or all of the following purposes:</span>
      </li>
      <ol class="c7 lst-kix_list_11-0 start" start="1">
        <li class="c12 li-bullet-1">
          <span class="c3"
            >Providing, operating, managing,  optimizing this activity of manpower sourcing and deployment (including but not limited to customer  research, analysis, data analysis, or other statistical methods);</span
          >
        </li>
        <li class="c12 li-bullet-1">
          <span class="c3"
            >Other purposes directly related or  ancillary to the above purposes.</span
          >
        </li>
      </ol>
      <li class="c17 c18 li-bullet-1">
        <span class="c3"
          >We will only use your personal data within the scope of the purposes for collecting personal data unless  we reasonably believe that there is a need to use the data for other reasons that are compatible with the  original purpose.</span
        >
      </li>
    </ul>
    <p class="c1 c6"><span class="c3"></span></p>
    <ol class="c7 lst-kix_list_26-0" start="4">
      <li class="c10 c17 li-bullet-2">
        <span class="c5">Transfer and Disclosure of Personal Data</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_12-0 start">
      <li class="c18 c17 li-bullet-1">
        <span class="c3">We will take all reasonable steps to ensure the confidentiality of your personal data. However, we may need  to disclose, transfer, or assign your personal data to any of the following parties in the following cases:</span>
      </li>
      <ol class="c7 lst-kix_list_11-0 start" start="1">
        <li class="c12 li-bullet-1">
          <span class="c3"
            >Any person requiring disclosure of MTR Corporation under any applicable laws and regulations  (including but not limited to government agencies, regulatory or administrative bodies, or law  enforcement agencies);</span
          >
        </li>
        <li class="c12 li-bullet-1">
          <span class="c3"
            >Our subsidiaries and/or partners for any management, operational, and/or all purposes related to  the trial version;</span
          >
        </li>
        <li class="c12 li-bullet-1">
          <span class="c3"
            >Agents, advisors, auditors, consultants, contractors, and/or third-party service providers who provide  services to us for or on behalf of us in relation to our related business operations, including  consultation, administration, telecommunications, customer service support, technology, payment,  fraud prevention, insurance, data processing, and/or otherwise processing personal data. For the  avoidance of doubt, we will not disclose or transfer your personal data for the purpose of market  research or market analysis to any data intermediary;</span
          >
        </li>
        <li class="c12 li-bullet-1">
          <span class="c3"
            >Any actual or prospective assignee or successor of any rights of MTR Corporation in connection with  mergers, acquisitions, or reorganizations involving MTR Corporation’s business or change of control  of other companies.</span
          >
        </li>
      </ol>
    </ul>
    <p class="c1 c6"><span class="c3"></span></p>
    <ol class="c7 lst-kix_list_26-0" start="5">
      <li class="c10 c17 li-bullet-2"><span class="c5">Security</span></li>
    </ol>
    <ul class="c7 lst-kix_list_12-0 start">
      <li class="c18 c17 li-bullet-1">
        <span class="c3">Except as mentioned in section 3 above regarding data disclosure and transfer, only authorized personnel  or contractors can access your personal data. Personal data stored electronically will be protected by secure  servers and password protection (or equivalent security measures) and will only be accessible to authorized  personnel. Personal data transmitted over the network will be protected by relevant security settings. The  authorized personnel instructed to process personal data shall comply with the provisions of this Statement.
        </span>
      </li>
    </ul>
    <p class="c1 c6"><span class="c3"></span></p>
    <ol class="c7 lst-kix_list_26-0" start="6">
      <li class="c10 c17 li-bullet-2">
        <span class="c5">Your Rights to Access and Correct Data</span>
      </li>
    </ol>
    <ul class="c7 lst-kix_list_12-0 start">
      <li class="c18 c17 li-bullet-1">
        <span class="c3">You have the right to request access to and/or correction of your personal data that we hold. You may also  request us to unsubscribe you from any existing mailing or distribution list. Should you wish to exercise any  of the above rights, please contact us at the following address, fax number, or email, and mark your  communication as "Confidential." In responding to your request, we may require certain information about  you to verify your identity as the individual to whom the data relates.
        </span>
      </li>
    </ul>
    <p class="c1 c6"><span class="c3"></span></p>
    <p class="c24">
      <span class="c3"
        >MTR Corporation Limited Data Protection (Privacy) Officer Legal Department (Marked Confidential)</span
      >
    </p>
    <p class="c6 c24"><span class="c3"></span></p>
    <p class="c24"><span class="c3">Address:</span></p>
    <p class="c24">
      <span class="c3"
        >MTR Headquarters Building, Telford Plaza, Kowloon Bay, Hong Kong
      </span>
    </p>
    <p class="c24 c6"><span class="c3"></span></p>
    <p class="c24">
      <span>Email: </span
      ><span class="c29"
        ><a class="c19" href="mailto:PDPO@mtr.com.hk">PDPO@mtr.com.hk</a></span
      >
    </p>
    <p class="c24 c6"><span class="c3"></span></p>
    <p class="c24"><span class="c3">Version: September 21, 2023</span></p>
    <p class="c10 c6"><span class="c2"></span></p>
    <p class="c10 c6"><span class="c2"></span></p>
  </body>
</html>
`;
