import { PtUser } from "oneclick-component/src/store/apis/enhancedApi";
import { AuthState, createAuthSlice } from "oneclick-component/src/store/auth";
import AppRoutes from "../routes/AppRoutes";

const initialState: AuthState<PtUser> = {
  isInitialized: false,
  meUser: null,
  token: null,
  redirectTo: AppRoutes.HomeScreen.render(),
};

const authSlice = createAuthSlice(initialState);

export const {
  loginSucceed,
  loginRedirected,
  logoutSucceed,
  initialized,
  unauthenticated,
  refetchTokenSucceed,
} = authSlice.actions;
export default authSlice.reducer;
