import React, { useMemo } from "react";
import cn from "classnames";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { DateTime } from "luxon";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { Trans } from "react-i18next";

interface Props {
  calendarDisplayStatus: "normal" | "conflicted" | "unavailable";
  isHoliday: boolean;
  datetime: DateTime;
  station: WorkingStation;
  isLayeredCalendar: boolean;
  className?: string;
  isExpired: boolean;
  isIncident?: boolean;
}
const CalendarStationView = (props: Props): React.ReactElement => {
  const {
    calendarDisplayStatus,
    isHoliday,
    datetime,
    station,
    isLayeredCalendar,
    isExpired,
    isIncident,
    className,
  } = props;

  const { dayOfWeek, localizedMonth, localizedDayOfWeek } = useMemo(() => {
    const localizedDayOfWeek = datetime.setLocale("zh-Hant-HK").toLocaleString({
      weekday: "long",
    });
    const localizedMonth = datetime.setLocale("zh-Hant-HK").toLocaleString({
      month: "long",
    });
    return {
      dayOfWeek: datetime.day,
      localizedMonth,
      localizedDayOfWeek,
    };
  }, [datetime]);

  return (
    <div
      className={cn(
        "flex",
        "flex-col",
        "gap-y-3",
        "w-19",
        "items-center",
        className
      )}
    >
      <div
        className={cn("flex", "items-center", "justify-center", {
          "opacity-30": calendarDisplayStatus === "unavailable",
        })}
      >
        {isLayeredCalendar ? (
          <div className={cn("relative", "w-16", "h-18")}>
            <div
              className={cn(
                "border",
                "border-black/20",
                "rounded-e",
                "rounded-s",
                "rounded-es",
                "rounded-ee-lg",
                "w-14.5",
                "h-[4.3595rem]", // 69.75px
                "absolute",
                "left-[6.75px]",
                "top-1.5",
                {
                  "bg-white": !isIncident,
                  "bg-red-50": isIncident,
                }
              )}
            />
            <div
              className={cn(
                "border",
                "border-black/40",
                "rounded-e",
                "rounded-s",
                "rounded-es",
                "rounded-ee-md",
                "w-14.5",
                "h-[4.3595rem]", // 69.75px
                "absolute",
                "left-[3.37px]",
                "top-[3px]",
                {
                  "bg-white": !isIncident,
                  "bg-red-50": isIncident,
                }
              )}
            />
            <div
              className={cn(
                "border",
                "rounded",
                "text-center",
                "w-14.5",
                "h-[4.3595rem]", // 69.75px
                "pt-1.5",
                "absolute",
                {
                  // conflicted specific styles below
                  "text-black/24 bg-[#E0E0E0] border-black/24":
                    calendarDisplayStatus === "conflicted",
                  "text-black bg-white border-black":
                    calendarDisplayStatus !== "conflicted",
                  "bg-red-50": isIncident,
                }
              )}
            >
              <p className={cn("text-xs", "font-medium")}>{localizedMonth}</p>
              <p
                className={cn("text-lg", "leading-6", "font-medium", {
                  "text-red-500":
                    isHoliday && calendarDisplayStatus !== "conflicted",
                  "text-red-500/24":
                    isHoliday && calendarDisplayStatus === "conflicted",
                })}
              >
                {dayOfWeek}
              </p>
              <p className={cn("text-xs", "font-medium")}>
                {localizedDayOfWeek}
              </p>
            </div>
          </div>
        ) : (
          <div
            className={cn(
              "border",
              "rounded",
              "text-center",
              "w-14.5",
              "h-18",
              "pt-1.5",
              {
                // conflicted specific styles below
                "text-black/24 bg-[#E0E0E0] border-black/24":
                  calendarDisplayStatus === "conflicted",
                "text-black bg-white border-black":
                  calendarDisplayStatus !== "conflicted",
                "bg-red-50": isIncident,
              }
            )}
          >
            <p className={cn("text-xs", "font-medium")}>{localizedMonth}</p>
            <p
              className={cn("text-lg", "leading-6", "font-medium", {
                "text-red-500":
                  isHoliday && calendarDisplayStatus !== "conflicted",
                "text-red-500/24":
                  isHoliday && calendarDisplayStatus === "conflicted",
              })}
            >
              {dayOfWeek}
            </p>
            <p className={cn("text-xs", "font-medium")}>{localizedDayOfWeek}</p>
          </div>
        )}
      </div>
      <div
        className={cn({
          "opacity-30": calendarDisplayStatus === "unavailable",
        })}
      >
        <StationBadge
          station={station}
          stationTeam={null}
          showStationName={true}
          className={cn(
            "!text-sm",
            "!leading-[0.875rem]",
            "font-normal",
            "!py-[3px]",
            "!px-[9px]",
            "!bg-white"
          )}
        />
      </div>
      {isExpired ? (
        <div
          className={cn(
            "absolute",
            "text-red-500",
            "text-xs",
            "border-y",
            "border-red-500",
            "rotate-[-30deg]",
            "bottom-10"
          )}
        >
          <Trans i18nKey="shiftList.status.expiredBadge" />
        </div>
      ) : null}
    </div>
  );
};

export default CalendarStationView;
