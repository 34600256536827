import React, { useContext, useEffect } from "react";
import cn from "classnames";
import AppRoutes from "../routes/AppRoutes";
import { Navigate } from "react-router-dom";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { AuthContext } from "../providers/AuthProvider";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export const LogInScreen = (): React.ReactElement => {
  const { login } = useContext(AuthContext);
  const isLoggedIn = useSelector((state: RootState) => {
    return state.auth.meUser != null;
  });

  useEffect(() => {
    if (!isLoggedIn) {
      login();
    }
  });
  if (isLoggedIn) {
    return <Navigate replace={true} to={AppRoutes.HomeScreen.render()} />;
  }
  return (
    <div
      className={cn(
        "fixed",
        "top-1/2",
        "left-1/2",
        "-translate-x-1/2",
        "-translate-y-1/2"
      )}
    >
      <LoadingSpinner size="l" />
    </div>
  );
};
