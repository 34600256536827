import { PropsWithChildren, ReactElement, useEffect } from "react";

import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import useMeUser from "../../hooks/useMeUser";
import AppRoutes from "../../routes/AppRoutes";
import { loginRedirected } from "../../store/auth";
import MainLayout from "../MainLayout";

export interface LayoutSettingProps extends PropsWithChildren {
  shouldShowTabMenu?: boolean;
  shouldShowHeaderFilter?: boolean;
  shouldShowBackNavigate?: boolean;
  shouldShowProfile?: boolean;
  shouldShowBookmarkFilter?: boolean;
}

const ProtectedRoute = (props: LayoutSettingProps): ReactElement | null => {
  const { children, ...rest } = props;
  const user = useMeUser();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (user == null) {
      dispatch(
        loginRedirected({
          redirectTo: `${location.pathname}${location.search}`,
        })
      );
    }
  }, [dispatch, user, location]);

  if (user == null) {
    return <Navigate replace={true} to={AppRoutes.LogInScreen.render()} />;
  }

  return <MainLayout {...rest}>{children}</MainLayout>;
};

export default ProtectedRoute;
