import { Button } from "oneclick-component/src/components/Button";
import React from "react";
import { Trans } from "react-i18next";
import cn from "classnames";

interface Props {
  onSubmit: () => void;
  disableSubmit: boolean;
  count?: number;
  className?: string;
}

const ShiftStationFilterViewFooter = (props: Props): React.ReactElement => {
  const { onSubmit, disableSubmit, className } = props;

  return (
    <footer
      className={cn(
        "bg-white",
        "flex",
        "px-5",
        "py-2",
        "items-center",
        "justify-between",
        "border",
        "border-black/12",
        "z-20",
        className
      )}
    >
      <Button onClick={onSubmit} disabled={disableSubmit} className="flex-1">
        <Trans i18nKey="shiftList.filter.checkResult" />
      </Button>
    </footer>
  );
};

export default ShiftStationFilterViewFooter;
