import { AuthService } from "oneclick-component/src/services/auth";
import authgear from "@authgear/web";

export class AuthgearService implements AuthService {
  private static _instance: AuthgearService | null = null;
  static getInstance(): AuthgearService {
    if (this._instance == null) {
      this._instance = new AuthgearService();
    }
    return this._instance;
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshToken(): Promise<string> {
    try {
      await authgear.refreshAccessTokenIfNeeded();
      if (authgear.accessToken == null) {
        throw new Error();
      }
      return authgear.accessToken;
    } catch (err: unknown) {
      // Expected to fail if refresh token expired
      console.log("Failed to obtain access token", err);
      throw new Error();
    }
  }
}
