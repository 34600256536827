import { Dialog } from "@headlessui/react";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import { Modal } from "oneclick-component/src/components/Modal";
import { ReactElement } from "react";
import { Trans } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CcoOverWorkHourDialog = (props: Props): ReactElement => {
  const { isOpen, onClose } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasXMarkButton={false}
      className={cn("flex", "flex-col", "items-center", "gap-y-5")}
    >
      <Dialog.Title
        className={cn(
          "text-lg",
          "text-center",
          "leading-6",
          "font-medium",
          "text-gray-900",
          "whitespace-pre-wrap"
        )}
      >
        <Trans i18nKey="shift.application.ccoOverWork.dialog.title" />
      </Dialog.Title>
      <Dialog.Description
        className={cn("text-sm", "leading-5", "font-normal", "text-gray-500")}
      >
        <Trans i18nKey="shift.application.ccoOverWork.dialog.description" />
      </Dialog.Description>
      <div className={cn("flex", "flex-col", "gap-y-3", "w-full")}>
        <Button theme="primary" onClick={onClose} className="w-full">
          <Trans i18nKey="shift.application.ccoOverWork.dialog.confirm" />
        </Button>
      </div>
    </Modal>
  );
};

export default CcoOverWorkHourDialog;
