import React, { useCallback, useMemo } from "react";
import { CustomerRailwayLineWithWorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { useTranslation } from "react-i18next";
import { Disclosure, Transition } from "@headlessui/react";
import cn from "classnames";
import { ChevronDownIcon } from "oneclick-component/src/icon";
import { localizeString } from "oneclick-component/src/utils/localize";
import BadgeToggle from "oneclick-component/src/components/BadgeToggle";

export const DummyLineGroup = (): React.ReactElement => {
  return (
    <Disclosure as="section" className="pointer-events-none">
      {() => (
        <div className="">
          <Disclosure.Button
            className={cn(
              "my-2",
              "pl-3",
              "h-5",
              "w-full",
              "text-left",
              "flex",
              "items-center",
              "gap-x-3"
            )}
          >
            <div
              className={cn(
                "rounded-full",
                "w-3",
                "h-3",
                "border",
                "border-black",
                "bg-white",
                "z-10"
              )}
            />
          </Disclosure.Button>
        </div>
      )}
    </Disclosure>
  );
};

interface Props {
  line: CustomerRailwayLineWithWorkingStation;
  selectedStationIds: number[];
  defaultOpen: boolean;
  onChangeLineStationIds: (
    line: CustomerRailwayLineWithWorkingStation,
    newLineStationIds: number[]
  ) => void;
}
const LineGroup = (props: Props): React.ReactElement => {
  const { line, onChangeLineStationIds, selectedStationIds, defaultOpen } =
    props;
  const { t } = useTranslation();

  const lineSelectedStations = useMemo(() => {
    const allStationIds = line.workingStations.map((st) => st.id);
    return selectedStationIds.filter((id) => allStationIds.includes(id));
  }, [selectedStationIds, line]);

  const lineStationSelectionStatus: "all" | "some" | "none" = useMemo(() => {
    if (lineSelectedStations.length === 0) {
      return "none";
    }
    if (lineSelectedStations.length < line.workingStations.length) {
      return "some";
    }
    return "all";
  }, [line.workingStations.length, lineSelectedStations]);

  const onChangeStationCheckbox = useCallback(
    (newIsChecked: boolean, stationId: number) => {
      const newStationIdSet = new Set(lineSelectedStations);
      if (newIsChecked) {
        newStationIdSet.add(stationId);
      } else {
        newStationIdSet.delete(stationId);
      }
      onChangeLineStationIds(line, [...newStationIdSet]);
    },
    [line, onChangeLineStationIds, lineSelectedStations]
  );

  const onChangeSelectAll = useCallback(
    (newIsChecked: boolean, _: number) => {
      if (newIsChecked) {
        onChangeLineStationIds(
          line,
          line.workingStations.map((s) => s.id)
        );
      } else {
        onChangeLineStationIds(line, []);
      }
    },
    [line, onChangeLineStationIds]
  );
  return (
    // Copied from https://headlessui.com/react/disclosure#transitions
    <Disclosure
      as="section"
      className={cn("relative", "py-1")}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <div
            style={{
              backgroundColor: `#${line.colorHexcode}`,
            }}
            className={cn(
              "w-1.5",
              "top-0",
              "bottom-0",
              "ml-[15px]",
              "absolute",
              "translate-y-5.5"
            )}
          />
          <Disclosure.Button
            className={cn(
              "my-2",
              "pl-3",
              "w-full",
              "text-left",
              "flex",
              "items-center",
              "text-sm",
              "gap-x-3"
            )}
          >
            <div
              className={cn(
                "rounded-full",
                "w-3",
                "h-3",
                "border",
                "border-black",
                "bg-white",
                "z-10"
              )}
            />
            <span className="flex-1">
              {localizeString(line.name)} ({lineSelectedStations.length})
            </span>
            <ChevronDownIcon
              className={cn(
                {
                  "rotate-180": open,
                },
                "h-5",
                "w-5",
                "text-black/86"
              )}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel as="div" className={cn("mb-6", "pl-3")}>
              <div
                className={cn(
                  "grid",
                  "grid-cols-3",
                  "pt-1",
                  "pb-4",
                  "px-3",
                  ""
                )}
              >
                <BadgeToggle<number>
                  className={cn(
                    "text-sm",
                    "leading-5",
                    "font-normal",
                    "py-1.5",
                    "px-3",
                    "w-22"
                  )}
                  colorHexcode={line.colorHexcode}
                  isChecked={lineStationSelectionStatus === "all"}
                  text={t("common.selectAll")}
                  value={-1}
                  onChange={onChangeSelectAll}
                />
              </div>
              <div
                className={cn("grid", "grid-cols-3", "py-1", "px-3", "gap-y-4")}
              >
                {line.workingStations.map((station) => (
                  <BadgeToggle<number>
                    key={station.id}
                    className={cn(
                      "text-sm",
                      "leading-5",
                      "font-normal",
                      "py-1.5",
                      "w-22"
                    )}
                    colorHexcode={line.colorHexcode}
                    isChecked={selectedStationIds.includes(station.id)}
                    text={localizeString(station.name)}
                    value={station.id}
                    onChange={onChangeStationCheckbox}
                  />
                ))}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default LineGroup;
