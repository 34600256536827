import React, { useCallback, useMemo, useState } from "react";
import {
  CustomerRailwayLineWithWorkingStation,
  useListCustomerRailwayLinesHandlerPtCustomerRailwayLinesGetQuery as useListLineWorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import cn from "classnames";
import ShiftStationFilterViewHeader from "./Header";
import { checkArrayhaveSameContents } from "../../utils/array";
import useMeUser from "../../hooks/useMeUser";
import LineGroup, { DummyLineGroup } from "./LineGroup";
import ShiftStationFilterViewFooter from "./Footer";
import { Trans } from "react-i18next";

interface Props {
  filterStationIds: number[];
  onSubmit: (newFilterStationIds: number[]) => void;
  className?: string;
  onDismiss: () => void;
}

const ShiftStationFilterView = (props: Props): React.ReactElement => {
  const { filterStationIds, onSubmit, className, onDismiss } = props;

  const meUser = useMeUser();

  const { data: lineStationResponse, isLoading } = useListLineWorkingStation();

  const [stationIds, setStationIds] = useState<number[]>(filterStationIds);

  const isDirty = useMemo(
    () => !checkArrayhaveSameContents(filterStationIds, stationIds),
    [filterStationIds, stationIds]
  );

  const lines: CustomerRailwayLineWithWorkingStation[] = useMemo(
    () => lineStationResponse?.customerRailwayLines ?? [],
    [lineStationResponse]
  );
  const homeLines = useMemo(() => {
    const homeStationCode = meUser?.station?.shortCode;
    if (homeStationCode == null) {
      return [];
    }
    return lines
      .filter(
        (l) =>
          l.workingStations.filter((st) => st.shortCode === homeStationCode)
            .length > 0
      )
      .map((l) => l.id);
  }, [meUser, lines]);

  const onChangeLineStationIds = useCallback(
    (
      line: CustomerRailwayLineWithWorkingStation,
      newLineStationIds: number[]
    ) => {
      const lineStationIds = line.workingStations.map((st) => st.id);
      const removeIds = lineStationIds.filter(
        (id) => !newLineStationIds.includes(id)
      );
      const addIds = lineStationIds.filter((id) =>
        newLineStationIds.includes(id)
      );
      const updatedStationIds = [
        ...stationIds.filter((id) => !removeIds.includes(id)),
        ...addIds,
      ];
      const uniqueStationIds = [...new Set(updatedStationIds)];
      setStationIds(uniqueStationIds);
    },
    [stationIds]
  );

  const handleOnClickSubmit = useCallback(() => {
    onSubmit(stationIds);
  }, [onSubmit, stationIds]);

  const handleReset = useCallback(() => setStationIds([]), []);

  if (isLoading || lineStationResponse == null) {
    return (
      <div className={cn("flex", "items-center", "justify-center", className)}>
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      <ShiftStationFilterViewHeader
        onDismiss={onDismiss}
        onReset={handleReset}
        className={cn("sticky", "top-0", "w-screen", "z-30")}
      />
      <main className={cn("px-5", "pt-2.5", "pb-16")}>
        <p className={cn("text-sm", "font-medium", "text-black/60", "my-4")}>
          <Trans i18nKey="shiftList.filter.content.station" />
        </p>
        <div className={cn("flex", "flex-col")}>
          {lines.map((line) => (
            <LineGroup
              key={line.id}
              line={line}
              selectedStationIds={stationIds}
              defaultOpen={homeLines.includes(line.id)}
              onChangeLineStationIds={onChangeLineStationIds}
            />
          ))}
          <DummyLineGroup />
        </div>
      </main>
      <ShiftStationFilterViewFooter
        onSubmit={handleOnClickSubmit}
        disableSubmit={!isDirty}
        count={stationIds.length}
        className={cn("fixed", "bottom-0", "w-screen")}
      />
    </div>
  );
};

export default ShiftStationFilterView;
