import { Menu } from "@headlessui/react";
import cn from "classnames";
import React, { useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { PopMenuItem } from "oneclick-component/src/components/PopMenu";
import { maskString } from "oneclick-component/src/utils/maskString";
import useMeUser from "../../hooks/useMeUser";
import { AuthContext } from "../../providers/AuthProvider";

interface UserMenuProps {}

export default function UserMenu(props: UserMenuProps): React.ReactElement {
  const {} = props;
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const meUser = useMeUser();

  const maskedPhoneNumber = useMemo(() => {
    return meUser?.phoneNumber != null ? maskString(meUser.phoneNumber) : "";
  }, [meUser]);

  if (meUser == null) {
    return (
      <>
        <div className="py-1">
          <Menu.Item>
            <div className={cn("px-4", "py-2", "text-sm", "text-gray-900")}>
              <Trans i18nKey="auth.anonymous" />
            </div>
          </Menu.Item>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="py-1">
        <Menu.Item>
          <div className={cn("px-4", "py-2", "text-sm", "text-gray-900")}>
            <div className={cn("flex", "flex-row")}>
              <p className="grow">
                <Trans i18nKey="menu.loginAs" />
              </p>
              <p className={cn("text-xs", "text-black/24", "font-normal")}>
                V {window.appConfig.version ?? "--"}
              </p>
            </div>
            <div>
              {meUser.countryCode} {maskedPhoneNumber}
            </div>
          </div>
        </Menu.Item>
      </div>
      <div className="py-1">
        <PopMenuItem type="button" onClick={logout} text={t("menu.logout")} />
      </div>
    </>
  );
}
