interface AppRoute<Args extends unknown[] = any[]> {
  path: string;
  render?: (...args: Args) => string;
}
const asAppRouteTypes = <T extends { [K in keyof T]: AppRoute }>(et: T): T =>
  et;

const AppRoutes = asAppRouteTypes(
  Object.freeze({
    LogInScreen: {
      path: "/",
      render: () => "/",
    },
    HomeScreen: {
      path: "/home",
      render: () => "/home",
    },
    FindShiftScreen: {
      path: "/explore",
      render: () => "/explore",
    },
    PendingShiftScreen: {
      path: "/pending",
      render: () => "/pending",
    },
    ShiftHistoryScreen: {
      path: "/history",
      render: () => "/history",
    },
    ShiftDetailScreen: {
      path: "/shifts/:id",
      render: (shiftId: number) => `/shifts/${shiftId}`,
    },
    JobDetailScreen: {
      path: "/jobs/:id",
      render: (jobId: number) => `/jobs/${jobId}`,
    },
    AuthRedirect: {
      path: "/auth-redirect",
      render: () => "/auth-redirect",
    },
    PrivacyScreen: {
      path: "/privacy",
      render: () => "/privacy",
    },
    ShiftApplicationScreen: {
      path: "/shifts/application",
      render: () => "/shifts/application",
    },
    MasterShiftApplicationScreen: {
      path: "/master-shifts/application",
      render: () => "/master-shifts/application",
    },
    MasterShiftApplicationSuccessScreen: {
      path: "/master-shifts/application/succeed",
      render: () => "/master-shifts/application/succeed",
    },
    WelcomeScreen: {
      path: "/welcome",
      render: () => "/welcome",
    },
  })
);

export default AppRoutes;
