import { ReactElement, useCallback } from "react";
import cn from "classnames";
import { Badge } from "../Badge";

interface Props<T> {
  className?: string;
  text: string;
  value: T;
  colorHexcode: string;
  isChecked?: boolean;
  onChange?: (newIsChecked: boolean, stationId: T) => void;
}

export default function BadgeToggle<T>(props: Props<T>): ReactElement {
  const { className, isChecked, text, value, colorHexcode, onChange } = props;

  const handleClick = useCallback(() => {
    if (onChange != null) {
      onChange(!isChecked, value);
    }
  }, [isChecked, value, onChange]);

  return (
    <button type="button" onClick={handleClick}>
      <Badge
        style={{
          color: isChecked ? "#fff" : `#${colorHexcode}`,
          borderWidth: 1,
          borderColor: `#${colorHexcode}`,
          backgroundColor: isChecked ? `#${colorHexcode}` : "#fff",
        }}
        className={cn("justify-center", className)}
      >
        {text}
      </Badge>
    </button>
  );
}
