interface Config {
  sentry?: {
    dsn: string;
  };
  authgearEndpoint: string;
  authgearClientId: string;
  timezone: string;
  environment: string;
  version: string;
}

export const config: Config = {
  environment: "LOCAL",
  ...window.appConfig,
};
