import cn from "classnames";
import AvatarImage from "oneclick-component/src/components/AvatarImage";
import { getInitialsFromPtUserFirstLastName } from "oneclick-component/src/utils/ptUser";
import { ReactElement, useMemo } from "react";
import useMeUser from "../../hooks/useMeUser";

interface MeAvatarProps {
  size: string;
}

const MeAvatarImage = (props: MeAvatarProps): ReactElement => {
  const { size } = props;
  const meUser = useMeUser();

  const initials = useMemo<string>(
    () =>
      getInitialsFromPtUserFirstLastName(
        meUser?.firstNameEn ?? "",
        meUser?.lastNameEn ?? ""
      ),
    [meUser?.firstNameEn, meUser?.lastNameEn]
  );

  return (
    <span className={cn("relative", "inline-block")}>
      <AvatarImage
        profilePic={null}
        username={meUser != null ? meUser.fullNameZhHk : ""}
        initials={initials}
        maxInitials={2}
        size={size}
      />
    </span>
  );
};

export default MeAvatarImage;
