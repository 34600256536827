import React, { useMemo } from "react";
import { DateTime } from "luxon";
import cn from "classnames";
import {
  ShiftForPtUserView,
  PublicHoliday,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  localizeDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { sort } from "oneclick-component/src/utils/list";
import ShiftListItem from "../../components/ShiftListItem";

interface ShiftListItemGroupProps {
  isoMonth: string;
  shifts: ShiftForPtUserView[];
  selectedShiftIds: number[];
  onSelectShift: (shift: ShiftForPtUserView) => void;
  holidays: PublicHoliday[];
  appliedShiftIds: number[];
}

const ShiftListItemGroup = (
  props: ShiftListItemGroupProps
): React.ReactElement => {
  const {
    isoMonth,
    shifts,
    selectedShiftIds,
    onSelectShift,
    holidays,
    appliedShiftIds,
  } = props;
  const monthDisplayText = useMemo(() => {
    const monthDt = DateTime.fromFormat(isoMonth, "yyyy-MM");
    return localizeDateTime(monthDt, {
      month: "long",
    });
  }, [isoMonth]);

  return (
    <div className="relative">
      <div
        className={cn(
          "border-b",
          "border-b-black/[0.12]",
          "py-4",
          "px-5",
          "my-2",
          "bg-white",
          "sticky",
          "top-[4rem]", // hard-coded header height
          "z-20"
        )}
      >
        <p className={cn("text-black/86", "font-medium")}>{monthDisplayText}</p>
      </div>
      <div>
        <ul>
          {shifts.map((s) => {
            const isApplied =
              (s.shiftRequest != null &&
                s.shiftRequest.status !== "requested") ||
              appliedShiftIds.includes(s.id);
            return (
              <ShiftListItem
                key={s.id}
                className={cn("mb-2", "last:mb-0")}
                shiftForPt={s}
                holidays={holidays}
                selectState={{
                  isApplied: isApplied,
                  isSelected: selectedShiftIds.includes(s.id),
                  onSelect: onSelectShift,
                }}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

interface MasterShiftShiftListProps {
  shiftList: ShiftForPtUserView[];
  onSelectShift: (shift: ShiftForPtUserView) => void;
  selectedShiftIds: number[];
  holidays: PublicHoliday[];
  appliedShiftIds: number[];
}

export const MasterShiftShiftList = (
  props: MasterShiftShiftListProps
): React.ReactElement => {
  const {
    shiftList,
    onSelectShift,
    selectedShiftIds,
    holidays,
    appliedShiftIds,
  } = props;

  const { sortedIsoMonths, shiftsByMonth } = useMemo(() => {
    // map of shift by ISO month
    const shiftsByMonth = shiftList.reduce<
      Record<string, ShiftForPtUserView[]>
    >((map, s) => {
      const startDt = isoToDateTime(s.dutyStartTime);
      const isoMonth = startDt.toFormat("yyyy-MM");
      if (!(isoMonth in map)) {
        map[isoMonth] = [];
      }
      map[isoMonth].push(s);
      return map;
    }, {});

    for (const k of Object.keys(shiftsByMonth)) {
      shiftsByMonth[k] = sort(shiftsByMonth[k], [
        { key: "dutyStartTime", direction: "asc" },
        { key: "id", direction: "asc" },
      ]);
    }

    const sortedIsoMonths = Object.keys(shiftsByMonth).sort((m1, m2) => {
      // In format YYYY-MM, hence we can sort the string without parsing
      if (m1 < m2) {
        return -1;
      } else if (m2 < m1) {
        return 1;
      }
      // unexpected
      return 0;
    });

    return {
      sortedIsoMonths,
      shiftsByMonth,
    };
  }, [shiftList]);

  return (
    <div className={cn("mb-3", "w-full")}>
      {sortedIsoMonths.map((m) => (
        <ShiftListItemGroup
          key={m}
          isoMonth={m}
          shifts={shiftsByMonth[m]}
          onSelectShift={onSelectShift}
          selectedShiftIds={selectedShiftIds}
          holidays={holidays}
          appliedShiftIds={appliedShiftIds}
        />
      ))}
    </div>
  );
};
