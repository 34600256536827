import { ReactElement } from "react";
import AppRouter from "./routes/AppRouter";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { StoreProvider } from "./providers/StoreProvider";
import AuthProvider from "./providers/AuthProvider";
import { AuthgearService } from "./services/AuthgearService";
import FeatureConfigProvider from "./providers/FeatureConfigProvider";

function App(): ReactElement {
  return (
    <StoreProvider authService={AuthgearService.getInstance()}>
      <AuthProvider>
        <FeatureConfigProvider>
          <Toaster containerClassName="!top-16 !right-0" />
          <AppRouter />
        </FeatureConfigProvider>
      </AuthProvider>
    </StoreProvider>
  );
}

export default App;
