import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { privacy } from "./privacy";
import AppRoutes from "../../routes/AppRoutes";
import { LogoIcon } from "oneclick-component/src/icon";

export function PrivacyScreen(): React.ReactElement {
  return (
    <main>
      <header className={cn("bg-white", "border-b", "border-gray-300")}>
        <nav
          className={cn(
            "mx-auto",
            "flex",
            "items-center",
            "justify-between",
            "py-3",
            "px-8"
          )}
          aria-label="Global"
        >
          <Link to={AppRoutes.LogInScreen.render()}>
            <LogoIcon />
          </Link>
        </nav>
      </header>
      <div className={"p-10"} dangerouslySetInnerHTML={{ __html: privacy }} />
    </main>
  );
}
