import { ReactElement, useCallback, useState } from "react";
import {
  ShiftForPtUserView,
  PtUserShiftCancelCount,
  useCancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPostMutation as useCancelApply,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Trans, useTranslation } from "react-i18next";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import { Modal } from "oneclick-component/src/components/Modal";
import { Dialog } from "@headlessui/react";
import { CancelWithCircleOutlineIcon } from "../../icon";
import useCancelApplyExpiryCountdown from "./useCancelApplyExpiryCountdown";

interface CancelApplicationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  timeUntilExpireDisplayString: string;
  canCancelApply: boolean;
  shift: ShiftForPtUserView;
}

const CancelApplicationDialog = (
  props: CancelApplicationDialogProps
): ReactElement => {
  const {
    timeUntilExpireDisplayString,
    isOpen,
    onClose,
    canCancelApply,
    shift,
  } = props;

  const { t } = useTranslation();
  const showMessage = useShowMessage();

  const [cancelApplyShift, { isLoading: isCancellingApplyShift }] =
    useCancelApply();
  const onClickCancelApplyShift = useCallback(() => {
    cancelApplyShift({ shiftId: shift.id })
      .unwrap()
      .then(() => {
        showMessage({
          title: t("shift.cancelApplication.cancelApply.toast.success.title"),
          message: t(
            "shift.cancelApplication.cancelApply.toast.success.message"
          ),
          type: "success",
          showDismiss: true,
        });
      })
      .catch((e) => {
        console.error(e);
        showMessage({
          title: t("shift.cancelApplication.cancelApply.toast.fail.title"),
          message: t("shift.cancelApplication.cancelApply.toast.fail.message"),
          type: "fail",
          showDismiss: true,
        });
      });
  }, [cancelApplyShift, shift.id, showMessage, t]);

  const handleOnClose = useCallback(() => {
    if (isCancellingApplyShift) {
      return;
    }
    onClose();
  }, [isCancellingApplyShift, onClose]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      hasXMarkButton={false}
      className={cn("flex", "flex-col", "items-center", "gap-y-5")}
    >
      <Dialog.Title
        className={cn("text-lg", "leading-6", "font-medium", "text-gray-900")}
      >
        <Trans i18nKey="shift.cancelApplication.cancelApply.dialog.title" />
      </Dialog.Title>
      <Dialog.Description
        className={cn(
          "text-sm",
          "leading-5",
          "font-normal",
          "text-gray-500",
          "text-center"
        )}
      >
        <p>
          <Trans i18nKey="shift.cancelApplication.cancelApply.dialog.description" />
        </p>
        <p className="mb-6">
          <Trans i18nKey="shift.cancelApplication.cancelApply.dialog.description2" />
        </p>
        {canCancelApply ? (
          <Trans
            i18nKey="shift.cancelApplication.cancelApply.dialog.timeUntilExpire"
            values={{ timeString: timeUntilExpireDisplayString }}
          />
        ) : (
          <Trans i18nKey="shift.cancelApplication.cancelApply.dialog.expired" />
        )}
      </Dialog.Description>
      <div className={cn("flex", "flex-col", "gap-y-3", "w-full")}>
        <Button
          onClick={onClickCancelApplyShift}
          isLoading={isCancellingApplyShift}
          className="w-full"
          disabled={!canCancelApply}
        >
          <Trans i18nKey="shift.cancelApplication.cancelApply.dialog.confirm" />
        </Button>
        <Button
          theme="white"
          onClick={onClose}
          disabled={isCancellingApplyShift}
          className="w-full"
        >
          <Trans i18nKey="shift.cancelApplication.cancelApply.dialog.cancel" />
        </Button>
      </div>
    </Modal>
  );
};
interface CancelApplicationSectionProps {
  shift: ShiftForPtUserView;
  ptCancelRecord: PtUserShiftCancelCount | null;
}
const ShiftCancelApplicationSection = (
  props: CancelApplicationSectionProps
): ReactElement => {
  const { shift, ptCancelRecord } = props;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onOpenDialog = useCallback(() => setIsDialogOpen(true), []);
  const onCloseDialog = useCallback(() => setIsDialogOpen(false), []);

  const { canCancelApply, durationUntilExpiry } = useCancelApplyExpiryCountdown(
    shift.shiftRequest
  );
  const timeUntilExpireDisplayString: string | undefined =
    durationUntilExpiry?.toFormat("hh:mm:ss") ?? "";

  const isCancelDisabled = ptCancelRecord != null || !canCancelApply;

  return (
    <>
      <div
        className={cn(
          "p-5",
          "fixed",
          "bottom-0",
          "left-0",
          "right-0",
          "border-t",
          "border-t-black/12",
          "bg-white"
        )}
      >
        <Button
          className={cn("w-full", "flex", "items-center", "gap-x-2", "group", {
            "!bg-black/24": isCancelDisabled,
            "hover:!bg-black/24": isCancelDisabled,
          })}
          onClick={onOpenDialog}
          theme="white"
          disabled={!canCancelApply || ptCancelRecord != null}
        >
          <CancelWithCircleOutlineIcon
            className={cn("w-5", "h-5", "group-disabled:fill-black/30", {
              "fill-black/60": ptCancelRecord == null,
              "!fill-white": isCancelDisabled,
            })}
          />
          <span
            className={cn("text-sm", "leading-5", "font-medium", {
              "group-disabled:text-black/30 text-black/60":
                ptCancelRecord == null,
              "group-disabled:text-white text-white": isCancelDisabled,
            })}
          >
            {isCancelDisabled ? (
              <Trans i18nKey="shift.cancelApplication.button.cancelApplyAgain" />
            ) : (
              <Trans i18nKey="shift.cancelApplication.button.cancelApply" />
            )}
          </span>
          {canCancelApply && ptCancelRecord == null ? (
            <span className={cn("text-xs", "leading-4", "text-black/60")}>
              ({timeUntilExpireDisplayString})
            </span>
          ) : null}
        </Button>
      </div>
      <CancelApplicationDialog
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        timeUntilExpireDisplayString={timeUntilExpireDisplayString}
        canCancelApply={canCancelApply}
        shift={shift}
      />
    </>
  );
};

export default ShiftCancelApplicationSection;
