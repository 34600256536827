import React, { useCallback } from "react";
import cn from "classnames";
import { BookmarkFilledIcon, BookmarkOutlineIcon } from "../../icon";

interface Props {
  onToggleBookmark: () => void;
  isBookmarked: boolean;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
}

const BookmarkShiftButton = (props: Props): React.ReactElement => {
  const { onToggleBookmark, className, isBookmarked, disabled, iconClassName } =
    props;

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onToggleBookmark();
    },
    [onToggleBookmark]
  );

  return (
    <button
      type="button"
      className={cn(
        "rounded-md",
        "focus:outline-0",
        "font-medium",
        "text-sm",
        "justify-center",
        "hover:bg-gray-100",
        className
      )}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {isBookmarked ? (
        <BookmarkFilledIcon className={cn("w-6", "h-6", iconClassName)} />
      ) : (
        <BookmarkOutlineIcon className={cn("w-6", "h-6", iconClassName)} />
      )}
    </button>
  );
};

export default BookmarkShiftButton;
