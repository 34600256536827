import { useEffect } from "react";
import ga from "../utils/ga";

interface GTag {
  type: string;
  name: string;
  params?: any;
}

const useTrackGTags = (tags: GTag[]): void => {
  useEffect(() => {
    for (const tag of tags) {
      ga(tag.type, tag.name, tag.params ?? null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useTrackGTags;
