import { ReactElement, useCallback, useMemo } from "react";
import cn from "classnames";
import { Trans } from "react-i18next";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Virtual, Controller } from "swiper/modules";
import { DateTime } from "luxon";
import { PublicHoliday } from "oneclick-component/src/store/apis/enhancedApi";
import {
  dateTimeNow,
  isDateInHoliday,
} from "oneclick-component/src/utils/datetime";

interface WeekDatePickerProps {
  filterDate: DateTime;
  onChangeFilterDate: (d: DateTime) => void;
  holidays: PublicHoliday[];
  setWeekDatePickerControl?: (swiper: SwiperClass) => void;
}

interface WeekViewProps {
  index: number;
  holidays: PublicHoliday[];
  onChangeFilterDate: (d: DateTime) => void;
  filterDate: DateTime;
}

const WeekView = (props: WeekViewProps) => {
  const { index, holidays, onChangeFilterDate, filterDate } = props;
  const today = dateTimeNow().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const weekMonday = today.plus({ day: -(today.weekday - 1) });
  const targetWeek = weekMonday.plus({ week: index });

  const handleChangeDate = useCallback(
    (d: DateTime) => {
      return () => {
        onChangeFilterDate(d);
      };
    },
    [onChangeFilterDate]
  );

  return (
    <div
      key={`week-${index}`}
      className={cn(
        "grid",
        "grid-cols-7",
        "text-center",
        "text-xs",
        "px-4",
        "bg-white"
      )}
    >
      {Array.from({ length: 7 }).map((_, dayIndex) => {
        const thisDate = targetWeek
          .plus({ day: dayIndex })
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const isHoliday = isDateInHoliday(thisDate.toISO()!, holidays);
        return (
          <div key={`day-${thisDate.toISO()!}`} className="p-2">
            <div
              className={cn("mb-2", {
                "text-black/86": thisDate >= today,
                "text-black/24": thisDate < today,
              })}
            >
              <Trans i18nKey={`calendar.dayOfWeek.${dayIndex}`} />
            </div>
            <button
              type="button"
              onClick={handleChangeDate(thisDate)}
              disabled={thisDate < today}
              className={cn(
                "w-9",
                "h-9",
                "font-normal",
                "leading-6",
                "text-base",
                "mx-auto",
                "flex",
                "flex-row",
                "items-center",
                "justify-center",
                {
                  "bg-primary-500 rounded-full text-white":
                    thisDate.day === filterDate.day &&
                    thisDate.month === filterDate.month &&
                    thisDate.year === filterDate.year &&
                    !isHoliday,
                  "bg-red-500 rounded-full text-white":
                    thisDate.day === filterDate.day &&
                    thisDate.month === filterDate.month &&
                    thisDate.year === filterDate.year &&
                    isHoliday,
                  "text-black/24": thisDate < today,
                  "text-red-300": thisDate < today && isHoliday,
                  "text-red-500": thisDate >= today && isHoliday,
                }
              )}
            >
              {thisDate.day}
            </button>
          </div>
        );
      })}
    </div>
  );
};

const WeekDatePicker = (props: WeekDatePickerProps): ReactElement => {
  const { holidays, onChangeFilterDate, filterDate, setWeekDatePickerControl } =
    props;

  const weeks = useMemo(() => {
    /** Render future 1 year weeks only */
    return Array.from({ length: 53 }).map((_, index) => (
      <WeekView
        key={index}
        index={index}
        holidays={holidays}
        onChangeFilterDate={onChangeFilterDate}
        filterDate={filterDate}
      />
    ));
  }, [holidays, onChangeFilterDate, filterDate]);

  const changePageOnInit = useCallback(
    (swiper: SwiperClass) => {
      const today = dateTimeNow();
      let pageIndex = filterDate.weekNumber - today.weekNumber;

      if (filterDate.year > today.year) {
        pageIndex = 52 - today.weekNumber + filterDate.weekNumber;
      }

      if (pageIndex > 0) {
        swiper.slideTo(pageIndex);
      }
    },
    [filterDate]
  );

  return (
    <div className="bg-white">
      <Swiper
        modules={[Virtual, Controller]}
        onSwiper={setWeekDatePickerControl}
        virtual={true}
        slidesPerView={1}
        onInit={changePageOnInit}
      >
        {weeks.map((slideContent, index) => (
          <SwiperSlide key={`week-${index}`} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WeekDatePicker;
