import { ReactElement, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { DateTime, Interval } from "luxon";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  dateTimeNow,
  isDateInHoliday,
  isoToDateTime,
  formatDateTime,
} from "oneclick-component/src/utils/datetime";
import {
  PublicHoliday,
  ShiftRateByDate,
  usePtUserListShiftsRateByDateHandlerPtShiftsListShiftRateByDateGetQuery as useListShiftRates,
} from "oneclick-component/src/store/apis/enhancedApi";
import useMeUserStation from "../../hooks/useMeUserStation";

interface CalendarViewProps {
  filterDate: DateTime;
  holidays: PublicHoliday[];
  onChangeFilterDate: (d: DateTime) => void;
  toggleShouldShowMonthPicker: () => void;
}

interface MonthViewProps {
  month: DateTime;
  filterDate: DateTime;
  holidays: PublicHoliday[];
  shiftRateMap: Record<string, ShiftRateByDate[]>;
  onChangeFilterDate: (d: DateTime) => void;
  toggleShouldShowMonthPicker: () => void;
}

interface DayViewProps {
  date: DateTime;
  isFocus: boolean;
  isHoliday: boolean;
  shiftRates: ShiftRateByDate[];
  onChangeFilterDate: (d: DateTime) => void;
  toggleShouldShowMonthPicker: () => void;
}

const DayView = (props: DayViewProps) => {
  const {
    date,
    isFocus,
    isHoliday,
    shiftRates,
    onChangeFilterDate,
    toggleShouldShowMonthPicker,
  } = props;
  const isDisabled = useMemo(() => {
    return dateTimeNow().plus({ day: -1 }) > date;
  }, [date]);

  const rateDisplay = useMemo(() => {
    if (shiftRates.length === 0) {
      return null;
    }

    const sortedRates = shiftRates.sort((a, b) => a.id - b.id);
    return (
      <>
        {sortedRates.map((rate) => (
          <div
            key={`day-rate-${rate.id}`}
            className={cn("w-1", "h-1", "rounded-full")}
            style={{
              backgroundColor: `#${rate.colorHexcode}`,
            }}
          />
        ))}
      </>
    );
  }, [shiftRates]);

  const onChooseDate = useCallback(() => {
    onChangeFilterDate(date);
    toggleShouldShowMonthPicker();
  }, [onChangeFilterDate, toggleShouldShowMonthPicker, date]);

  return (
    <button type="button" onClick={onChooseDate} disabled={isDisabled}>
      <div>
        <div
          className={cn(
            "w-10",
            "h-10",
            "font-medium",
            "text-sm",
            "mx-auto",
            "flex",
            "flex-row",
            "items-center",
            "justify-center",
            {
              "bg-primary-500 rounded-full text-white": isFocus && !isHoliday,
              "bg-red-500 rounded-full text-white": isFocus && isHoliday,
              "text-gray-300": isDisabled,
              "text-red-500": isHoliday,
            }
          )}
        >
          {date.day}
        </div>
        <div
          className={cn(
            "flex",
            "flex-row",
            "gap-x-1",
            "justify-center",
            "mt-0.5",
            "mb-1",
            "h-1"
          )}
        >
          {rateDisplay}
        </div>
      </div>
    </button>
  );
};

const MonthView = (props: MonthViewProps) => {
  const {
    month,
    filterDate,
    holidays,
    shiftRateMap,
    onChangeFilterDate,
    toggleShouldShowMonthPicker,
  } = props;
  const date = month.set({ day: 1, hour: 0, minute: 0, second: 0 });
  const endDate = date.plus({ months: 1 });
  const range = Interval.fromDateTimes(date, endDate);

  const daysDisplay = useMemo(() => {
    const daysArray = [];
    const days = range.splitBy({ days: 1 });
    const addDay = (date: DateTime) => {
      const isHoliday = isDateInHoliday(date.toISODate()!, holidays);
      const dateKey = formatDateTime(date, "yyyy-MM-dd");
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const rates = shiftRateMap[dateKey] ?? [];
      daysArray.push(
        <DayView
          key={`day-${date.month}-${date.day}`}
          date={date}
          isHoliday={isHoliday}
          isFocus={
            // eslint-disable-next-line react/jsx-no-leaked-render
            date.month === filterDate.month && date.day === filterDate.day
          }
          shiftRates={rates}
          onChangeFilterDate={onChangeFilterDate}
          toggleShouldShowMonthPicker={toggleShouldShowMonthPicker}
        />
      );
    };

    for (let s = 0; s < days[0].start!.weekday % 7; s += 1) {
      daysArray.push(
        <div key={`empty-day-${s}`} className={cn("w-10", "h-10")}></div>
      );
    }
    for (let i = 0; i < days.length; i += 1) {
      const thatDay: DateTime = days[i].start!;
      addDay(thatDay);
    }

    return daysArray;
  }, [
    holidays,
    range,
    filterDate,
    shiftRateMap,
    onChangeFilterDate,
    toggleShouldShowMonthPicker,
  ]);

  return (
    <div className={cn("grid", "grid-cols-7", "text-center", "text-xs", "p-4")}>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.6" />
      </div>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.0" />
      </div>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.1" />
      </div>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.2" />
      </div>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.3" />
      </div>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.4" />
      </div>
      <div className={cn("text-gray-500", "p-2")}>
        <Trans i18nKey="calendar.month.dayOfWeek.5" />
      </div>
      {daysDisplay}
    </div>
  );
};

const MonthPicker = (props: CalendarViewProps): ReactElement => {
  const {
    filterDate,
    holidays,
    onChangeFilterDate,
    toggleShouldShowMonthPicker,
  } = props;
  const { t } = useTranslation();

  const now = useMemo(() => {
    return dateTimeNow();
  }, []);

  const [viewMonth, setViewMonth] = useState(
    filterDate.set({
      day: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  );

  const meUserStation = useMeUserStation();

  const { data } = useListShiftRates({
    endAfter: viewMonth.toISO(),
    startBefore: viewMonth.plus({ month: 1 }).toISO(),
    shiftRequestStatuses: ["requested"],
    showNoneStationIds: meUserStation?.workingStations.map((ws) => ws.id) ?? [],
    shiftStatus: ["active"],
    shiftType: "REGULAR",
  });

  const handlePrevMonthClick = useCallback(() => {
    setViewMonth((prev) => prev.plus({ month: -1 }));
  }, []);
  const handleNextMonthClick = useCallback(() => {
    setViewMonth((prev) => prev.plus({ month: 1 }));
  }, []);

  const shiftRateByDate = useMemo(() => {
    if (data == null) {
      return {};
    }
    const result: Record<string, ShiftRateByDate[]> = {};

    for (const shiftRate of data.rates) {
      const date = isoToDateTime(shiftRate.date);
      const dateKey = formatDateTime(date, "yyyy-MM-dd");
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (result[dateKey] == null) {
        result[dateKey] = [];
      }
      result[dateKey].push(shiftRate);
    }

    return result;
  }, [data]);

  return (
    <div>
      <div className={cn("border-t", "border-gray-200", "pt-2", "mx-4")} />
      <div
        className={cn(
          "flex",
          "flex-row",
          "justify-center",
          "items-center",
          "gap-x-2"
        )}
      >
        <button
          type="button"
          onClick={handlePrevMonthClick}
          disabled={now > viewMonth}
        >
          <ChevronLeftIcon
            className={cn("h-5", {
              "text-gray-400": now > viewMonth,
              "text-primary-500": now < viewMonth,
            })}
          />
        </button>
        <p>{viewMonth.toFormat(t("header.monthFilter.format"))}</p>
        <button type="button" onClick={handleNextMonthClick}>
          <ChevronRightIcon className={cn("text-primary-500", "h-5")} />
        </button>
      </div>
      <MonthView
        month={viewMonth}
        holidays={holidays}
        shiftRateMap={shiftRateByDate}
        onChangeFilterDate={onChangeFilterDate}
        toggleShouldShowMonthPicker={toggleShouldShowMonthPicker}
        filterDate={filterDate}
      />
    </div>
  );
};

export default MonthPicker;
