import React, { useContext, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useParams, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
  ShiftForPtUserView,
  useGetShiftByIdHandlerPtShiftsShiftIdGetQuery as useShiftDetail,
  useGetPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGetQuery as usePtUserShiftCancel,
} from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import useHolidays from "../../hooks/useHolidays";
import AppRoutes from "../../routes/AppRoutes";
import ShiftListItem from "../../components/ShiftListItem";
import { CheckCircleIcon, PendingCircleIcon } from "../../icon";
import ShiftApplicationSection from "./ApplicationSection";
import ShiftCancelApplicationSection from "./CancelApplicationSection";
import IncidentShiftApplicationSection from "./IncidentShiftApplicationSection";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";

interface StatusHeadingProps {
  appliedSuccess: boolean;
  shift?: ShiftForPtUserView;
}

const StatusHeading = (props: StatusHeadingProps) => {
  const { appliedSuccess, shift } = props;

  const isInWaitingList = useMemo(() => {
    if (shift == null) {
      return false;
    }

    return (
      shift.shiftRequest?.status === "applied" && shift.shiftRequest.index > 0
    );
  }, [shift]);

  const isHired = useMemo(() => {
    if (shift == null) {
      return false;
    }

    return shift.shiftRequest?.status === "hired";
  }, [shift]);

  const applySuccessMessageKeys = useMemo(() => {
    if (shift?.isIncident) {
      return { title: "shift.application.hired.title", description: null };
    }
    return {
      title: "shift.application.success.title",
      description: "shift.application.success.description",
    };
  }, [shift]);

  if (shift == null) {
    return <></>;
  }

  if (appliedSuccess && !isInWaitingList) {
    return (
      <>
        <div className={cn("px-5", "pt-7", "bg-white", "w-full")}>
          <div className={cn("text-center", "px-15")}>
            <CheckCircleIcon className={cn("w-14.5", "h-14.5", "mx-auto")} />
            <h1
              className={cn(
                "font-medium",
                "text-xl",
                "text-black",
                "mt-3",
                "break-keep"
              )}
            >
              <Trans i18nKey={applySuccessMessageKeys.title} />
            </h1>
            {applySuccessMessageKeys.description != null ? (
              <p
                className={cn(
                  "font-medium",
                  "text-sm",
                  "text-black/60",
                  "whitespace-pre-line",
                  "mt-5"
                )}
              >
                <Trans i18nKey={applySuccessMessageKeys.description} />
              </p>
            ) : null}
          </div>
        </div>
        <hr className={cn("border-black/12", "mt-7")} />
      </>
    );
  }

  if (isHired && shift.status === "active" && shift.isIncident) {
    return (
      <>
        <div className={cn("px-5", "pt-7", "bg-white", "w-full")}>
          <div className={cn("text-center", "px-15")}>
            <CheckCircleIcon className={cn("w-14.5", "h-14.5", "mx-auto")} />
            <h1
              className={cn(
                "font-medium",
                "text-xl",
                "text-black",
                "mt-3",
                "break-keep"
              )}
            >
              <Trans i18nKey="shift.application.hired.title" />
            </h1>
          </div>
        </div>
        <hr className={cn("border-black/12", "mt-7")} />
      </>
    );
  }

  if (isInWaitingList && shift.isIncident) {
    return (
      <>
        <div className={cn("px-5", "pt-7", "bg-white", "w-full")}>
          <div className={cn("text-center", "px-15")}>
            <PendingCircleIcon className={cn("w-14.5", "h-14.5", "mx-auto")} />
            <h1 className={cn("font-medium", "text-xl", "text-black", "mt-3")}>
              <Trans i18nKey="shift.application.pending.title" />
            </h1>
          </div>
        </div>
        <hr className={cn("border-black/12", "mt-7")} />
      </>
    );
  }

  return <></>;
};

const ShiftDetailScreen = (): React.ReactElement => {
  const { id } = useParams();
  const holidays = useHolidays();
  const { t } = useTranslation();
  const [appliedSuccess, setAppliedSuccess] = useState(false);
  const showMessage = useShowMessage();

  const { shouldShowCancelApplyFeature } = useContext(FeatureConfigContext);

  const { data, error, isLoading } = useShiftDetail(
    {
      shiftId: parseInt(id!, 10),
    },
    {
      skip: id == null,
    }
  );
  const { data: ptCancelRecord } = usePtUserShiftCancel({
    shiftId: parseInt(id!, 10),
  });

  const isSingleShift = useMemo(() => {
    if (data == null) {
      return true;
    }
    return data.shift.displayId.split("-")[2].charAt(0) === "S";
  }, [data]);

  const actionSection: "applyRegular" | "cancelApply" | "applyIncident" | null =
    useMemo(() => {
      if (
        data == null ||
        data.shift.status === "cancelled" ||
        data.shift.status === "expired"
      ) {
        return null;
      }
      const shiftRequest = data.shift.shiftRequest;

      if (
        shiftRequest != null &&
        (shiftRequest.status === "applied" || shiftRequest.status === "hired")
      ) {
        return data.shift.isIncident ? null : "cancelApply";
      }
      if (!appliedSuccess) {
        return data.shift.isIncident ? "applyIncident" : "applyRegular";
      }
      return null;
    }, [appliedSuccess, data]);

  useEffect(() => {
    if (error != null) {
      console.error(error);
      showMessage({
        title: t("shift.application.detail.toast.fail.title"),
        type: "fail",
        showDismiss: true,
      });
    }
  }, [error, showMessage, t]);

  if (isLoading || data == null) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }
  return (
    <main className="bg-white">
      <StatusHeading appliedSuccess={appliedSuccess} shift={data.shift} />
      <ShiftListItem
        shiftForPt={data.shift}
        holidays={holidays}
        className="!shadow-none"
      />
      {isSingleShift || data.shift.shiftType === "INCIDENT" ? null : (
        <div className={cn("mb-4", "ml-5")}>
          <Link
            to={AppRoutes.JobDetailScreen.render(data.shift.masterShiftId)}
            className={cn(
              "text-primary-500",
              "text-sm",
              "font-normal",
              "underline"
            )}
          >
            <Trans i18nKey="shift.application.relatedShifts" />
          </Link>
        </div>
      )}
      <hr className={cn("border-black/12", "mx-5", "mb-4")} />
      <section className="mx-4">
        <div className={"w-full"}>
          <h6
            className={cn(
              "text-sm",
              "font-medium",
              "leading-5",
              "text-black/60",
              "mt-5"
            )}
          >
            <Trans i18nKey="shift.application.detail.description" />
          </h6>
          <p
            className={cn(
              "text-sm",
              "font-normal",
              "text-black/86",
              "mt-3",
              "whitespace-pre-wrap"
            )}
          >
            {data.shift.shiftDescription}
          </p>
        </div>
      </section>
      {actionSection === "applyRegular" ? (
        <ShiftApplicationSection
          shift={data.shift}
          setAppliedSuccess={setAppliedSuccess}
        />
      ) : null}
      {actionSection === "applyIncident" ? (
        <IncidentShiftApplicationSection
          shift={data.shift}
          setAppliedSuccess={setAppliedSuccess}
        />
      ) : null}
      {actionSection === "cancelApply" && shouldShowCancelApplyFeature ? (
        <ShiftCancelApplicationSection
          shift={data.shift}
          ptCancelRecord={ptCancelRecord?.ptUserShiftCancel ?? null}
        />
      ) : null}
    </main>
  );
};

export default ShiftDetailScreen;
