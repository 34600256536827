import React, { useMemo } from "react";
import { DateTime } from "luxon";
import {
  BorrowingRequestStatus,
  ConflictWithHiredShiftViolation,
  ConflictWithRegularScheduleViolation,
  PtUserSuspendedViolation,
  ShiftCancelledViolation,
  ShiftCompletedViolation,
  ShiftExpiredViolation,
  ShiftForPtUserView,
  ShiftRequestBaseWithNumbering,
  ShiftRequestStatus,
  ShiftStatus,
  Station,
} from "oneclick-component/src/store/apis/enhancedApi";
import { isShiftStartTimeT3 } from "oneclick-component/src/utils/shiftT3";
import {
  dateTimeNow,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { useTranslation } from "react-i18next";
import {
  makeApplySuccessFutureShiftDisplayStatus,
  makeApplySuccessPastShiftDisplayStatus,
  makeCancelledShiftDisplayStatus,
  makeConflictedShiftDisplayStatus,
  makeExpiredShiftDisplayStatus,
  makePtSuspendedShiftDisplayStatus,
  makeRejectedShiftDisplayStatus,
  makeUnavailableShiftDisplayStatus,
  makeWaitingListShiftDisplayStatus,
  makeWelcomeApplyShiftDisplayStatus,
  makeApplyIncidentSuccessShiftDisplayStatus,
} from "./status";
import { TFunction } from "i18next";

// ref Truth Table https://docs.google.com/spreadsheets/d/1hKGCcmN60NbVAysnNE0qQ2l4yi90FZAlqH6XJgv3T3I/edit#gid=0
export interface ShiftDisplayStatus {
  calendar: "normal" | "conflicted" | "unavailable";
  statusIcon: React.ComponentType<any>;
  statusText: string;
  canApply: boolean;
  action: "bookmark" | "like" | null;
}

// eslint-disable-next-line complexity
function invariantCheck(
  shiftStatus: ShiftStatus,
  shiftRequestStatus: ShiftRequestStatus | null,
  shiftRequestBorrowingStatus: BorrowingRequestStatus | null | undefined,
  shiftViolations:
    | (
        | ShiftCompletedViolation
        | ShiftCancelledViolation
        | ShiftExpiredViolation
        | ConflictWithHiredShiftViolation
        | ConflictWithRegularScheduleViolation
        | PtUserSuspendedViolation
      )[]
    | null,
  t: TFunction
): ShiftDisplayStatus | null {
  // this should not happen, but put it here just in case
  if (
    shiftViolations != null &&
    shiftViolations.filter((v) => v.code === "PT_USER_SUSPENDED").length > 0
  ) {
    return makePtSuspendedShiftDisplayStatus(t);
  }

  if (shiftStatus === "cancelled") {
    return makeCancelledShiftDisplayStatus(t);
  }

  if (
    shiftRequestStatus === "rejected" ||
    shiftRequestBorrowingStatus === "rejected"
  ) {
    return makeRejectedShiftDisplayStatus(t);
  }

  if (
    shiftStatus === "expired" &&
    shiftRequestStatus !== "hired" &&
    shiftRequestStatus !== "applied" &&
    shiftRequestStatus !== "contacted"
  ) {
    return makeExpiredShiftDisplayStatus(t);
  }

  if (shiftRequestStatus === "completed") {
    return makeApplySuccessPastShiftDisplayStatus(t);
  }

  return null;
}

function homeStationConflictCheck(
  shiftRequestStatus: ShiftRequestStatus | null,
  shiftViolations:
    | (
        | ShiftCompletedViolation
        | ShiftCancelledViolation
        | ShiftExpiredViolation
        | ConflictWithHiredShiftViolation
        | ConflictWithRegularScheduleViolation
        | PtUserSuspendedViolation
      )[]
    | null,
  t: TFunction,
  isIncident: boolean
): ShiftDisplayStatus | null {
  const hasHiredShiftConflict: boolean =
    shiftViolations != null &&
    shiftViolations.filter((v) => v.code === "CONFLICT_WITH_HIRED_SHIFT")
      .length > 0;

  const hasRegularShiftConflict: boolean =
    shiftViolations != null &&
    shiftViolations.filter((v) => v.code === "CONFLICT_WITH_REGULAR_SCHEDULE")
      .length > 0;

  if (shiftRequestStatus == null || shiftRequestStatus === "requested") {
    // available for apply/invited home-station
    if (hasHiredShiftConflict) {
      return makeConflictedShiftDisplayStatus(t, "hiredShift");
    }

    if (hasRegularShiftConflict) {
      return makeConflictedShiftDisplayStatus(t, "regularSchedule");
    }
    return makeWelcomeApplyShiftDisplayStatus(t, isIncident);
  }

  return null;
}

function nonHomeStationConflictCheck(
  shiftRequestStatus: ShiftRequestStatus | null,
  shiftViolations:
    | (
        | ShiftCompletedViolation
        | ShiftCancelledViolation
        | ShiftExpiredViolation
        | ConflictWithHiredShiftViolation
        | ConflictWithRegularScheduleViolation
        | PtUserSuspendedViolation
      )[]
    | null,
  t: TFunction,
  isIncident: boolean,
  isShiftAppliableToAllAtT3: boolean,
  isShiftAtT3: boolean
): ShiftDisplayStatus | null {
  const hasHiredShiftConflict: boolean =
    shiftViolations != null &&
    shiftViolations.filter((v) => v.code === "CONFLICT_WITH_HIRED_SHIFT")
      .length > 0;

  const hasRegularShiftConflict: boolean =
    shiftViolations != null &&
    shiftViolations.filter((v) => v.code === "CONFLICT_WITH_REGULAR_SCHEDULE")
      .length > 0;

  if (
    shiftRequestStatus === "requested" ||
    (isShiftAppliableToAllAtT3 && isShiftAtT3 && shiftRequestStatus == null)
  ) {
    // invited non-home-station
    if (hasHiredShiftConflict) {
      return makeConflictedShiftDisplayStatus(t, "hiredShift");
    }

    if (hasRegularShiftConflict) {
      return makeConflictedShiftDisplayStatus(t, "regularSchedule");
    }

    return makeWelcomeApplyShiftDisplayStatus(t, isIncident);
  }

  return null;
}

function appliedOrHiredCheck(
  shiftRequest: ShiftRequestBaseWithNumbering,
  shiftStartTime: DateTime,
  t: TFunction,
  isIncident: boolean,
  shiftStatus: ShiftStatus
): ShiftDisplayStatus | null {
  if (
    shiftRequest.status === "applied" ||
    shiftRequest.status === "contacted"
  ) {
    return makeWaitingListShiftDisplayStatus(
      t,
      shiftRequest.borrowingRequestStatus != null,
      shiftRequest.index,
      isIncident
    );
  }

  if (shiftRequest.status === "hired") {
    const nowTime = dateTimeNow();
    if (isIncident) {
      return shiftStatus === "active"
        ? makeApplyIncidentSuccessShiftDisplayStatus(t)
        : makeApplySuccessPastShiftDisplayStatus(t);
    }
    return nowTime < shiftStartTime
      ? makeApplySuccessFutureShiftDisplayStatus(t)
      : makeApplySuccessPastShiftDisplayStatus(t);
  }

  return null;
}

// eslint-disable-next-line complexity
export function getShiftDisplayStatus(
  shift: ShiftForPtUserView,
  meStation: Station,
  t: TFunction,
  isLikedDisplay?: boolean
): ShiftDisplayStatus {
  const shiftRequestStatus: ShiftRequestStatus | null =
    shift.shiftRequest == null ? null : shift.shiftRequest.status;

  const invariantCheckResult = invariantCheck(
    shift.status,
    shiftRequestStatus,
    shift.shiftRequest?.borrowingRequestStatus,
    shift.violations,
    t
  );
  if (invariantCheckResult != null) {
    return invariantCheckResult;
  }

  const isHomeStation: boolean =
    shift.workingStation.mainStationId === meStation.id;

  const isShiftAtT3 = isShiftStartTimeT3(isoToDateTime(shift.dutyStartTime));
  const conflictCheckResult = isHomeStation
    ? homeStationConflictCheck(
        shiftRequestStatus,
        shift.violations,
        t,
        shift.isIncident
      )
    : nonHomeStationConflictCheck(
        shiftRequestStatus,
        shift.violations,
        t,
        shift.isIncident,
        shift.isAppliableToAllAtT3,
        isShiftAtT3
      );
  if (conflictCheckResult != null) {
    return conflictCheckResult;
  }

  if (!isHomeStation && shiftRequestStatus == null) {
    // non-invited non-home-station
    return makeUnavailableShiftDisplayStatus(
      t,
      isLikedDisplay ?? shift.isLiked,
      shift.isIncident
    );
  }

  const appliedOrHiredCheckResult = appliedOrHiredCheck(
    shift.shiftRequest!,
    isoToDateTime(shift.dutyStartTime),
    t,
    shift.isIncident,
    shift.status
  );
  if (appliedOrHiredCheckResult != null) {
    return appliedOrHiredCheckResult;
  }

  if (shift.status === "expired") {
    return makeExpiredShiftDisplayStatus(t);
  }

  // unexpected case, default to unavailable
  return makeUnavailableShiftDisplayStatus(
    t,
    isLikedDisplay ?? shift.isLiked,
    shift.isIncident
  );
}

function useShiftDisplayStatus(
  shift: ShiftForPtUserView,
  meStation: Station,
  isLikedDisplay?: boolean
): ShiftDisplayStatus {
  const { t } = useTranslation();

  const status: ShiftDisplayStatus = useMemo(
    () => getShiftDisplayStatus(shift, meStation, t, isLikedDisplay),
    [isLikedDisplay, meStation, shift, t]
  );

  return status;
}

export default useShiftDisplayStatus;
