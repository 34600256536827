import React, { useCallback } from "react";
import cn from "classnames";
import { ThumbUpFilledIcon, ThumbUpIcon } from "../../icon";

interface Props {
  onToggleLike: () => void;
  isLiked: boolean;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
}

const LikeShiftButton = (props: Props): React.ReactElement => {
  const { onToggleLike, className, isLiked, disabled, iconClassName } = props;

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onToggleLike();
    },
    [onToggleLike]
  );

  return (
    <button
      type="button"
      className={cn(
        "rounded-md",
        "focus:outline-0",
        "font-medium",
        "text-sm",
        "justify-center",
        "hover:bg-gray-100",
        className
      )}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {isLiked ? (
        <ThumbUpFilledIcon className={cn("w-6", "h-6", iconClassName)} />
      ) : (
        <ThumbUpIcon className={cn("w-6", "h-6", iconClassName)} />
      )}
    </button>
  );
};

export default LikeShiftButton;
