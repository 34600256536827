import React, { useCallback } from "react";
import cn from "classnames";
import PopMenu from "oneclick-component/src/components/PopMenu";
import { DateTime, Interval } from "luxon";
import { useMatch } from "react-router-dom";
import { SwiperClass } from "swiper/react";
import { useTranslation, Trans } from "react-i18next";
import { Menu } from "@headlessui/react";
import {
  PublicHoliday,
  useListPublicHolidaysHandlerPublicHolidaysGetQuery as useListPublicHoliday,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  BookmarkLayerFilledIcon,
  BookmarkLayerOutlineIcon,
  SortIcon,
} from "../../icon";
import { CalendarIcon } from "oneclick-component/src/icon";
import {
  dateTimeNow,
  isDateInHoliday,
} from "oneclick-component/src/utils/datetime";
import WeekDatePicker from "../WeekDatePicker";
import MonthPicker from "../MonthPicker";
import AppRoutes from "../../routes/AppRoutes";

interface MonthMenuItemProps {
  onSelectMonth: (d: DateTime) => void;
  date: DateTime | null;
}

interface DateRangeButtonProps {
  filterMonth: DateTime;
}

const MonthMenuItem = (props: MonthMenuItemProps) => {
  const { onSelectMonth, date } = props;
  const { t } = useTranslation();
  const onClickMonth = useCallback(() => {
    if (date != null) {
      onSelectMonth(date);
    }
  }, [onSelectMonth, date]);

  return (
    <Menu.Item>
      <div className={cn("px-4", "py-2", "text-sm", "text-gray-900")}>
        <button
          type="button"
          onClick={onClickMonth}
          className={cn("w-full", "text-left", "h-8")}
        >
          {date?.toFormat(t("header.monthFilter.format"))}
        </button>
      </div>
    </Menu.Item>
  );
};

interface MonthMenuProps {
  onSelectMonth: (d: DateTime) => void;
  dateInterval: Interval;
}

const MonthMenu = (props: MonthMenuProps) => {
  const { onSelectMonth, dateInterval } = props;

  return (
    <div className={cn("h-55", "overflow-y-auto")}>
      {dateInterval.splitBy({ months: 1 }).map((date) => (
        <MonthMenuItem
          key={date.toISODate()}
          date={date.start}
          onSelectMonth={onSelectMonth}
        />
      ))}
    </div>
  );
};

interface DatePickButtonProps {
  toggleShouldShowMonthPicker: () => void;
}

const DatePickButton = (props: DatePickButtonProps) => {
  const { toggleShouldShowMonthPicker } = props;
  return (
    <button
      type="button"
      className={cn("flex", "flex-row", "items-center")}
      onClick={toggleShouldShowMonthPicker}
    >
      <CalendarIcon className="fill-gray-900" />
    </button>
  );
};

const DateRangeFilterButton = (props: DateRangeButtonProps) => {
  const { filterMonth } = props;
  const { t } = useTranslation();
  return (
    <div className={cn("flex", "flex-row", "items-center")}>
      <div className="mr-4">
        {filterMonth.toFormat(t("header.monthFilter.format"))}
      </div>
    </div>
  );
};

interface DateDisplayProps {
  filterDate: DateTime;
  holidays: PublicHoliday[];
  className?: string;
}

const DateDisplay = (props: DateDisplayProps) => {
  const { filterDate, holidays, className } = props;
  const today = dateTimeNow();
  const { t } = useTranslation();
  return (
    <div className={className}>
      <p
        className={cn("text-sm", "text-center", "leading-8", "font-medium", {
          "text-red-500": isDateInHoliday(filterDate.toISO()!, holidays),
        })}
      >
        {today.day === filterDate.day &&
        today.month === filterDate.month &&
        today.year === filterDate.year ? (
          <Trans i18nKey="header.filter.today" />
        ) : (
          filterDate.toFormat(t("header.filterDate.format"))
        )}
      </p>
    </div>
  );
};

interface Props {
  filterMonth: DateTime;
  filterDate: DateTime;
  onChangeFilterDate: (d: DateTime) => void;
  onChangeFilterMonth: (d: DateTime) => void;
  isBookmarkFilterActive: boolean;
  onClickBookmark: () => void;
  onClickFilter: () => void;
  shouldShowBookmarkFilter?: boolean;
  shouldShowFutureMonth: boolean;
  filterStationCount: number;
  weekDatePickerControl: SwiperClass | null;
  setWeekDatePickerControl: (swiper: SwiperClass) => void;
  shouldShowMonthPicker: boolean;
  toggleShouldShowMonthPicker: () => void;
}
// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
const MainLayoutHeaderFilter = (props: Props): React.ReactElement => {
  const {
    filterMonth,
    filterDate,
    onChangeFilterDate,
    onChangeFilterMonth,
    isBookmarkFilterActive,
    onClickBookmark,
    onClickFilter,
    shouldShowBookmarkFilter = false,
    shouldShowFutureMonth = true,
    filterStationCount,
    weekDatePickerControl,
    setWeekDatePickerControl,
    shouldShowMonthPicker,
    toggleShouldShowMonthPicker,
  } = props;

  const findShiftPathMatch = useMatch(AppRoutes.FindShiftScreen.path);

  const { data: holidays } = useListPublicHoliday({});

  const handleChangeMonth = useCallback(
    (d: DateTime) => {
      if (findShiftPathMatch == null) {
        onChangeFilterMonth(d);
      } else {
        const now = dateTimeNow();
        const firstAvailableDate = d < now ? now : d;
        let pageIndex = firstAvailableDate.weekNumber - now.weekNumber;

        if (firstAvailableDate.year > now.year) {
          pageIndex = 52 - now.weekNumber + firstAvailableDate.weekNumber;
        }
        weekDatePickerControl?.slideTo(pageIndex);
        onChangeFilterDate(firstAvailableDate);
      }
    },
    [
      onChangeFilterMonth,
      findShiftPathMatch,
      onChangeFilterDate,
      weekDatePickerControl,
    ]
  );

  const dateNow = dateTimeNow().set({
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const dateOneYearAgo = dateNow.minus({ years: 1 });
  const dateOneYearFromNow = dateNow.plus({ years: 1 });
  const range = shouldShowFutureMonth
    ? Interval.fromDateTimes(dateNow, dateOneYearFromNow)
    : Interval.fromDateTimes(dateOneYearAgo, dateNow.plus({ months: 1 }));

  return (
    <>
      <div
        className={cn(
          "grid",
          "grid-cols-3",
          "pl-2",
          "pr-5",
          "py-2",
          "bg-white"
        )}
      >
        <div className={cn("flex", "flex-row", "items-center")}>
          <div className={cn("ml-3", "flex", "flex-row", "items-center")}>
            {findShiftPathMatch == null ? (
              <PopMenu
                menuClass="relative"
                controlElement={
                  <DateRangeFilterButton filterMonth={filterMonth} />
                }
                menuBodyClass={cn("right-0", "!z-30", "w-27")}
              >
                <MonthMenu
                  dateInterval={range}
                  onSelectMonth={handleChangeMonth}
                />
              </PopMenu>
            ) : (
              <DatePickButton
                toggleShouldShowMonthPicker={toggleShouldShowMonthPicker}
              />
            )}
          </div>
        </div>
        <div
          className={cn("flex", "flex-row", "items-center", "justify-center")}
        >
          {findShiftPathMatch != null ? (
            <DateDisplay
              filterDate={filterDate}
              holidays={holidays?.results ?? []}
            />
          ) : null}
        </div>
        <div className={cn("flex", "flex-row", "justify-end", "items-center")}>
          {shouldShowBookmarkFilter ? (
            <button
              type="button"
              className={cn("hover:bg-gray-50", "py-2", "px-1")}
              onClick={onClickBookmark}
            >
              {isBookmarkFilterActive ? (
                <BookmarkLayerFilledIcon
                  className={cn("w-6", "h-6", "fill-black/86")}
                />
              ) : (
                <BookmarkLayerOutlineIcon
                  className={cn("w-6", "h-6", "fill-black/86")}
                />
              )}
            </button>
          ) : null}
          <button
            type="button"
            className={cn(
              "hover:bg-gray-50",
              "py-2",
              "px-1",
              "ml-1",
              "relative"
            )}
            onClick={onClickFilter}
          >
            <SortIcon
              className={cn("w-5", "h-4", {
                "fill-black/86": filterStationCount === 0,
                "fill-primary-500": filterStationCount > 0,
              })}
            />
            {filterStationCount > 0 && filterStationCount < 10 ? (
              <p
                className={cn(
                  "text-primary-500",
                  "text-xs",
                  "leading-0",
                  "font-normal",
                  "right-0",
                  "absolute"
                )}
              >
                {filterStationCount}
              </p>
            ) : null}
            {filterStationCount >= 10 ? (
              <div
                className={cn(
                  "w-1.5",
                  "h-1.5",
                  "bg-primary-500",
                  "rounded-full",
                  "right-1.5",
                  "top-[7px]",
                  "absolute"
                )}
              />
            ) : null}
          </button>
        </div>
      </div>
      {findShiftPathMatch != null && !shouldShowMonthPicker ? (
        <WeekDatePicker
          holidays={holidays?.results ?? []}
          filterDate={filterDate}
          onChangeFilterDate={onChangeFilterDate}
          setWeekDatePickerControl={setWeekDatePickerControl}
        />
      ) : null}
      {findShiftPathMatch != null && shouldShowMonthPicker ? (
        <MonthPicker
          holidays={holidays?.results ?? []}
          filterDate={filterDate}
          onChangeFilterDate={onChangeFilterDate}
          toggleShouldShowMonthPicker={toggleShouldShowMonthPicker}
        />
      ) : null}
    </>
  );
};

export default MainLayoutHeaderFilter;
